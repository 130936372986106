import {
  CLOSE_TASK,
  GET_ADMIN_DETAILS,
  GET_USERS,
  GET_TASKLIST_REGISTRATION,
  GET_TASKLIST_PROJECTS,
  SET_ADMIN_DETAILS,
  SET_USERS,
  SET_TASKLIST_REGISTRATION,
  SET_TASKLIST_PROJECTS,
  UPDATE_ADMIN_DETAILS,
  UPDATE_COMPANY_DETAILS,
  UPDATE_IS_PAID,
  SET_COMPANIES,
  SET_TECHNICIANS,
  SET_CUSTOMERS,
  SET_CONDOMINIUMS,
  SET_SEEN_TASKLIST,
  GET_TRUSTED_SUPPLIERS,
  SET_TRUSTED_SUPPLIERS,
  CREATE_NEW_PROJECT,
} from 'redux/constants/Admin';

export const getUsers = () => ({
  type: GET_USERS,
});

export const setUsers = users => ({
  type: SET_USERS,
  payload: users,
});

export const setCompanies = companies => ({
  type: SET_COMPANIES,
  payload: companies,
});

export const setTechnicians = technicians => ({
  type: SET_TECHNICIANS,
  payload: technicians,
});

export const setCustomers = customers => ({
  type: SET_CUSTOMERS,
  payload: customers,
});

export const setCondominiums = condominiums => ({
  type: SET_CONDOMINIUMS,
  payload: condominiums,
});

export const getTasklistRegistration = () => ({
  type: GET_TASKLIST_REGISTRATION,
});

export const setTasklistRegistration = tasklist => ({
  type: SET_TASKLIST_REGISTRATION,
  payload: tasklist,
});

export const getTasklistProjects = () => ({
  type: GET_TASKLIST_PROJECTS,
});

export const setTasklistProjects = tasklist => ({
  type: SET_TASKLIST_PROJECTS,
  payload: tasklist,
});

export const closeTask = (userId, taskId, variables, intl) => ({
  type: CLOSE_TASK,
  payload: { userId, taskId, variables, intl },
});

export const updateCompanyDetails = (data, intl) => ({
  type: UPDATE_COMPANY_DETAILS,
  payload: { data, intl },
});

export const getAdminDetails = () => ({
  type: GET_ADMIN_DETAILS,
});

export const setAdminDetails = details => ({
  type: SET_ADMIN_DETAILS,
  payload: details,
});

export const setIsPaid = (projectSupplier, intl) => ({
  type: UPDATE_IS_PAID,
  payload: { projectSupplier, intl },
});

export const updateAdminDetails = (details, intl) => ({
  type: UPDATE_ADMIN_DETAILS,
  payload: { details, intl },
});

export const setSeenTasklist = seen => ({
  type: SET_SEEN_TASKLIST,
  payload: seen,
});

export const getTrustedSuppliers = (projectId, role) => ({
  type: GET_TRUSTED_SUPPLIERS,
  payload: { projectId, role },
});

export const setTrustedSuppliers = suppliers => ({
  type: SET_TRUSTED_SUPPLIERS,
  payload: suppliers,
});

export const createNewProject = project => ({
  type: CREATE_NEW_PROJECT,
  payload: { project },
});
