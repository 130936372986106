import { TOGGLE_COLLAPSED_NAV, CHANGE_LOCALE, TOGGLE_MOBILE_NAV, SET_INTL } from '../constants/Theme';
import { SIGNOUT_SUCCESS } from '../constants/Auth';

const initTheme = {
  navCollapsed: false,
  locale: 'it',
  intl: null,
  mobileNav: false,
};

const theme = (state = initTheme, action) => {
  switch (action.type) {
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.navCollapsed,
      };
    case CHANGE_LOCALE:
      return {
        ...state,
        locale: action.locale,
      };
    case TOGGLE_MOBILE_NAV:
      return {
        ...state,
        mobileNav: action.mobileNav,
      };
    case SET_INTL:
      return {
        ...state,
        intl: action.intl,
      };
    case SIGNOUT_SUCCESS:
      return {
        ...state,
        navCollapsed: false,
      };
    default:
      return state;
  }
};

export default theme;
