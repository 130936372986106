import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import _ from 'lodash';
import { connect } from 'react-redux';
import Loading from 'components/shared-components/Loading';

export const ErrorViews = ({ match, error }) => {
  const errorPath = _.chain(error).camelCase().upperFirst().value();
  const actualComponent = lazy(() => import(`./${errorPath}`));

  return (
    <Suspense fallback={<Loading cover="page" />}>
      <div className="auth-container">
        <Switch>
          <Route path={`${match.url}`} component={actualComponent} />
        </Switch>
      </div>
    </Suspense>
  );
};

const mapStateToProps = ({ appStatus }) => {
  const { error } = appStatus;
  return { error };
};

export default connect(mapStateToProps, null)(ErrorViews);
