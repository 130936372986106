export const ROW_GUTTER = 16;
export const SIDE_NAV_WIDTH = 250;
export const SIDE_NAV_COLLAPSED_WIDTH = 80;
export const SCROLL_OFFSET = 120;

export const BLUE_TITLE_STYLE = {
  fontWeight: 'bold',
  fontFamily: 'Sora',
  fontSize: '18px',
  color: '#232192',
};
