import { all, takeEvery, put, fork, delay } from 'redux-saga/effects';
import AuthService from 'services/AuthService';
import { notification } from 'antd';
import {
  AUTH_ROLE,
  AUTH_TOKEN,
  AUTH_USERID,
  SIGNIN,
  SIGNUP,
  SIGNOUT,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  HIDE_LOADING,
  AUTH_MAIL,
} from '../constants/Auth';
import { signOutSuccess, authenticated, showLoading, hideLoading, setHideLoading } from '../actions/Auth';
import { setError, getStatus } from '../actions/Status';

export function* signIn() {
  yield takeEvery(SIGNIN, function* (action) {
    const { user, form, intl } = action.payload;
    yield put(showLoading());

    try {
      const res = yield AuthService.login(user);

      const { token, roles, user_id, username } = res;
      const role = roles[0].authority.slice(5).toLowerCase();

      localStorage.setItem(AUTH_USERID, user_id);
      localStorage.setItem(AUTH_ROLE, role);
      localStorage.setItem(AUTH_TOKEN, token);
      localStorage.setItem(AUTH_MAIL, username);
      yield put(getStatus());
      yield put(authenticated(token, role, user_id, username));
      yield put(hideLoading());
    } catch (err) {
      yield put(hideLoading());

      form.resetFields();

      if (err.view) {
        yield put(setError(err.code));
      } else {
        notification.error({
          message: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          description: `${intl.formatMessage({
            id: `error.${err.code}.message`,
          })}`,
        });
      }
    }
  });
}

export function* signUp() {
  yield takeEvery(SIGNUP, function* (action) {
    const { user, form, intl, history } = action.payload;
    yield put(showLoading());

    try {
      yield AuthService.signUp(user);
      yield put(hideLoading());
      history.push('/app-auth/registration-sent');
    } catch (err) {
      yield put(hideLoading());

      form.resetFields();

      if (err.view) {
        yield put(setError(err.code));
      } else {
        notification.error({
          message: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          description: `${intl.formatMessage({
            id: `error.${err.code}.message`,
          })}`,
        });
      }
    }
  });
}

export function* forgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, function* (action) {
    const { values, form, intl } = action.payload;

    yield put(showLoading());

    try {
      yield AuthService.forgotPassword(values);
      yield put(hideLoading());

      notification.success({
        message: `${intl.formatMessage({
          id: 'messages.forgot_password_success.title',
        })}`,
        description: `${intl.formatMessage({
          id: 'messages.forgot_password_success.message',
        })}`,
      });
    } catch (err) {
      yield put(hideLoading());

      form.resetFields();

      if (err.view) {
        yield put(setError(err.code));
      } else {
        notification.error({
          message: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          description: `${intl.formatMessage({
            id: `error.${err.code}.message`,
          })}`,
        });
      }
    }
  });
}

export function* resetPassword() {
  yield takeEvery(RESET_PASSWORD, function* (action) {
    const { values, form, intl, history } = action.payload;
    yield put(showLoading());

    try {
      yield AuthService.resetPassword(values);
      yield put(hideLoading());

      history.push('/app-auth/login');

      notification.success({
        message: `${intl.formatMessage({
          id: 'messages.reset_password_success.title',
        })}`,
        description: `${intl.formatMessage({
          id: 'messages.reset_password_success.message',
        })}`,
      });
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        yield put(setError(err.code));
      } else if (err === 'request.error.invalidToken') {
        history.push('/app-auth/forgot-password');

        notification.error({
          message: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          description: `${intl.formatMessage({
            id: `error.${err.code}.message`,
          })}`,
        });
      } else if (err) {
        form.resetFields();

        notification.error({
          message: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          description: `${intl.formatMessage({
            id: `error.${err.code}.message`,
          })}`,
        });
      }
    }
  });
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      localStorage.removeItem(AUTH_ROLE);
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem(AUTH_USERID);
      localStorage.removeItem(AUTH_MAIL);
      yield put(signOutSuccess());
    } catch (err) {
      if (err.view) {
        yield put(setError(err.code));
      }
    }
  });
}

export function* hideLoadingSaga() {
  yield takeEvery(HIDE_LOADING, function* () {
    try {
      yield delay(1000);
      yield put(setHideLoading());
    } catch (err) {
      if (err.view) {
        yield put(setError(err.code));
      }
    }
  });
}

export default function* rootSaga() {
  yield all([fork(signOut), fork(signIn), fork(signUp), fork(forgotPassword), fork(resetPassword), fork(hideLoadingSaga)]);
}
