export const SET_STATUS = 'SET_STATUS';
export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const GET_STATUS = 'GET_STATUS';

export const SET_MENU_KEY = 'SET_MENU_KEY';
export const SET_LAST_MENU_KEY = 'SET_LAST_MENU_KEY';

export const SET_SELECTED_USER = 'SET_SELECTED_USER';
export const SET_LAST_SELECTED_USER = 'SET_LAST_SELECTED_USER';

export const SET_SELECTED_PROJECT = 'SET_SELECTED_PROJECT';
export const SET_LAST_SELECTED_PROJECT = 'SET_LAST_SELECTED_PROJECT';

export const SET_SELECTED_TASK = 'SET_SELECTED_TASK';
export const SET_LAST_SELECTED_TASK = 'SET_LAST_SELECTED_TASK';

export const GO_BACK = 'GO_BACK';
export const CLEAR_BACK_ACTIVE = 'CLEAR_BACK_ACTIVE';
