import React from 'react';
import _ from 'lodash';
import { Menu, Dropdown } from 'antd';
import { connect } from 'react-redux';
import {
  SettingOutlined,
  LogoutOutlined,
  UserOutlined,
  ProfileOutlined,
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';
import { setMenuKey } from 'redux/actions/Status';
import IntlMessage from 'components/util-components/IntlMessage';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { NOT_ADMIN } from 'constants/AppConstant';
import Avatar from 'views/app-views/components/profile/Avatar';
import Utils from 'utils';

export const NavProfile = props => {
  const { signOut, role, status, setMenuKey, isMobile } = props;
  const intl = useIntl();

  const registrationTerminated = status === 'registration_terminated';

  const menuItem = [
    !registrationTerminated &&
      isMobile && {
        title: `${intl.formatMessage({ id: 'nav.registration' })}`,
        icon: ProfileOutlined,
        path: '/app-registration',
        key: 'registration',
      },
    registrationTerminated &&
      NOT_ADMIN.includes(role) && {
        title: `${intl.formatMessage({ id: 'nav.profile' })}`,
        icon: UserOutlined,
        path: '/app/profile',
        key: 'profile',
      },
    {
      title: `${intl.formatMessage({ id: 'nav.account' })}`,
      icon: SettingOutlined,
      path: registrationTerminated
        ? '/app/account'
        : '/app-registration/account',
      key: 'account',
    },
  ];

  _.remove(menuItem, i => !i);

  const profileMenu = (
    <div className="nav-profile nav-dropdown w-100">
      <div className="nav-profile-body">
        <Menu
          onSelect={({ key }) => (key !== 'signOut' ? setMenuKey(key) : null)}
        >
          {menuItem.map(el => (
            <Menu.Item className="border-bottom options" key={el.key}>
              <Link to={el.path}>
                <div className="d-flex align-items-center justify-content-between">
                  <span className="mr-4">{el.title}</span>
                  <Icon className="mr-0" type={el.icon} />
                </div>
              </Link>
            </Menu.Item>
          ))}
          <Menu.Item key="signOut" onClick={signOut}>
            <div className="d-flex align-items-center justify-content-between">
              <span className="mr-4">
                <IntlMessage id="button.signout" />
              </span>
              <LogoutOutlined className="mr-0" />
            </div>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );

  return (
    <Dropdown
      className="mr-sm-4"
      placement="bottomRight"
      overlay={profileMenu}
      trigger={['click']}
    >
      <Menu className="d-flex align-item-center h-100" mode="horizontal">
        <Menu.Item className="px-0">
          <Avatar avatar={Utils.getAvatar(role)} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

const mapStateToProps = ({ auth, appStatus }) => {
  const { role } = auth;
  const { status } = appStatus;
  return { role, status };
};

export default connect(mapStateToProps, { signOut, setMenuKey })(NavProfile);
