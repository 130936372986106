import { GESTIONE_RILIEVO } from 'constants/CamundaConstant';
import { STATUS_TODO, TEC_APE } from 'constants/ProjectsConstant';
import { GENERIC_TASKS, TECHNICIAN_TASKS } from './CommonTasks';

export const TEC_APE_STEPS = [
  {
    code: TEC_APE,
    icon: null,
    order: 1,
  },
];

export const TEC_APE_TASKS = [
  ...TECHNICIAN_TASKS,
  ...GENERIC_TASKS,
  {
    code: GESTIONE_RILIEVO,
    currentStep: 0,
    status: STATUS_TODO,
    component: GESTIONE_RILIEVO,
    message: GESTIONE_RILIEVO,
  },
];
