import {
  ATTESA_GESTIONE_PROCEDURA,
  ATTESA_CONTRATTO_APPALTO,
  ATTESA_COMPUTO_METRICO,
  ESECUZIONE_LAVORI,
  VERIFICA_COMPUTO_METRICO,
} from 'constants/CamundaConstant';
import { GENERAL_CONTRACTOR, STATUS_TODO, STATUS_WAITING } from 'constants/ProjectsConstant';
import { GENERIC_TASKS } from './CommonTasks';

export const GENERAL_CONTRACTOR_STEPS = [
  {
    code: GENERAL_CONTRACTOR,
    icon: null,
    order: 1,
  },
];

export const ATTESA_APPALTATRICE = 'attesa_appaltatrice';

export const GENERAL_CONTRACTOR_TASKS = [
  {
    code: ATTESA_GESTIONE_PROCEDURA,
    status: STATUS_WAITING,
    component: ATTESA_GESTIONE_PROCEDURA,
    message: ATTESA_GESTIONE_PROCEDURA,
  },
  {
    code: ATTESA_CONTRATTO_APPALTO,
    status: STATUS_WAITING,
    component: ATTESA_APPALTATRICE,
    message: ATTESA_CONTRATTO_APPALTO,
  },
  {
    code: VERIFICA_COMPUTO_METRICO,
    status: STATUS_TODO,
    component: VERIFICA_COMPUTO_METRICO,
    message: VERIFICA_COMPUTO_METRICO,
  },
  {
    code: ATTESA_COMPUTO_METRICO,
    status: STATUS_WAITING,
    component: ATTESA_COMPUTO_METRICO,
    message: ATTESA_COMPUTO_METRICO,
  },
  {
    code: ESECUZIONE_LAVORI,
    status: STATUS_WAITING,
    component: ESECUZIONE_LAVORI,
    message: ESECUZIONE_LAVORI,
  },
  ...GENERIC_TASKS,
];
