import { STATUS_PROCESSING, STATUS_TODO, STATUS_WAITING } from 'constants/ProjectsConstant';
import {
  ATTESA_CHIUSURA_PROGETTO,
  ATTESA_CONTRATTO_APPALTO,
  ATTESA_GESTIONE_PROCEDURA,
  ATTESA_PRENOTAZIONE,
  ATTESA_PREVENTIVO_MASSIMA,
  ATTESA_PROGETTO_ESECUTIVO,
  ATTESA_PROGETTO_PRELIMINARE,
  ATTESA_RILIEVO,
  ATTESA_SOPRALLUOGO,
  ESECUZIONE_LAVORI,
  FIRMA_CONTRATTO,
  PROPOSTA_PRENOTAZIONE,
  VALUTAZIONE_TECNICO,
  VERIFICA_PROPOSTA_PRENOTAZIONE,
  PAGAMENTO,
  ACCETTAZIONE_DOCUMENTI_PREVENTIVI,
  ATTESA_APPUNTAMENTO,
  ATTESA_CARICAMENTO_DOCUMENTI,
  ATTESA_ACCETTAZIONE_DOCUMENTI,
  COMPILAZIONE_RAPPORTO,
  CARICAMENTO_DOCUMENTI,
  SELEZIONE_CATEGORIE,
  ATTESA_VERIFICA_PAGAMENTO,
  ATTESA_DOCUMENTI_PREVENTIVI,
  ATTESA_DOCUMENTAZIONE,
  ATTESA_COMPILAZIONE,
} from 'constants/CamundaConstant';

export const GENERIC_TASKS = [
  {
    code: STATUS_PROCESSING,
    status: STATUS_PROCESSING,
    component: STATUS_PROCESSING,
    message: STATUS_PROCESSING,
  },
  {
    code: FIRMA_CONTRATTO,
    status: STATUS_TODO,
    component: FIRMA_CONTRATTO,
    message: FIRMA_CONTRATTO,
  },
  {
    code: ATTESA_GESTIONE_PROCEDURA,
    status: STATUS_WAITING,
    component: ATTESA_GESTIONE_PROCEDURA,
    message: ATTESA_GESTIONE_PROCEDURA,
  },
];

export const TECHNICIAN_TASKS = [
  {
    code: ATTESA_PRENOTAZIONE,
    status: STATUS_WAITING,
    component: ATTESA_PRENOTAZIONE,
    message: ATTESA_PRENOTAZIONE,
  },
  {
    code: ATTESA_COMPILAZIONE,
    status: STATUS_WAITING,
    component: ATTESA_COMPILAZIONE,
    message: ATTESA_COMPILAZIONE,
  },
  {
    code: PROPOSTA_PRENOTAZIONE,
    timer: 30,
    status: STATUS_TODO,
    component: PROPOSTA_PRENOTAZIONE,
    message: PROPOSTA_PRENOTAZIONE,
  },
  {
    code: ATTESA_CARICAMENTO_DOCUMENTI,
    status: STATUS_WAITING,
    component: ATTESA_CARICAMENTO_DOCUMENTI,
    message: ATTESA_CARICAMENTO_DOCUMENTI,
  },
  {
    code: ATTESA_ACCETTAZIONE_DOCUMENTI,
    status: STATUS_WAITING,
    component: ATTESA_ACCETTAZIONE_DOCUMENTI,
    message: ATTESA_ACCETTAZIONE_DOCUMENTI,
  },
  {
    code: ATTESA_CONTRATTO_APPALTO,
    status: STATUS_WAITING,
    component: ATTESA_CONTRATTO_APPALTO,
    message: ATTESA_CONTRATTO_APPALTO,
  },
  {
    code: COMPILAZIONE_RAPPORTO,
    status: STATUS_TODO,
    component: COMPILAZIONE_RAPPORTO,
    message: COMPILAZIONE_RAPPORTO,
  },
  {
    code: CARICAMENTO_DOCUMENTI,
    status: STATUS_TODO,
    component: CARICAMENTO_DOCUMENTI,
    message: CARICAMENTO_DOCUMENTI,
  },
  {
    code: ESECUZIONE_LAVORI,
    status: STATUS_WAITING,
    component: ESECUZIONE_LAVORI,
    message: ESECUZIONE_LAVORI,
  },
  {
    code: SELEZIONE_CATEGORIE,
    status: STATUS_TODO,
    component: SELEZIONE_CATEGORIE,
    message: SELEZIONE_CATEGORIE,
  },
];

export const USER_TASKS = [
  {
    code: ATTESA_CHIUSURA_PROGETTO,
    status: STATUS_WAITING,
    component: ATTESA_CHIUSURA_PROGETTO,
    message: ATTESA_CHIUSURA_PROGETTO,
  },
  {
    code: ATTESA_DOCUMENTAZIONE,
    status: STATUS_WAITING,
    component: ATTESA_DOCUMENTAZIONE,
    message: ATTESA_DOCUMENTAZIONE,
  },
  {
    code: ATTESA_DOCUMENTI_PREVENTIVI,
    status: STATUS_WAITING,
    component: ATTESA_DOCUMENTI_PREVENTIVI,
    message: ATTESA_DOCUMENTI_PREVENTIVI,
  },
  {
    code: ATTESA_VERIFICA_PAGAMENTO,
    status: STATUS_WAITING,
    component: ATTESA_VERIFICA_PAGAMENTO,
    message: ATTESA_VERIFICA_PAGAMENTO,
  },
  {
    code: VALUTAZIONE_TECNICO,
    status: STATUS_TODO,
    component: VALUTAZIONE_TECNICO,
    message: VALUTAZIONE_TECNICO,
  },
  {
    code: VERIFICA_PROPOSTA_PRENOTAZIONE,
    status: STATUS_TODO,
    component: VERIFICA_PROPOSTA_PRENOTAZIONE,
    message: VERIFICA_PROPOSTA_PRENOTAZIONE,
  },
  {
    code: ACCETTAZIONE_DOCUMENTI_PREVENTIVI,
    status: STATUS_TODO,
    component: ACCETTAZIONE_DOCUMENTI_PREVENTIVI,
    message: ACCETTAZIONE_DOCUMENTI_PREVENTIVI,
  },
  {
    code: ATTESA_APPUNTAMENTO,
    status: STATUS_WAITING,
    component: ATTESA_APPUNTAMENTO,
    message: ATTESA_APPUNTAMENTO,
  },
  {
    code: ATTESA_SOPRALLUOGO,
    status: STATUS_WAITING,
    component: ATTESA_SOPRALLUOGO,
    message: ATTESA_SOPRALLUOGO,
  },
  {
    code: ATTESA_RILIEVO,
    status: STATUS_WAITING,
    component: ATTESA_RILIEVO,
    message: ATTESA_RILIEVO,
  },
  {
    code: ATTESA_PREVENTIVO_MASSIMA,
    status: STATUS_WAITING,
    component: ATTESA_PREVENTIVO_MASSIMA,
    message: ATTESA_PREVENTIVO_MASSIMA,
  },
  {
    code: ATTESA_PROGETTO_PRELIMINARE,
    status: STATUS_WAITING,
    component: ATTESA_PROGETTO_PRELIMINARE,
    message: ATTESA_PROGETTO_PRELIMINARE,
  },
  {
    code: ATTESA_PROGETTO_ESECUTIVO,
    status: STATUS_WAITING,
    component: ATTESA_PROGETTO_ESECUTIVO,
    message: ATTESA_PROGETTO_ESECUTIVO,
  },
  {
    code: ATTESA_CONTRATTO_APPALTO,
    status: STATUS_WAITING,
    component: ATTESA_CONTRATTO_APPALTO,
    message: ATTESA_CONTRATTO_APPALTO,
  },
  {
    code: ESECUZIONE_LAVORI,
    status: STATUS_WAITING,
    component: ESECUZIONE_LAVORI,
    message: ESECUZIONE_LAVORI,
  },
  {
    code: PAGAMENTO,
    status: STATUS_TODO,
    component: PAGAMENTO,
    message: PAGAMENTO,
  },
];
