import fetch from './interceptor';

const TechnicianService = {};

TechnicianService.submitPersonalRegistry = data =>
  fetch({
    url: '/api/technician/insert/registry/personal',
    method: 'post',
    data,
  });

TechnicianService.getPersonalRegistry = params =>
  fetch({
    url: '/api/technician/get/registry/personal',
    method: 'get',
    params,
  });

TechnicianService.getAssignments = () =>
  fetch({
    url: '/api/project/get/user-assignments',
    method: 'get',
  });

TechnicianService.getClosedAssignments = () =>
  fetch({
    url: '/api/project/get/user-assignments-archive',
    method: 'get',
  });

TechnicianService.updatePersonalRegistry = data =>
  fetch({
    url: '/api/technician/update/registry-personal',
    method: 'put',
    data,
  });

TechnicianService.submitProfessionalRegistry = data =>
  fetch({
    url: '/api/technician/insert/registry/professional',
    method: 'post',
    data,
  });

TechnicianService.getProfessionalRegistry = params =>
  fetch({
    url: '/api/technician/get/registry/professional',
    method: 'get',
    params,
  });

TechnicianService.updateProfessionalRegistry = data =>
  fetch({
    url: '/api/technician/update/registry-professional',
    method: 'put',
    data,
  });

TechnicianService.updateSocial = data =>
  fetch({
    url: '/api/technician/update/registry-link',
    method: 'put',
    data,
  });

TechnicianService.submitRegisters = data =>
  fetch({
    url: '/api/technician/insert/register',
    method: 'post',
    data,
  });

TechnicianService.getRegisters = () =>
  fetch({
    url: '/api/technician/get/register',
    method: 'get',
  });

TechnicianService.updateRegisters = data =>
  fetch({
    url: '/api/technician/update/register',
    method: 'put',
    data,
  });

TechnicianService.submitCourses = data =>
  fetch({
    url: '/api/technician/insert/course',
    method: 'post',
    data,
  });

TechnicianService.getCourses = () =>
  fetch({
    url: '/api/technician/get/course',
    method: 'get',
  });

TechnicianService.updateCourses = data =>
  fetch({
    url: '/api/technician/update/course',
    method: 'put',
    data,
  });

TechnicianService.getCompetences = () =>
  fetch({
    url: '/api/technician/get/competences',
    method: 'get',
  });

TechnicianService.submitCompetences = data =>
  fetch({
    url: '/api/technician/insert/competence',
    method: 'post',
    data,
  });

TechnicianService.getActualCompetences = params =>
  fetch({
    url: '/api/technician/get/competence',
    method: 'get',
    params,
  });

TechnicianService.updateCompetences = data =>
  fetch({
    url: '/api/technician/update/competence',
    method: 'put',
    data,
  });

TechnicianService.getFigures = () =>
  fetch({
    url: '/api/technician/get/figures',
    method: 'get',
  });

TechnicianService.getMacroFigures = () =>
  fetch({
    url: '/api/technician/get/macrofigures',
    method: 'get',
  });

TechnicianService.submitFigures = data =>
  fetch({
    url: '/api/technician/insert/figure',
    method: 'post',
    data,
  });

TechnicianService.getActualFigures = params =>
  fetch({
    url: '/api/technician/get/figure',
    method: 'get',
    params,
  });

TechnicianService.updateFigures = data =>
  fetch({
    url: '/api/technician/update/figure',
    method: 'put',
    data,
  });

TechnicianService.getAppointments = params =>
  fetch({
    url: '/api/technician/get/booking-list',
    method: 'get',
    params,
  });

TechnicianService.getProposedDates = params =>
  fetch({
    url: '/api/technician/get/booking-proposal',
    method: 'get',
    params,
  });

TechnicianService.getInsurancePolicies = params =>
  fetch({
    url: '/api/technician/get/insurance-policy',
    method: 'get',
    params,
  });

TechnicianService.submitInsurancePolicy = data =>
  fetch({
    url: '/api/technician/insert/insurance-policy',
    method: 'post',
    data,
  });

TechnicianService.updateInsurancePolicy = data =>
  fetch({
    url: '/api/technician/update/insurance-policy',
    method: 'put',
    data,
  });

TechnicianService.deleteInsurancePolicy = params =>
  fetch({
    url: '/api/technician/delete/insurance-policy',
    method: 'delete',
    params,
  });

TechnicianService.getWorksAmount = params =>
  fetch({
    url: '/api/technician/get/works-amount',
    method: 'get',
    params,
  });

TechnicianService.saveCondominium = data =>
  fetch({
    url: '/api/technician/insert/condominium',
    method: 'post',
    data,
  });

TechnicianService.deleteCondominium = params =>
  fetch({
    url: '/api/technician/delete/condominium',
    method: 'delete',
    params,
  });

TechnicianService.saveBuilding = data =>
  fetch({
    url: '/api/technician/insert/building',
    method: 'post',
    data,
  });

TechnicianService.getBuildingDetail = params =>
  fetch({
    url: '/api/technician/get/building-detail',
    method: 'get',
    params,
  });

TechnicianService.saveBuildingDetail = data =>
  fetch({
    url: '/api/technician/insert/building-detail',
    method: 'post',
    data,
  });

TechnicianService.getBuildingPortions = params =>
  fetch({
    url: '/api/technician/get/building-portions',
    method: 'get',
    params,
  });

TechnicianService.saveBuildingPortion = data =>
  fetch({
    url: '/api/technician/insert/building-portion',
    method: 'post',
    data,
  });

TechnicianService.deleteBuildingPortion = params =>
  fetch({
    url: '/api/technician/delete/building-portion',
    method: 'delete',
    params,
  });

TechnicianService.getProjectWorkingOperations = params =>
  fetch({
    url: '/api/technician/get/project-working-operation',
    method: 'get',
    params,
  });

TechnicianService.saveProjectWorkingOperations = ({ data, params }) =>
  fetch({
    url: '/api/technician/insert/project-working-operation',
    method: 'post',
    data,
    params,
  });

TechnicianService.getApeDetail = params =>
  fetch({
    url: '/api/technician/get/ape-detail',
    method: 'get',
    params,
  });

TechnicianService.saveApeDetail = data =>
  fetch({
    url: '/api/technician/insert/ape-detail',
    method: 'post',
    data,
  });

TechnicianService.getCondominiumRooms = params =>
  fetch({
    url: '/api/technician/get/condominium-room-list',
    method: 'get',
    params,
  });

TechnicianService.saveCondominiumRoom = data =>
  fetch({
    url: '/api/technician/insert/condominium-room',
    method: 'post',
    data,
  });

TechnicianService.deleteCondominiumRoom = params =>
  fetch({
    url: '/api/technician/delete/condominium-room',
    method: 'delete',
    params,
  });

export default TechnicianService;
