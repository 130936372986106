import _ from 'lodash';
import fetch from './interceptor';

const ProjectsService = {};

ProjectsService.startProject = data =>
  fetch({
    url: '/api/project/start',
    method: 'post',
    data,
  });

ProjectsService.listProjects = () =>
  fetch({
    url: '/api/project/get/projects-list',
    method: 'get',
  });

ProjectsService.getDocumentsList = (projectId, statusList, typeList = [], countersigned) => {
  const formData = new FormData();
  formData.append('projectId', projectId);
  formData.append('projectDocumentStatusList', JSON.stringify(statusList));
  formData.append('projectDocumentTypeList', JSON.stringify(typeList));
  if (!_.isNil(countersigned)) formData.append('countersigned', countersigned);

  return fetch({
    url: '/api/project/documents/list',
    method: 'put',
    data: formData,
  });
};

ProjectsService.uploadFile = (file, fileDetails, requestedId) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('projectDocument', JSON.stringify(fileDetails));
  if (requestedId) formData.append('projectDocumentId', requestedId);

  return fetch({
    url: '/api/project/document/upload',
    method: 'post',
    data: formData,
  });
};

ProjectsService.deleteFile = params =>
  fetch({
    url: '/api/project/document/delete',
    method: 'delete',
    params,
  });

ProjectsService.closeTask = content => {
  const { projectId, taskId, data, params, service, variables } = content;

  return fetch({
    url: `/api${service}`,
    method: 'post',
    params: { projectId, taskId, variables: JSON.stringify(variables), ...params },
    data,
  });
};

ProjectsService.getIframe = params =>
  fetch({
    url: '/api/sign/get/signature-procedure-iframe',
    method: 'get',
    params,
  });

ProjectsService.getPaymentInfo = params =>
  fetch({
    url: '/api/payment/get/payment-info',
    method: 'get',
    params,
  });

ProjectsService.getSupplierClosedProjects = () =>
  fetch({
    url: '/api/project/get/suppliers-projects-closed',
    method: 'get',
  });

ProjectsService.getWorkingOperations = () =>
  fetch({
    url: '/api/project/get/working-operations',
    method: 'get',
  });

ProjectsService.getTechnicianEvaluation = params =>
  fetch({
    url: '/api/project/get/technician/evaluation',
    method: 'get',
    params,
  });

ProjectsService.getAssignments = () =>
  fetch({
    url: '/api/project/get/assignments',
    method: 'get',
  });

ProjectsService.getProjectCompanyCategoriesIds = params =>
  fetch({
    url: '/api/project/get/project-company-category-ids',
    method: 'get',
    params,
  });

ProjectsService.getProjectTechnicianAssignments = params =>
  fetch({
    url: '/api/project/get/project-assignment-ids',
    method: 'get',
    params,
  });

ProjectsService.getTemplate = params =>
  fetch({
    url: '/api/project/get/template',
    method: 'get',
    params,
  });

ProjectsService.getPayments = params =>
  fetch({
    url: 'api/payment/get',
    method: 'get',
    params,
  });

ProjectsService.updateIban = params =>
  fetch({
    url: 'api/project/update/iban',
    method: 'put',
    params,
  });

ProjectsService.getBonusInfo = params =>
  fetch({
    url: 'api/project/get/project-bonus',
    method: 'get',
    params,
  });

ProjectsService.closeProject = params =>
  fetch({
    url: 'api/project/close',
    method: 'post',
    params,
  });

ProjectsService.createNewProject = data =>
  fetch({
    url: '/api/admin/create-project',
    method: 'post',
    data,
  });

ProjectsService.getNotes = params =>
  fetch({
    url: '/api/note/get/project-notes',
    method: 'get',
    params,
  });

ProjectsService.getDocumentComments = params =>
  fetch({
    url: '/api/project/document-comments',
    method: 'get',
    params,
  });

ProjectsService.insertDocumentComment = data =>
  fetch({
    url: '/api/project/insert/document-comment',
    method: 'post',
    data,
  });

ProjectsService.saveNote = data =>
  fetch({
    url: '/api/note/insert',
    method: 'post',
    data,
  });

ProjectsService.deleteNote = params =>
  fetch({
    url: '/api/note/delete',
    method: 'delete',
    params,
  });

ProjectsService.getProjectSuppliers = params =>
  fetch({
    url: '/api/project/get/project-suppliers',
    method: 'get',
    params,
  });

ProjectsService.getProjectCompanyCategories = params =>
  fetch({
    url: '/api/project/get/project-company-categories',
    method: 'get',
    params,
  });

ProjectsService.getDocumentDetail = params =>
  fetch({
    url: '/api/project/document',
    method: 'get',
    params,
  });

ProjectsService.updateDocumentStatus = params =>
  fetch({
    url: '/api/project/update/document',
    method: 'put',
    params,
  });

ProjectsService.getUnreadComments = params =>
  fetch({
    url: '/api/project/get/unread-comments',
    method: 'get',
    params,
  });

ProjectsService.readDocumentComments = data =>
  fetch({
    url: '/api/project/update/document-comment',
    method: 'put',
    data,
  });

export default ProjectsService;
