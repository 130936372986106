import React from 'react';
import { APP_NAME } from 'configs/AppConfig';
import { connect } from 'react-redux';
import Utils from 'utils';
import { Grid } from 'antd';

const { useBreakpoint } = Grid;

const Logo = props => {
  const { mobileLogo, navCollapsed, registration } = props;
  const isMobile = !Utils.getBreakPoint(useBreakpoint()).includes('lg');

  const getLogo = () => {
    if (
      (navCollapsed && !mobileLogo) ||
      (!registration && !mobileLogo && isMobile)
    ) {
      return '/img/logo_square.svg';
    }

    if (registration) {
      return '/img/logo_full.svg';
    }

    return '/img/logo_full_negative.svg';
  };

  const getLogoWidth = () => {
    if (
      (navCollapsed && !mobileLogo) ||
      (!registration && !mobileLogo && isMobile)
    ) {
      return '40px';
    }

    return '140px';
  };

  return (
    <div className="logo justify-content-center">
      <img src={getLogo()} alt={`${APP_NAME} logo`} width={getLogoWidth()} />
    </div>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed } = theme;
  return { navCollapsed };
};

export default connect(mapStateToProps)(Logo);
