export const MAX_SIZE_FILE = 10000000;
export const IMAGES_EXTENSIONS = ['.jpeg', '.png', '.heic', '.jpg', '.heif'];
export const DEFAULT_ACCEPTED_FORMATS = ['.pdf', ...IMAGES_EXTENSIONS];

export const BASE_URLS = [
  '/app-registration',
  '/app/projects',
  '/app/payments',
  '/app/assignments',
  '/app/profile',
  '/app/tasklist',
  '/app-auth',
  '/app',
];

export const PUBLIC_URLS = ['/app-auth', '/app-error'];

export const DRAG_TYPES = {
  APPOINTMENT: 'appointment',
};

export const TECHNICIAN = 'technician';
export const COMPANY = 'company';
export const CUSTOMER = 'customer';
export const CONDO = 'condo';
export const ADMIN = 'administrator';

export const CONDO_CHILD = 'condo_child';

export const ROLES = [TECHNICIAN, COMPANY, CUSTOMER, CONDO, ADMIN];
export const CLIENT_TYPE = [CUSTOMER, CONDO, CONDO_CHILD];
export const SUPPLIERS_TYPE = [TECHNICIAN, COMPANY];
export const NOT_ADMIN = ROLES.filter(role => role !== ADMIN);

// DOCUMENTS STATUS
export const DOCUMENT_PENDING = 'PENDING';
export const DOCUMENT_ACCEPTED = 'ACCEPTED';
export const DOCUMENT_REFUSED = 'REFUSED';
export const DOCUMENT_REQUESTED = 'REQUESTED';

export const DOCUMENT_ALL = [DOCUMENT_PENDING, DOCUMENT_ACCEPTED, DOCUMENT_REFUSED, DOCUMENT_REQUESTED];

export const TECHNICIAN_TRAINING_TYPES = [
  'technician.training_1',
  'technician.training_2',
  'technician.training_3',
  'technician.training_4',
  'technician.training_5',
  'technician.training_6',
];

export const TECHNICIAN_TITLES = [
  'technician.title_1',
  'technician.title_2',
  'technician.title_3',
  'technician.title_4',
  'technician.title_5',
  'technician.title_6',
  'technician.title_7',
];

export const RATE_COUNT = 8;

export const RATE_OPTIONS = ['rate_1', 'rate_2', 'rate_3', 'rate_4', 'rate_5', 'rate_6', 'rate_7', 'rate_8'];

export const SOCIAL_FIELDS = ['facebookLink', 'instagramLink', 'linkedinLink', 'siteLink', 'twitterLink'];

export const HEADER_FIELDS = ['title', 'name', 'surname', 'mail'];

export const SUPPLIERS_TERMS_URL =
  'https://storage.googleapis.com/superpratico-terms/Informativa%20a_%20fornitori%20e%20referenti%20presso%20Aziende%20Fornitrici.pdf';

export const CUSTOMERS_TERMS_URL =
  'https://storage.googleapis.com/superpratico-terms/Informativa%20a_%20clienti%20e%20referenti%20presso%20aziende%20Clienti.pdf';
