export const GET_PROJECTS = 'GET_PROJECTS';
export const SET_PROJECTS = 'SET_PROJECTS';
export const START_PROJECT = 'START_PROJECT';

export const UPLOAD_PROJECT_FILE = 'UPLOAD_PROJECT_FILE';

export const CLOSE_TASK = 'CLOSE_TASK';

export const SET_ASSIGNMENTS = 'SET_ASSIGNMENTS';
export const GET_SEEN_ASSIGNMENTS = 'GET_SEEN_ASSIGNMENTS';
export const SET_SEEN_ASSIGNMENTS = 'SET_SEEN_ASSIGNMENTS';

export const GET_WORKING_OPERATIONS = 'GET_WORKING_OPERATIONS';
export const SET_WORKING_OPERATIONS = 'SET_WORKING_OPERATIONS';

export const GET_PAYMENTS = 'GET_PAYMENTS';
export const SET_PAYMENTS = 'SET_PAYMENTS';

export const GET_BUILDING = 'GET_BUILDING';
export const SET_BUILDING = 'SET_BUILDING';
