import { all, takeEvery, put, fork } from 'redux-saga/effects';
import _ from 'lodash';
import { message } from 'antd';
import CompanyService from 'services/CompanyService';
import { setAssignments } from 'redux/actions/Projects';
import moment from 'moment';
import { setRegistry, setMacro, setCategories, setBalanceFields, getCategories, getRegistry } from 'redux/actions/Company';
import {
  GET_BALANCE_FIELDS,
  GET_CATEGORIES,
  GET_REGISTRY,
  SUBMIT_BALANCE,
  SUBMIT_BALANCE_DETAIL,
  SUBMIT_CATEGORIES,
  SUBMIT_DURC,
  SUBMIT_REGISTRY,
  UPDATE_CATEGORIES,
  UPDATE_REGISTRY,
  UPDATE_SOCIAL,
  GET_COMPANY_ASSIGNMENTS,
} from '../constants/Company';
import { showLoading, hideLoading } from '../actions/Auth';
import { setError, getStatus } from '../actions/Status';

export function* getRegistrySaga() {
  yield takeEvery(GET_REGISTRY, function* (action) {
    const { params } = action.payload;

    try {
      const res = yield CompanyService.getRegistry(params);
      const data = _.omit(res.object, ['id']);

      const formattedData = {
        ...data,
        chamberCommerceRegistrationDate: moment(data.chamberCommerceRegistrationDate),
      };

      yield put(setRegistry(formattedData));
    } catch (err) {
      if (err.view) {
        yield put(setError(err.code));
      }
    }
  });
}

export function* submitRegistry() {
  yield takeEvery(SUBMIT_REGISTRY, function* (action) {
    const { data, intl } = action.payload;

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.sending' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      yield CompanyService.submitRegistry(data);

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(setRegistry(data.companyDetail));
      yield put(getStatus());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* updateRegistry() {
  yield takeEvery(UPDATE_REGISTRY, function* (action) {
    const { registry, intl } = action.payload;
    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.updating' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      yield CompanyService.updateRegistry(registry);

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(getRegistry());
      yield put(hideLoading());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* updateSocial() {
  yield takeEvery(UPDATE_SOCIAL, function* (action) {
    const { social, intl } = action.payload;

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.updating' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      yield CompanyService.updateSocial(social);

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(getRegistry());
      yield put(hideLoading());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* submitDurc() {
  yield takeEvery(SUBMIT_DURC, function* (action) {
    const { intl } = action.payload;

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.sending' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      yield CompanyService.submitDurc();

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(getStatus());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* getCategoriesList() {
  yield takeEvery(GET_CATEGORIES, function* (action) {
    const { params, notCompany } = action.payload;

    try {
      const resMacro = yield CompanyService.getMacroCategories();
      const resCategories = yield CompanyService.getCategories();
      const macro = resMacro.objectList;

      if (notCompany) {
        yield put(setCategories(resCategories.objectList));
      } else {
        const resActual = yield CompanyService.getActualCategories(params);
        const actualCategories = resActual.object.categoryIds;

        const categories = resCategories.objectList.map(c => ({
          ...c,
          checked: actualCategories.includes(c.id),
        }));

        yield put(setCategories(categories));
      }

      yield put(setMacro(macro));
    } catch (err) {
      if (err.view) {
        yield put(setError(err.code));
      }
    }
  });
}

export function* submitCategories() {
  yield takeEvery(SUBMIT_CATEGORIES, function* (action) {
    const { categories, intl } = action.payload;

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.sending' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      yield CompanyService.submitCategories(categories);

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(getCategories());
      yield put(getStatus());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* updateCategories() {
  yield takeEvery(UPDATE_CATEGORIES, function* (action) {
    const { categories, intl } = action.payload;

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.updating' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      yield CompanyService.updateCategories(categories);

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(getCategories());
      yield put(hideLoading());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* getBalanceFields() {
  yield takeEvery(GET_BALANCE_FIELDS, function* () {
    try {
      const res = yield CompanyService.getBalanceFormFields();

      const initialFields = res.objectList.map(f => ({
        ...f,
        initialValue: f.financeType === 'INTEGER' ? null : false,
      }));

      yield put(setBalanceFields(initialFields));
    } catch (err) {
      if (err.view) {
        yield put(setError(err.code));
      }
    }
  });
}

export function* submitBalance() {
  yield takeEvery(SUBMIT_BALANCE, function* (action) {
    const { values, intl } = action.payload;

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.sending' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      yield CompanyService.submitBalance(values);

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(getStatus());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* submitBalanceDetail() {
  yield takeEvery(SUBMIT_BALANCE_DETAIL, function* (action) {
    const { data, intl } = action.payload;

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.sending' })}`,
      key,
      duration: 0,
    });
    yield put(showLoading());

    try {
      yield CompanyService.submitBalanceDetail(data);

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(getStatus());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* getAssignmentsSaga() {
  yield takeEvery(GET_COMPANY_ASSIGNMENTS, function* () {
    try {
      const { objectList } = yield CompanyService.getAssignments();

      yield put(setAssignments(objectList));
    } catch (err) {
      if (err.view) {
        yield put(setError(err.code));
      }
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getRegistrySaga),
    fork(updateRegistry),
    fork(updateSocial),
    fork(getCategoriesList),
    fork(submitRegistry),
    fork(submitDurc),
    fork(submitCategories),
    fork(updateCategories),
    fork(getBalanceFields),
    fork(submitBalance),
    fork(submitBalanceDetail),
    fork(getAssignmentsSaga),
  ]);
}
