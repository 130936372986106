import { TEC_TERMOTECNICO } from 'constants/ProjectsConstant';
import { GENERIC_TASKS, TECHNICIAN_TASKS } from './CommonTasks';

export const TEC_TERMOTECNICO_STEPS = [
  {
    code: TEC_TERMOTECNICO,
    icon: null,
    order: 1,
  },
];

export const TEC_TERMOTECNICO_TASKS = [...TECHNICIAN_TASKS, ...GENERIC_TASKS];
