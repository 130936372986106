export const GET_REGISTRY = 'GET_COMPANY_REGISTRY';
export const SUBMIT_REGISTRY = 'SUBMIT_COMPANY_REGISTRY';
export const SET_REGISTRY = 'SET_COMPANY_REGISTRY';
export const UPDATE_REGISTRY = 'UPDATE_COMPANY_REGISTRY';
export const UPDATE_SOCIAL = 'UPDATE_COMPANY_SOCIAL';

export const GET_COMPANY_ASSIGNMENTS = 'GET_COMPANY_ASSIGNMENTS';

export const GET_CATEGORIES = 'GET_COMPANY_CATEGORIES';
export const SUBMIT_CATEGORIES = 'SUBMIT_COMPANY_CATEGORIES';
export const SET_MACRO = 'SET_COMPANY_MACRO';
export const SET_CATEGORIES = 'SET_COMPANY_CATEGORIES';
export const UPDATE_CATEGORIES = 'UPDATE_COMPANY_CATEGORIES';

export const GET_BALANCE_FIELDS = 'GET_BALANCE_FIELDS';
export const SET_BALANCE_FIELDS = 'SET_BALANCE_FIELDS';
export const SUBMIT_BALANCE = 'SUBMIT_BALANCE';
export const SUBMIT_BALANCE_DETAIL = 'SUBMIT_BALANCE_DETAIL';
export const SUBMIT_DURC = 'SUBMIT_DURC';
