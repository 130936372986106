// PROJECT TASKS
export const ACCETTAZIONE_DOCUMENTI_PREVENTIVI = 'accettazione_documenti_preventivi';
export const INVITI_CONDOMINI = 'inviti_condomini';
export const VALUTAZIONE_TECNICO = 'valutazione_tecnico';
export const VERIFICA_PROPOSTA_PRENOTAZIONE = 'verifica_proposta_prenotazione';

// COMPANY TASKS
export const VERIFICA_COMPUTO_METRICO = 'verifica_computo_metrico';

// TECHNICIAN TASKS
export const COMPILAZIONE_RAPPORTO = 'compilazione_rapporto';
export const GESTIONE_RILIEVO = 'gestione_rilievo';
export const INSERIMENTO_IMPORTI = 'inserimento_importi';
export const PROPOSTA_PRENOTAZIONE = 'proposta_prenotazione';
export const SELEZIONE_CATEGORIE = 'selezione_categorie';
export const SELEZIONE_FIGURE = 'selezione_figure';
export const VERIFICA_DOCUMENTI = 'verifica_documenti';

// ADMIN TASKS
export const ASSEGNAZIONE_AZIENDE = 'assegnazione_aziende';
export const ASSEGNAZIONE_AZIENDA_APPALTATRICE = 'assegnazione_azienda_appaltatrice';
export const ASSEGNAZIONE_TECNICI = 'assegnazione_tecnici';
export const CALCOLO_PAGAMENTO_PROGETTAZIONE = 'calcolo_pagamento_progettazione';
export const CREAZIONE_PROGETTO = 'creazione_progetto';
export const GESTIONE_PROCEDURA_FIRMA = 'gestione_procedura_firma';
export const VALIDAZIONE_DOCUMENTO = 'validazione_documento';
export const VERIFICA_RICEZIONE_PAGAMENTO = 'verifica_ricezione_pagamento';

// GENERIC TASKS
export const ACCETTAZIONE_ASSEGNAZIONE = 'accettazione_assegnazione';
export const CARICAMENTO_DOCUMENTI = 'caricamento_documenti';
export const FIRMA_CONTRATTO = 'firma_contratto';
export const PAGAMENTO = 'pagamento';

// PROJECT EVENTS
export const ATTESA_APPUNTAMENTO = 'attesa_appuntamento';
export const ATTESA_CHIUSURA_PROGETTO = 'attesa_chiusura_progetto';
export const ATTESA_DOCUMENTAZIONE = 'attesa_documentazione';
export const ATTESA_DOCUMENTI_PREVENTIVI = 'attesa_documenti_preventivi';
export const ATTESA_PRECONTRATTO = 'attesa_precontratto';
export const ATTESA_PREVENTIVO_MASSIMA = 'attesa_preventivo_massima';
export const ATTESA_PROGETTO_ESECUTIVO = 'attesa_progetto_esecutivo';
export const ATTESA_PROGETTO_PRELIMINARE = 'attesa_progetto_preliminare';
export const ATTESA_RILIEVO = 'attesa_rilievo';
export const ATTESA_SOPRALLUOGO = 'attesa_sopralluogo';
export const ATTESA_VERIFICA_DOCUMENTI = 'attesa_verifica_documenti';
export const ATTESA_VERIFICA_PAGAMENTO = 'attesa_verifica_pagamento';

// COMPANY EVENTS
export const ATTESA_COMPUTO_METRICO = 'attesa_computo_metrico';

// TECHNICIAN EVENTS
export const ATTESA_ACCETTAZIONE_DOCUMENTI = 'attesa_accettazione_documenti';
export const ATTESA_CARICAMENTO_DOCUMENTI = 'attesa_caricamento_documenti';
export const ATTESA_COMPILAZIONE = 'attesa_compilazione';
export const ATTESA_PAGAMENTO = 'attesa_pagamento';
export const ATTESA_PRENOTAZIONE = 'attesa_prenotazione';

// GENERIC EVENTS
export const ATTESA_CONTRATTO_APPALTO = 'attesa_contratto_appalto';
export const ATTESA_GESTIONE_PROCEDURA = 'attesa_gestione_procedura';
export const ESECUZIONE_LAVORI = 'esecuzione_lavori';
