import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';

export const AuthViews = ({ match }) => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <div className="auth-container">
        <Switch>
          <Route path={`${match.url}/login`} component={lazy(() => import(`./authentication/login`))} />
          <Route path={`${match.url}/register`} component={lazy(() => import(`./authentication/register`))} />
          <Route path={`${match.url}/register-company`} component={lazy(() => import(`./authentication/register-company`))} />
          <Route
            path={`${match.url}/register-technician`}
            component={lazy(() => import(`./authentication/register-technician`))}
          />
          <Route path={`${match.url}/register-customer`} component={lazy(() => import(`./authentication/register-customer`))} />
          <Route path={`${match.url}/register-condo`} component={lazy(() => import(`./authentication/register-condo`))} />
          <Route path={`${match.url}/registration-sent`} component={lazy(() => import(`./authentication/registration-sent`))} />
          <Route path={`${match.url}/forgot-password`} component={lazy(() => import(`./authentication/forgot-password`))} />
          <Route path={`${match.url}/reset-password`} component={lazy(() => import(`./authentication/reset-password`))} />
          <Route path={`${match.url}/confirm-account`} component={lazy(() => import(`./authentication/confirm-account`))} />
          <Route
            path={`${match.url}/confirm-account-failed`}
            component={lazy(() => import(`./authentication/confirm-account-failed`))}
          />
          <Redirect from={`${match.url}`} to={`${match.url}/login`} />
        </Switch>
      </div>
    </Suspense>
  );
};

export default AuthViews;
