import React from 'react';
import { Layout, Grid } from 'antd';
import { Switch, Route, withRouter } from 'react-router-dom';
import HeaderRegistration from 'components/layout-components/HeaderRegistration';
import RegistrationViews from 'views/registration-views';
import Utils from 'utils';

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const RegistrationLayout = () => {
  const screens = Utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes('md');

  return (
    <Layout>
      <HeaderRegistration isMobile={isMobile} />
      <div className="registration-content p-3 p-sm-4 pl-md-0">
        <Content>
          <Switch>
            <Route path="" component={RegistrationViews} />
          </Switch>
        </Content>
      </div>
    </Layout>
  );
};

export default withRouter(RegistrationLayout);
