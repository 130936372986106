import { all, takeEvery, put, fork } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment';
import { message } from 'antd';
import AdminService from 'services/AdminService';
import { hideLoading, showLoading } from 'redux/actions/Auth';
import { COMPANY, CUSTOMER, TECHNICIAN, CONDO } from 'constants/AppConstant';
import { getRegistry } from 'redux/actions/Company';
import { setError } from '../actions/Status';
import {
  CLOSE_TASK,
  GET_ADMIN_DETAILS,
  GET_USERS,
  GET_TASKLIST_REGISTRATION,
  GET_TASKLIST_PROJECTS,
  UPDATE_ADMIN_DETAILS,
  UPDATE_COMPANY_DETAILS,
  UPDATE_IS_PAID,
  GET_TRUSTED_SUPPLIERS,
} from '../constants/Admin';
import {
  setUsers,
  setTasklistRegistration,
  setTasklistProjects,
  getTasklistRegistration,
  getTasklistProjects,
  setAdminDetails,
  getAdminDetails,
  setCompanies,
  setTechnicians,
  setCustomers,
  setCondominiums,
  setTrustedSuppliers,
} from '../actions/Admin';
import { getProjects } from '../actions/Projects';

export function* getAdminDetailsSaga() {
  yield takeEvery(GET_ADMIN_DETAILS, function* () {
    try {
      const res = yield AdminService.getAdminDetails();
      const data = _.omit(res.object, ['id', 'userId']);

      yield put(setAdminDetails(data));
    } catch (err) {
      if (err.view) {
        yield put(setError(err.code));
      }
    }
  });
}

export function* updateAdminDetails() {
  yield takeEvery(UPDATE_ADMIN_DETAILS, function* (action) {
    const { details, intl } = action.payload;

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.sending' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      yield AdminService.updateAdminDetails(details);

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(getAdminDetails());
      yield put(hideLoading());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* getUsers() {
  yield takeEvery(GET_USERS, function* () {
    try {
      const res = yield AdminService.getUsers();
      const users = res.objectList.map(user =>
        _.chain(user)
          .pickBy(_.identity)
          .mapValues(field => (typeof field === 'string' ? field.toLocaleLowerCase() : field))
          .value(),
      );

      yield put(setUsers(users));
      yield put(setCompanies(users.filter(user => user.role === COMPANY)));
      yield put(setTechnicians(users.filter(user => user.role === TECHNICIAN)));
      yield put(setCustomers(users.filter(user => user.role === CUSTOMER)));
      yield put(setCondominiums(users.filter(user => user.role === CONDO)));
    } catch (err) {
      if (err.view) {
        yield put(setError(err.code));
      }
    }
  });
}

export function* getTasksRegistration() {
  yield takeEvery(GET_TASKLIST_REGISTRATION, function* () {
    try {
      const res = yield AdminService.getTasklistRegistration();
      const tasklist = res.objectList;

      yield put(setTasklistRegistration(tasklist));
    } catch (err) {
      if (err.view) {
        yield put(setError(err.code));
      }
    }
  });
}

export function* getTasksProjects() {
  yield takeEvery(GET_TASKLIST_PROJECTS, function* () {
    try {
      const res = yield AdminService.getTasklistProjects();
      const tasklist = res.objectList.sort((a, b) => moment(b.projectTask.creationDate) - moment(a.projectTask.creationDate));

      yield put(setTasklistProjects(tasklist));
    } catch (err) {
      if (err.view) {
        yield put(setError(err.code));
      }
    }
  });
}

export function* closeTask() {
  yield takeEvery(CLOSE_TASK, function* (action) {
    const { userId, taskId, variables, intl } = action.payload;

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.sending' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      yield AdminService.completeTask(userId, taskId, variables);

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(getTasklistRegistration());
      yield put(getTasklistProjects());
      yield put(hideLoading());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* updateCompanyDetails() {
  yield takeEvery(UPDATE_COMPANY_DETAILS, function* (action) {
    const { data, intl } = action.payload;
    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.updating' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      yield AdminService.updateCompanyDetails(data);

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(getRegistry({ userId: data.userId }));
      yield put(hideLoading());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* updateIsPaid() {
  yield takeEvery(UPDATE_IS_PAID, function* (action) {
    const { projectSupplier, intl } = action.payload;

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.sending' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      yield AdminService.payTechnician({ id: projectSupplier.id });

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(getProjects());
      yield put(hideLoading());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* getTrustedSuppliers() {
  yield takeEvery(GET_TRUSTED_SUPPLIERS, function* (action) {
    const { projectId, role } = action.payload;
    try {
      const { objectList } = yield AdminService.getTrustedSuppliers({
        projectId,
        role,
      });

      yield put(setTrustedSuppliers(objectList));
    } catch (err) {
      if (err.view) {
        yield put(setError(err.code));
      }
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getAdminDetailsSaga),
    fork(updateAdminDetails),
    fork(getUsers),
    fork(getTasksRegistration),
    fork(getTasksProjects),
    fork(closeTask),
    fork(updateCompanyDetails),
    fork(updateIsPaid),
    fork(getTrustedSuppliers),
  ]);
}
