import _ from 'lodash';
import fetch from './interceptor';

const AppService = {};

AppService.getStatus = () =>
  fetch({
    url: '/registration/status',
    method: 'get',
  });

AppService.getRating = () =>
  fetch({
    url: '/api/rating/get/list-all',
    method: 'get',
  });

AppService.getIframe = () =>
  fetch({
    url: '/api/sign/get/iframe',
    method: 'get',
  });

AppService.changePassword = data =>
  fetch({
    url: '/api/user/update-password',
    method: 'put',
    data,
  });

AppService.sendSms = data =>
  fetch({
    url: '/api/sms/send',
    method: 'post',
    data,
  });

AppService.verifySmsCode = data =>
  fetch({
    url: '/api/sms/verify',
    method: 'post',
    data,
  });

AppService.deleteAccount = () =>
  fetch({
    url: '/api/delete/supplier',
    method: 'delete',
  });

AppService.terminateRegistration = () =>
  fetch({
    url: '/registration/terminate',
    method: 'get',
  });

AppService.getFiles = (userId, statusList, typeList = [], countersigned) => {
  const formData = new FormData();
  if (userId) formData.append('userId', userId);
  formData.append('userDocumentStatusList', JSON.stringify(statusList));
  formData.append('userDocumentTypeList', JSON.stringify(typeList));
  if (!_.isNil(countersigned)) formData.append('countersigned', countersigned);

  return fetch({
    url: '/api/user/files/get',
    method: 'put',
    data: formData,
  });
};

AppService.downloadFile = params =>
  fetch({
    url: '/api/user/document/download',
    method: 'get',
    params,
  });

AppService.getNote = params =>
  fetch({
    url: '/api/note/get/note',
    method: 'get',
    params,
  });

AppService.getProjectNotes = params =>
  fetch({
    url: '/api/note/get/project-notes',
    method: 'get',
    params,
  });

AppService.getUnreadComments = params =>
  fetch({
    url: '/api/user/get/unread-comments',
    method: 'get',
    params,
  });

AppService.readDocumentComments = data =>
  fetch({
    url: '/api/user/update/document-comment',
    method: 'put',
    data,
  });

AppService.requestDocument = params =>
  fetch({
    url: '/api/user/request-document',
    method: 'post',
    params,
  });

AppService.uploadUserFile = (file, fileDetails, requestedId) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('userDocument', JSON.stringify(fileDetails));
  if (requestedId) formData.append('userDocumentId', requestedId);

  return fetch({
    url: '/api/user/document/upload',
    method: 'put',
    data: formData,
  });
};

AppService.deleteUserFile = params =>
  fetch({
    url: '/api/user/document/delete',
    method: 'delete',
    params,
  });

AppService.insertDocumentComment = data =>
  fetch({
    url: '/api/user/insert/document-comment',
    method: 'post',
    data,
  });

AppService.getDocumentComments = params =>
  fetch({
    url: '/api/user/document-comments',
    method: 'get',
    params,
  });

AppService.updateDocumentStatus = params =>
  fetch({
    url: '/api/user/update/document',
    method: 'put',
    params,
  });

export default AppService;
