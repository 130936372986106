import React, { Children } from 'react';
import AppLocale from 'lang';
import { connect } from 'react-redux';
import { ConfigProvider } from 'antd';

const AntWrapper = props => {
  const { locale, children } = props;
  const currentAppLocale = AppLocale[locale];

  return <ConfigProvider locale={currentAppLocale.antd}>{Children.only(children)}</ConfigProvider>;
};

const mapStateToProps = ({ theme }) => {
  const { locale } = theme;
  return { locale };
};

export default connect(mapStateToProps)(AntWrapper);
