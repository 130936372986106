export const STATUS_LIST = [
  'registration_failed',
  'registration_terminated',
  'registration_pending',
  'terminate_registration',
  'sign_contract',
  'company_balance',
  'reupload_balance',
  'company_category',
  'company_registry',
  'upload_durc',
  'reupload_durc',
  'technician_competence',
  'technician_figure',
  'technician_personal_registry',
  'technician_professional_registry',
  'technician_register',
  'technician_course',
];

export const COMPANY_TOTAL_STEPS = 4;
export const TECHNICIAN_TOTAL_STEPS = 6;
export const CUSTOMER_TOTAL_STEPS = 1;
export const CONDO_TOTAL_STEPS = 1;

export const REGISTRATION_STEPS = {
  company_registry: 1,
  company_category: 2,
  company_balance: 3,
  reupload_balance: 3,
  upload_durc: 4,
  reupload_durc: 4,
  technician_personal_registry: 1,
  technician_professional_registry: 2,
  technician_register: 3,
  technician_course: 4,
  technician_competence: 5,
  technician_figure: 6,
  sign_contract: 'complete',
  registration_pending: 1,
};

export const INSURANCE_POLICY_VALID = 'VALID';
export const INSURANCE_POLICY_NOT_VALID = 'NOT_VALID';
export const INSURANCE_POLICY_IN_PENDING = 'IN_PENDING';
export const INSURANCE_POLICY_EXPIRING = 'EXPIRING';
export const INSURANCE_POLICY_EXPIRED = 'EXPIRED';
