import { all, takeEvery, put, fork, delay, select } from 'redux-saga/effects';
import AppService from 'services/AppService';
import { STATUS_LIST } from 'constants/RegistrationConstant';
import { GET_STATUS } from '../constants/Status';
import { setStatus, getStatus, setError } from '../actions/Status';
import { signOut, hideLoading } from '../actions/Auth';

let count = 0;

export const getAuth = state => state.auth;

export function* getStatusSaga() {
  yield takeEvery(GET_STATUS, function* () {
    try {
      const res = yield AppService.getStatus();
      const status = res.object.currentTask;

      yield put(setStatus(STATUS_LIST.includes(status) ? status : 'waiting'));

      if (!STATUS_LIST.includes(status)) {
        if (count < 12) {
          count++;
          yield delay(5000);
          const { role, token } = yield select(getAuth);
          const authenticated = role && token;
          if (authenticated) {
            yield put(getStatus());
          }
        }
      } else {
        count = 0;
      }

      yield put(hideLoading());
    } catch (err) {
      yield put(hideLoading());
      yield put(signOut());

      if (err.view) {
        yield put(setError(err.code));
      }
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getStatusSaga)]);
}
