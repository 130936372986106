import { ESECUZIONE_LAVORI, INSERIMENTO_IMPORTI, SELEZIONE_FIGURE } from 'constants/CamundaConstant';
import { STATUS_TODO, STATUS_WAITING, TEC_PROGETTISTA } from 'constants/ProjectsConstant';
import { GENERIC_TASKS, TECHNICIAN_TASKS } from './CommonTasks';

export const TEC_PROGETTISTA_STEPS = [
  {
    code: TEC_PROGETTISTA,
    icon: null,
    order: 1,
  },
];

export const TEC_PROGETTISTA_TASKS = [
  ...TECHNICIAN_TASKS,
  ...GENERIC_TASKS,
  {
    code: SELEZIONE_FIGURE,
    status: STATUS_TODO,
    component: SELEZIONE_FIGURE,
    message: SELEZIONE_FIGURE,
  },
  {
    code: INSERIMENTO_IMPORTI,
    status: STATUS_TODO,
    component: INSERIMENTO_IMPORTI,
    message: INSERIMENTO_IMPORTI,
  },
  {
    code: ESECUZIONE_LAVORI,
    status: STATUS_WAITING,
    component: ESECUZIONE_LAVORI,
    message: ESECUZIONE_LAVORI,
  },
];
