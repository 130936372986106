import { SIGNOUT_SUCCESS } from 'redux/constants/Auth';
import {
  SET_ADMIN_DETAILS,
  SET_USERS,
  SET_TASKLIST_REGISTRATION,
  SET_TASKLIST_PROJECTS,
  SET_COMPANIES,
  SET_CUSTOMERS,
  SET_CONDOMINIUMS,
  SET_TECHNICIANS,
  SET_APPOINTMENTS,
  SET_SEEN_TASKLIST,
  SET_TRUSTED_SUPPLIERS,
} from '../constants/Admin';

const initState = {
  appointments: [],
  adminDetails: {},
  users: [],
  companies: [],
  technicians: [],
  customers: [],
  condominiums: [],
  tasklistRegistration: [],
  tasklistProjects: [],
  seenTasklist: false,
  trustedSuppliers: [],
};

const admin = (state = initState, action) => {
  switch (action.type) {
    case SET_ADMIN_DETAILS:
      return {
        ...state,
        adminDetails: action.payload,
      };
    case SET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case SET_COMPANIES:
      return {
        ...state,
        companies: action.payload,
      };
    case SET_TECHNICIANS:
      return {
        ...state,
        technicians: action.payload,
      };
    case SET_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
      };
    case SET_CONDOMINIUMS:
      return {
        ...state,
        condominiums: action.payload,
      };
    case SET_TASKLIST_REGISTRATION:
      return {
        ...state,
        tasklistRegistration: action.payload,
      };
    case SET_TASKLIST_PROJECTS:
      return {
        ...state,
        tasklistProjects: action.payload,
      };
    case SET_APPOINTMENTS:
      return {
        ...state,
        appointments: action.payload,
      };
    case SET_SEEN_TASKLIST:
      return {
        ...state,
        seenTasklist: action.payload,
      };
    case SIGNOUT_SUCCESS:
      return {
        ...initState,
      };
    case SET_TRUSTED_SUPPLIERS:
      return {
        ...state,
        trustedSuppliers: action.payload,
      };
    default:
      return state;
  }
};

export default admin;
