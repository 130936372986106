import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { message } from 'antd';
import CondominiumService from 'services/CondominiumService';
import { getProjects } from 'redux/actions/Projects';
import { showLoading, hideLoading } from '../actions/Auth';
import { setError } from '../actions/Status';
import {
  INSERT_PROJECT,
  GET_INVITATIONS,
  GET_INVITATIONS_BY_ID,
  ACCEPT_INVITATION,
  UPDATE_INVITATION,
  DELETE_INVITATION,
  RESEND_INVITATION,
} from '../constants/Condominium';
import { setInvitations, getInvitations, getInvitationsById } from '../actions/Condominium';

export function* insertProjectSaga() {
  yield takeEvery(INSERT_PROJECT, function* (action) {
    const { project, intl } = action.payload;

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.sending' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      yield CondominiumService.startProject(project);
      message.success({
        content: `${intl.formatMessage({
          id: 'messages.done',
        })}`,
        key,
      });
      yield put(getProjects());
      yield put(hideLoading());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* getCondominiumInvitationsSaga() {
  yield takeEvery(GET_INVITATIONS, function* () {
    try {
      const { objectList } = yield CondominiumService.getInvitations();
      yield put(setInvitations(objectList));
    } catch (err) {
      if (err.view) {
        yield put(setError(err.code));
      }
    }
  });
}
export function* getCondominiumInvitationsByIdSaga() {
  yield takeEvery(GET_INVITATIONS_BY_ID, function* (action) {
    const { id } = action.payload;

    try {
      const { objectList } = yield CondominiumService.getInvitationsById({
        projectFatherId: id,
      });
      yield put(setInvitations(objectList));
    } catch (err) {
      if (err.view) {
        yield put(setError(err.code));
      }
    }
  });
}

export function* acceptInvitationSaga() {
  yield takeEvery(ACCEPT_INVITATION, function* (action) {
    const { data, intl } = action.payload;

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.sending' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      yield CondominiumService.acceptInvitation(data);

      message.success({
        content: `${intl.formatMessage({
          id: 'messages.done',
        })}`,
        key,
      });
      yield put(getInvitations());
      yield put(getProjects());
      yield put(hideLoading());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* updateInvitationSaga() {
  yield takeEvery(UPDATE_INVITATION, function* (action) {
    const { data, intl, updateById } = action.payload;

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.sending' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());
    try {
      yield CondominiumService.updateInvitation(data);

      message.success({
        content: `${intl.formatMessage({
          id: 'messages.done',
        })}`,
        key,
      });
      yield put(getProjects());
      if (updateById) yield put(getInvitationsById(data.projectFatherId));
      else yield put(getInvitations());

      yield put(hideLoading());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* deleteInvitationSaga() {
  yield takeEvery(DELETE_INVITATION, function* (action) {
    const { invitation, intl } = action.payload;

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.sending' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      yield CondominiumService.deleteInvitation({
        condominiumInvitationId: invitation.id,
      });

      message.success({
        content: `${intl.formatMessage({
          id: 'messages.done',
        })}`,
        key,
      });

      yield put(getInvitationsById(invitation.projectFatherId));
      yield put(hideLoading());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* resendInvitationSaga() {
  yield takeEvery(RESEND_INVITATION, function* (action) {
    const { data, intl } = action.payload;

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.sending' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      yield CondominiumService.resendInvitation({ invitationId: data.id });

      message.success({
        content: `${intl.formatMessage({
          id: 'messages.done',
        })}`,
        key,
      });
      yield put(getInvitationsById(data.projectFatherId));
      yield put(getProjects());
      yield put(hideLoading());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(insertProjectSaga),
    fork(getCondominiumInvitationsSaga),
    fork(getCondominiumInvitationsByIdSaga),
    fork(acceptInvitationSaga),
    fork(updateInvitationSaga),
    fork(deleteInvitationSaga),
    fork(resendInvitationSaga),
  ]);
}
