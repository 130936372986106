export const SET_SELECTED_DATE = 'SET_SELECTED_DATE';
export const SET_SELECTED_APPOINTMENT = 'SET_SELECTED_APPOINTMENT';

export const SET_APPOINTMENTS = 'SET_APPOINTMENTS';
export const SET_WEEKLY_APPOINTMENTS = 'SET_WEEKLY_APPOINTMENTS';

export const SET_PRESENT_MONTHS = 'SET_PRESENT_MONTHS';
export const SET_FIRST_MONTH = 'SET_FIRST_MONTH';

export const GET_APPOINTMENTS = 'GET_APPOINTMENTS';
export const CLEANUP_APPOINTMENTS = 'CLEANUP_APPOINTMENTS';

export const SET_SEEN_REQUESTS = 'SET_SEEN_REQUESTS';
