import { ALL_PROJECT_DOCUMENTS_CONFIG, EXCLUDED_PROJECT_DOCUMENT_CONFIG } from 'configs/ProjectDocumentsConfig';
import { ADMIN, COMPANY, CUSTOMER, TECHNICIAN, CONDO, IMAGES_EXTENSIONS } from 'constants/AppConstant';
import { PROJECT_CLOSED, STATUS_PROCESSING } from 'constants/ProjectsConstant';
import { SCROLL_OFFSET } from 'constants/ThemeConstant';
import { PROJECT_TASKLIST } from 'flows';
import _ from 'lodash';

class Utils {
  /**
   * Returns either ascending or descending value
   * @param {Object} a - antd Table sorter param a
   * @param {Object} b - antd Table sorter param b
   * @param {String} key - object key for compare
   * @return {any} a value minus b value
   */
  static antdTableSorter(a, b, key) {
    if (typeof a[key] === 'number' && typeof b[key] === 'number') {
      return a[key] - b[key];
    }

    if (typeof a[key] === 'string' && typeof b[key] === 'string') {
      a = a[key].toLowerCase();
      b = b[key].toLowerCase();
      return a > b ? -1 : b > a ? 1 : 0;
    }
  }

  /**
   * Filter array of object
   * @param {Array} list - array of objects that need to filter
   * @param {String} key - object key target
   * @param {any} value  - value that excluded from filter
   * @return {Array} a value minus b value
   */
  static filterArray(list, key, value) {
    let data = list;
    if (list) {
      data = list.filter(item => item[key] === value);
    }
    return data;
  }

  /**
   * Get Breakpoint
   * @param {Object} screens - Grid.useBreakpoint() from antd
   * @return {Array} array of breakpoint size
   */
  static getBreakPoint(screens) {
    const breakpoints = [];
    for (const key in screens) {
      if (screens.hasOwnProperty(key)) {
        const element = screens[key];
        if (element) {
          breakpoints.push(key);
        }
      }
    }
    return breakpoints;
  }

  /**
   * Scroll to the first form field error
   * @param {FormInstance<any>} form - form instance
   * @return {void}
   */
  static scrollToFirstError(form) {
    const fields = form.getFieldsError().filter(field => !_.isEmpty(field.errors));

    if (fields.length > 0) {
      const firstField = fields[0].name[0];
      form.scrollToField(firstField, {
        block: 'center',
      });
    }
  }

  static convertB64toByteArray(b64Data) {
    const byteCharacters = atob(b64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return byteArray;
  }

  static downloadFile(byteArray, nameFile) {
    const url = window.URL.createObjectURL(new Blob([byteArray]));
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', nameFile);

    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }

  static renameFile(file, newName) {
    if (!file || _.isEmpty(file)) return null;

    const extension = `.${file.name.split('.').slice(-1).pop()}`;
    const fileName = file.name.replace(extension, '');
    const formattedName = _.chain(newName || fileName)
      .camelCase()
      .upperFirst()
      .value();

    return new File([file], `${formattedName}${extension}`, {
      type: file.type,
      lastModified: file.lastModified,
    });
  }

  static getAvatar(role) {
    let avatarUrl;
    switch (role?.toLowerCase()) {
      case COMPANY:
        avatarUrl = '/img/avatars/company.png';
        break;
      case TECHNICIAN:
        avatarUrl = '/img/avatars/technician.png';
        break;
      case CUSTOMER:
        avatarUrl = '/img/avatars/customer.png';
        break;
      case CONDO:
        avatarUrl = '/img/avatars/condo.png';
        break;
      case ADMIN:
        avatarUrl = '/img/avatars/admin.png';
        break;
      default:
        avatarUrl = '/img/avatars/admin.png';
        break;
    }
    return avatarUrl;
  }

  static getFileExtension(fileName) {
    let fileExt = fileName.split('.');
    fileExt = `.${fileExt[fileExt.length - 1].toLowerCase()}`;
    return fileExt;
  }

  static isImage(fileName) {
    return IMAGES_EXTENSIONS.includes(this.getFileExtension(fileName));
  }

  static checkFileExtension(fileName, extensions) {
    return extensions.includes(this.getFileExtension(fileName));
  }

  static convertFromTime(time) {
    const hour = Math.floor(time);
    const minute = time % 1 ? 30 : 0;
    return [hour, minute];
  }

  static convertToTime(hour, minute) {
    const time = hour + (minute ? 0.5 : 0);
    return time;
  }

  static scrollIntoElement(element) {
    element.scrollIntoView();

    const scrolledY = window.scrollY;

    if (scrolledY) {
      window.scroll(0, scrolledY - SCROLL_OFFSET);
    }
  }

  static createBaseFileDetails(file, type, countersigned) {
    const documentGcpPath = `${countersigned ? 'Controfirmato' : ''}${_.chain(type).camelCase().upperFirst().value()}_${
      file.uid
    }${Utils.getFileExtension(file.name)}`;

    return {
      documentName: file.name,
      documentGcpPath,
      uploadDate: new Date(),
    };
  }

  static createUserFileDetails(file, type, userId, countersigned, documentStatus) {
    return {
      ...this.createBaseFileDetails(file, type, countersigned),
      userId,
      userDocumentType: type,
      userDocumentStatus: documentStatus,
      countersigned,
    };
  }

  static createProjectFileDetails(file, type, projectId, countersigned, documentStatus) {
    return {
      ...this.createBaseFileDetails(file, type, countersigned),
      projectId,
      projectDocumentType: type,
      projectDocumentStatus: documentStatus,
      countersigned,
    };
  }

  static createAcceptExtensionArray(list) {
    return list
      .filter(format => !['.heic', '.heif', '.jpg'].includes(format))
      .map(format => format.slice(1).toUpperCase())
      .join('/');
  }

  static validateFileUpload(file, list, accept, maxSize, multiple) {
    if (!multiple && list.length) return 'messages.already_uploaded';
    if (!accept.includes(Utils.getFileExtension(file.name))) return 'messages.incorrect_format';
    if (file.size > maxSize) return 'drag.max_size_exceeded';
    return false;
  }

  static getProjectDocumentConfigList = (projectType, assignment) => {
    return ALL_PROJECT_DOCUMENTS_CONFIG.filter(
      c => c.projectType.includes(projectType) && c.allowlistView.includes(assignment) && !c.blocklistView.includes(assignment),
    );
  };

  static getProjectDocumentConfigListFiltered = (projectType, assignment, excluded) => {
    return ALL_PROJECT_DOCUMENTS_CONFIG.filter(
      c =>
        c.projectType.includes(projectType) &&
        c.allowlistView.includes(assignment) &&
        !c.blocklistView.includes(assignment) &&
        !excluded.includes(c.type),
    );
  };

  static getTaskConfiguration(projectData, assignment) {
    const { eventTasks, projectTasks, projectDetail } = projectData;
    const { projectStatus } = projectDetail;

    const taskAssignee = projectTasks.find(task => task.assignee === assignment);

    const formattedEvents = eventTasks?.map(event => ({
      creationDate: event.createdDate,
      taskDefinitionKey: event.activityId,
      taskId: event.id,
      variables: event.variables,
    }));

    const tasklistConfig = PROJECT_TASKLIST[assignment];
    const currentEvent = formattedEvents?.find(event => {
      const codes = tasklistConfig.reduce((acc, cur) => acc.concat(cur.code), []);
      return codes.includes(event.taskDefinitionKey);
    });

    const currentTask = taskAssignee || currentEvent;

    let taskCode = currentTask?.taskDefinitionKey || STATUS_PROCESSING;
    if (PROJECT_CLOSED.includes(projectStatus)) taskCode = projectStatus;

    const taskConfig = tasklistConfig.find(task => task.code === taskCode);

    return [currentTask, taskConfig];
  }
}

export default Utils;
