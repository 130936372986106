import { all, takeEvery, put, fork } from 'redux-saga/effects';
import _ from 'lodash';
import { message } from 'antd';
import moment from 'moment';
import CustomerService from 'services/CustomerService';
import { GET_REGISTRY, SUBMIT_REGISTRY, UPDATE_REGISTRY } from '../constants/Customer';
import { showLoading, hideLoading } from '../actions/Auth';
import { setError, getStatus } from '../actions/Status';
import { getRegistry, setRegistry } from '../actions/Customer';

export function* getRegistrySaga() {
  yield takeEvery(GET_REGISTRY, function* (action) {
    const { params } = action.payload;

    try {
      const res = yield CustomerService.getRegistry(params);
      const data = _.omit(res.object, ['id', 'userId']);

      const formattedData = {
        ...data,
        birthDate: moment(data.birthDate),
      };

      yield put(setRegistry(formattedData));
    } catch (err) {
      if (err.view) {
        yield put(setError(err.code));
      }
    }
  });
}

export function* submitRegistry() {
  yield takeEvery(SUBMIT_REGISTRY, function* (action) {
    const { registry, intl } = action.payload;

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.sending' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      yield CustomerService.submitRegistry(registry);

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(getStatus());
      yield put(getRegistry());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* updateRegistry() {
  yield takeEvery(UPDATE_REGISTRY, function* (action) {
    const { registry, intl } = action.payload;

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.updating' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      yield CustomerService.updateRegistry(registry);

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(getRegistry());
      yield put(hideLoading());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getRegistrySaga), fork(updateRegistry), fork(submitRegistry)]);
}
