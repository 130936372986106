import {
  GET_REGISTRY,
  SET_REGISTRY,
  SET_MACRO,
  GET_CATEGORIES,
  SET_CATEGORIES,
  UPDATE_REGISTRY,
  SUBMIT_REGISTRY,
  SUBMIT_DURC,
  SUBMIT_CATEGORIES,
  GET_BALANCE_FIELDS,
  SUBMIT_BALANCE,
  SUBMIT_BALANCE_DETAIL,
  SET_BALANCE_FIELDS,
  UPDATE_CATEGORIES,
  UPDATE_SOCIAL,
  GET_COMPANY_ASSIGNMENTS,
} from '../constants/Company';

export const getAssignments = () => ({
  type: GET_COMPANY_ASSIGNMENTS,
});

export const getRegistry = params => ({
  type: GET_REGISTRY,
  payload: { params },
});

export const setRegistry = registry => ({
  type: SET_REGISTRY,
  payload: registry,
});

export const submitRegistry = (data, intl) => ({
  type: SUBMIT_REGISTRY,
  payload: { data, intl },
});

export const updateRegistry = (registry, intl) => ({
  type: UPDATE_REGISTRY,
  payload: { registry, intl },
});

export const updateSocial = (social, intl) => ({
  type: UPDATE_SOCIAL,
  payload: { social, intl },
});

export const submitDurc = intl => ({
  type: SUBMIT_DURC,
  payload: { intl },
});

export const getCategories = (params, notCompany) => ({
  type: GET_CATEGORIES,
  payload: { params, notCompany },
});

export const setMacro = macro => ({
  type: SET_MACRO,
  payload: macro,
});

export const setCategories = categories => ({
  type: SET_CATEGORIES,
  payload: categories,
});

export const submitCategories = (categories, intl) => ({
  type: SUBMIT_CATEGORIES,
  payload: { categories, intl },
});

export const updateCategories = (categories, intl) => ({
  type: UPDATE_CATEGORIES,
  payload: { categories, intl },
});

export const getBalanceFields = () => ({
  type: GET_BALANCE_FIELDS,
});

export const setBalanceFields = fields => ({
  type: SET_BALANCE_FIELDS,
  payload: fields,
});

export const submitBalance = (values, intl) => ({
  type: SUBMIT_BALANCE,
  payload: { values, intl },
});

export const submitBalanceDetail = (data, intl) => ({
  type: SUBMIT_BALANCE_DETAIL,
  payload: { data, intl },
});
