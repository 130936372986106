import { TEC_RILIEVO } from 'constants/ProjectsConstant';
import { GENERIC_TASKS, TECHNICIAN_TASKS } from './CommonTasks';

export const TEC_RILIEVO_STEPS = [
  {
    code: TEC_RILIEVO,
    icon: null,
    order: 1,
  },
];

export const TEC_RILIEVO_TASKS = [...TECHNICIAN_TASKS, ...GENERIC_TASKS];
