import { TEC_GEOLOGO } from 'constants/ProjectsConstant';
import { GENERIC_TASKS, TECHNICIAN_TASKS } from './CommonTasks';

export const TEC_GEOLOGO_STEPS = [
  {
    code: TEC_GEOLOGO,
    icon: null,
    order: 1,
  },
];

export const TEC_GEOLOGO_TASKS = [...TECHNICIAN_TASKS, ...GENERIC_TASKS];
