import {
  SET_ASSIGNMENTS,
  SET_PROJECTS,
  SET_SEEN_ASSIGNMENTS,
  SET_WORKING_OPERATIONS,
  SET_PAYMENTS,
  SET_BUILDING,
} from '../constants/Projects';
import { SIGNOUT_SUCCESS } from '../constants/Auth';

const initState = {
  seenAssignments: false,
  list: [],
  assignments: [],
  workingOperations: [],
  paymentsList: [],
  building: null,
};

const projectsStatus = (state = initState, action) => {
  switch (action.type) {
    case SET_PROJECTS:
      return {
        ...state,
        list: action.payload,
      };
    case SET_BUILDING:
      return {
        ...state,
        building: action.payload,
      };
    case SET_ASSIGNMENTS:
      return {
        ...state,
        assignments: action.payload,
      };
    case SET_WORKING_OPERATIONS:
      return {
        ...state,
        workingOperations: action.payload,
      };
    case SET_SEEN_ASSIGNMENTS:
      return {
        ...state,
        seenAssignments: action.payload,
      };
    case SET_PAYMENTS:
      return {
        ...state,
        paymentsList: action.payload,
      };
    case SIGNOUT_SUCCESS:
      return {
        ...initState,
      };
    default:
      return state;
  }
};

export default projectsStatus;
