import React from 'react';
import { Layout, Grid } from 'antd';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SideNav from 'components/layout-components/SideNav';
import MobileNav from 'components/layout-components/MobileNav';
import HeaderNav from 'components/layout-components/HeaderNav';
import AppViews from 'views/app-views';
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
} from 'constants/ThemeConstant';
import Utils from 'utils';
import ContentNav from 'components/layout-components/ContentNav';

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = ({ navCollapsed, location }) => {
  const screens = Utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes('lg');

  const getLayoutGutter = () => {
    if (isMobile) {
      return 0;
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  return (
    <Layout>
      {!isMobile ? <SideNav /> : null}
      <Layout style={{ paddingLeft: getLayoutGutter() }}>
        <HeaderNav location={location} isMobile={isMobile} />
        <div className="app-content">
          <Content>
            {isMobile && <ContentNav />}
            <div className="m-2 m-sm-3 m-md-4">
              <Switch>
                <Route path="" component={AppViews} />
              </Switch>
            </div>
          </Content>
        </div>
      </Layout>
      {isMobile && <MobileNav />}
    </Layout>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, locale } = theme;
  return { navCollapsed, locale };
};

export default withRouter(connect(mapStateToProps)(AppLayout));
