import moment from 'moment';
import { SIGNOUT_SUCCESS } from 'redux/constants/Auth';
import { SET_ERROR } from 'redux/constants/Status';
import {
  SET_SELECTED_DATE,
  SET_APPOINTMENTS,
  SET_WEEKLY_APPOINTMENTS,
  SET_SELECTED_APPOINTMENT,
  SET_PRESENT_MONTHS,
  SET_FIRST_MONTH,
  CLEANUP_APPOINTMENTS,
  SET_SEEN_REQUESTS,
} from '../constants/Appointments';

require('moment/locale/it');

const initState = {
  selectedDate: null,
  selectedAppointment: null,
  list: [],
  presentMonths: [],
  firstMonth: moment(),
  weeklyList: [],
  seenRequests: false,
};

const appointments = (state = initState, action) => {
  switch (action.type) {
    case SET_SEEN_REQUESTS:
      return {
        ...state,
        seenRequests: action.payload,
      };
    case SET_APPOINTMENTS:
      return {
        ...state,
        list: action.payload,
      };
    case SET_WEEKLY_APPOINTMENTS:
      return {
        ...state,
        weeklyList: action.payload,
      };
    case SET_SELECTED_DATE:
      return {
        ...state,
        selectedAppointment: null,
        selectedDate: action.payload,
      };
    case SET_SELECTED_APPOINTMENT:
      return {
        ...state,
        selectedAppointment: action.payload,
      };
    case SET_PRESENT_MONTHS:
      return {
        ...state,
        presentMonths: action.payload,
      };
    case SET_FIRST_MONTH:
      return {
        ...state,
        firstMonth: action.payload,
      };
    case SET_ERROR:
      return {
        ...initState,
      };
    case CLEANUP_APPOINTMENTS:
      return {
        ...initState,
      };
    case SIGNOUT_SUCCESS:
      return {
        ...initState,
      };
    default:
      return state;
  }
};

export default appointments;
