import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import { message } from 'antd';
import ProjectsService from 'services/ProjectsService';
import { getProjects, setBuilding, setPayments, setProjects, setWorkingOperations } from 'redux/actions/Projects';
import { CREATE_NEW_PROJECT } from 'redux/constants/Admin';
import AdminService from 'services/AdminService';
import {
  CLOSE_TASK,
  GET_PROJECTS,
  GET_WORKING_OPERATIONS,
  START_PROJECT,
  UPLOAD_PROJECT_FILE,
  GET_PAYMENTS,
  GET_BUILDING,
} from '../constants/Projects';
import { setError, setSelectedProject } from '../actions/Status';
import { showLoading, hideLoading } from '../actions/Auth';

export const getTheme = state => state.theme;
export const getStatus = state => state.appStatus;

export function* startProject() {
  yield takeEvery(START_PROJECT, function* (action) {
    const { project, intl } = action.payload;

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.sending' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      const { object } = yield ProjectsService.startProject(project);

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(getProjects());

      const selectedProject = {
        projectId: object,
        projectDetail: project,
        assignment: null,
      };

      yield put(setSelectedProject(selectedProject));
      yield put(hideLoading());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* getProjectsSaga() {
  yield takeEvery(GET_PROJECTS, function* () {
    try {
      const { objectList } = yield ProjectsService.listProjects();
      const projects = objectList.sort((a, b) => b.projectId - a.projectId);
      yield put(setProjects(projects));
    } catch (err) {
      if (err.view) {
        yield put(setError(err.code));
      }
    }
  });
}

export function* uploadFile() {
  yield takeEvery(UPLOAD_PROJECT_FILE, function* (action) {
    const { file, fileDetails, onSuccess, requestedId } = action.payload;
    const { intl } = yield select(getTheme);

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.uploading' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());
    try {
      yield ProjectsService.uploadFile(file, fileDetails, requestedId);

      message.success({
        content: `${file.name} ${intl.formatMessage({ id: 'messages.uploaded_successfully' })}`,
        key,
      });

      if (onSuccess) onSuccess();
      yield put(hideLoading());
    } catch (err) {
      yield put(hideLoading());
      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: 'messages.uploaded_unsuccessfully' }, { fileName: file.name })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* closeTask() {
  yield takeEvery(CLOSE_TASK, function* (action) {
    const { content, options } = action.payload;
    const { intl } = yield select(getTheme);

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: `messages.${options?.loadingMessage || 'sending'}` })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      yield ProjectsService.closeTask(content);

      message.success({
        content: `${intl.formatMessage({ id: `messages.${options?.successMessage || 'done'}` })}`,
        key,
      });

      yield put(options?.successAction ? options.successAction() : getProjects());
      yield put(hideLoading());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* getWorkingOperations() {
  yield takeEvery(GET_WORKING_OPERATIONS, function* () {
    try {
      const { objectList } = yield ProjectsService.getWorkingOperations();
      yield put(setWorkingOperations(objectList));
    } catch (err) {
      if (err.view) yield put(setError(err.code));
    }
  });
}

export function* getPaymentsSaga() {
  yield takeEvery(GET_PAYMENTS, function* (action) {
    const { userId, projectId } = action.payload;

    try {
      const { objectList } = yield ProjectsService.getPayments({ projectId, userId });
      yield put(setPayments(objectList));
    } catch (err) {
      if (err.view) yield put(setError(err.code));
    }
  });
}

export function* getBuilding() {
  yield takeEvery(GET_BUILDING, function* (action) {
    const { projectId } = action.payload;

    try {
      const { object } = yield AdminService.getBuilding({ projectId });
      yield put(setBuilding(object));
    } catch (err) {
      if (err.view) yield put(setError(err.code));
    }
  });
}

export function* createNewProject() {
  yield takeEvery(CREATE_NEW_PROJECT, function* (action) {
    const { project } = action.payload;
    const { intl } = yield select(getTheme);

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.sending' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      yield ProjectsService.createNewProject(project);

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(hideLoading());
      yield put(getProjects());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(startProject),
    fork(getProjectsSaga),
    fork(getWorkingOperations),
    fork(uploadFile),
    fork(closeTask),
    fork(getPaymentsSaga),
    fork(createNewProject),
    fork(getBuilding),
  ]);
}
