import {
  INSERT_PROJECT,
  GET_INVITATIONS,
  GET_INVITATIONS_BY_ID,
  SET_INVITATIONS,
  ACCEPT_INVITATION,
  UPDATE_INVITATION,
  DELETE_INVITATION,
  RESEND_INVITATION,
} from '../constants/Condominium';

export const insertProject = (project, intl) => ({
  type: INSERT_PROJECT,
  payload: { project, intl },
});

export const getInvitations = () => ({
  type: GET_INVITATIONS,
});

export const getInvitationsById = id => ({
  type: GET_INVITATIONS_BY_ID,
  payload: { id },
});

export const setInvitations = invitations => ({
  type: SET_INVITATIONS,
  payload: invitations,
});

export const acceptInvitation = (data, intl) => ({
  type: ACCEPT_INVITATION,
  payload: { data, intl },
});

export const updateInvitation = (data, intl, updateById) => ({
  type: UPDATE_INVITATION,
  payload: { data, intl, updateById },
});

export const deleteInvitation = (invitation, intl) => ({
  type: DELETE_INVITATION,
  payload: { invitation, intl },
});

export const resendInvitation = (data, intl) => ({
  type: RESEND_INVITATION,
  payload: { data, intl },
});
