export const AUTHENTICATED = 'AUTHENTICATED';
export const SIGNIN = 'SIGNIN';
export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SIGNUP = 'SIGNUP';

export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';
export const SET_HIDE_LOADING = 'SET_HIDE_LOADING';

export const AUTH_TOKEN = 'AUTH_TOKEN';
export const AUTH_ROLE = 'AUTH_ROLE';
export const AUTH_USERID = 'AUTH_USERID';
export const AUTH_MAIL = 'AUTH_MAIL';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
