export const APPOINTMENT_STATUS_NEW = 'NEW';
export const APPOINTMENT_STATUS_PENDING = 'PENDING';
export const APPOINTMENT_STATUS_REFUSED = 'REFUSED';
export const APPOINTMENT_STATUS_ACCEPTED = 'ACCEPTED';
export const APPOINTMENT_STATUS_ACCEPTED_TECHNICIAN = 'ACCEPTED_TECHNICIAN';
export const APPOINTMENT_STATUS_EMPTY = 'EMPTY';
export const APPOINTMENT_STATUS_TERMINATED = 'TERMINATED';

export const APPOINTMENT_STATUS_LIST = [
  APPOINTMENT_STATUS_NEW,
  APPOINTMENT_STATUS_PENDING,
  APPOINTMENT_STATUS_REFUSED,
  APPOINTMENT_STATUS_ACCEPTED,
  APPOINTMENT_STATUS_EMPTY,
  APPOINTMENT_STATUS_TERMINATED,
  APPOINTMENT_STATUS_ACCEPTED_TECHNICIAN,
];

export const APPOINTMENT_DAYS = 7;
export const APPOINTMENT_TIMES = 14;
export const APPOINTMENT_INITIAL_TIME = 7;
export const APPOINTMENT_LAST_TIME = 20;

export const APPOINTMENTS_MAX_MONTHS = 6;
