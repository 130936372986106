import {
  AUTHENTICATED,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SIGNUP,
  SHOW_LOADING,
  HIDE_LOADING,
  SIGNIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  SET_HIDE_LOADING,
} from '../constants/Auth';

export const authenticated = (token, role, userId, mail) => ({
  type: AUTHENTICATED,
  payload: { token, role, userId, mail },
});

export const signIn = (user, form, intl) => ({
  type: SIGNIN,
  payload: { user, form, intl },
});

export const signOut = () => ({
  type: SIGNOUT,
});

export const signOutSuccess = () => ({
  type: SIGNOUT_SUCCESS,
});

export const signUp = (user, form, intl, history) => ({
  type: SIGNUP,
  payload: { user, form, intl, history },
});

export const showLoading = () => ({
  type: SHOW_LOADING,
});

export const hideLoading = () => ({
  type: HIDE_LOADING,
});

export const setHideLoading = () => ({
  type: SET_HIDE_LOADING,
});

export const forgotPassword = (values, form, intl) => ({
  type: FORGOT_PASSWORD,
  payload: { values, form, intl },
});

export const resetPassword = (values, form, intl, history) => ({
  type: RESET_PASSWORD,
  payload: { values, form, intl, history },
});
