import { TEC_VVF } from 'constants/ProjectsConstant';
import { GENERIC_TASKS, TECHNICIAN_TASKS } from './CommonTasks';

export const TEC_VVF_STEPS = [
  {
    code: TEC_VVF,
    icon: null,
    order: 1,
    steps: 2,
  },
];

export const TEC_VVF_TASKS = [...TECHNICIAN_TASKS, ...GENERIC_TASKS];
