import AntWrapper from 'components/util-components/AntWrapper';
import GlobalDndContext from 'components/util-components/GlobalDndContext';
import IntlWrapper from 'components/util-components/IntlWrapper';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import store from './redux/store';
import Views from './views';

function App() {
  return (
    <div className="App">
      <GlobalDndContext>
        <Provider store={store}>
          <IntlWrapper>
            <AntWrapper>
              <Router>
                <Switch>
                  <Route path="/" component={Views} />
                </Switch>
              </Router>
            </AntWrapper>
          </IntlWrapper>
        </Provider>
      </GlobalDndContext>
    </div>
  );
}

export default App;
