import { all } from 'redux-saga/effects';
import Auth from './Auth';
import Status from './Status';
import Company from './Company';
import Technician from './Technician';
import App from './App';
import Admin from './Admin';
import Customer from './Customer';
import Projects from './Projects';
import Appointments from './Appointments';
import Condominium from './Condominium';

export default function* rootSaga() {
  yield all([
    Auth(),
    Status(),
    Company(),
    Technician(),
    App(),
    Admin(),
    Customer(),
    Projects(),
    Appointments(),
    Condominium(),
  ]);
}
