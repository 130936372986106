import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment';
import CustomerService from 'services/CustomerService';
import { APPOINTMENTS_MAX_MONTHS, APPOINTMENT_STATUS_TERMINATED } from 'constants/AppointmentsConstant';
import AdminService from 'services/AdminService';
import TechnicianService from 'services/TechnicianService';
import { ADMIN, CUSTOMER, TECHNICIAN } from 'constants/AppConstant';
import { GET_APPOINTMENTS } from '../constants/Appointments';
import { setError } from '../actions/Status';
import { setAppointments, setPresentMonths } from '../actions/Appointments';

export function* getAppointments() {
  yield takeEvery(GET_APPOINTMENTS, function* (action) {
    const { list, presentMonths } = yield select(state => state.appointments);
    const { role } = yield select(state => state.auth);
    const { date } = action.payload;
    const { year, month } = date;

    const actualMonth = moment([year, month - 1]);
    const alreadyExists = presentMonths.some(month => month.isSame(actualMonth, 'month'));

    try {
      if (!alreadyExists) {
        let service;
        switch (role) {
          case TECHNICIAN:
            service = TechnicianService;
            break;
          case CUSTOMER:
            service = CustomerService;
            break;
          case ADMIN:
            service = AdminService;
            break;
          default:
            service = null;
            break;
        }

        const { objectList } = yield service.getAppointments({ year, month });

        const nextList = objectList
          .filter(a => a.booking.bookingStatus !== APPOINTMENT_STATUS_TERMINATED)
          .map(a => ({
            ...a,
            booking: {
              ...a.booking,
              dateTime: moment(a.booking.date).set({
                hour: a.booking.hour,
                minute: a.booking.minute,
              }),
            },
          }))
          .sort((a, b) => a.booking.dateTime - b.booking.dateTime);

        const actualIsNewest = moment.max(presentMonths).isBefore(actualMonth);
        const nextAppointments = [...list];
        const nextPresentMonths = [...presentMonths];

        if (presentMonths.length === APPOINTMENTS_MAX_MONTHS) {
          let monthToRemove = null;

          if (actualIsNewest) {
            nextPresentMonths.shift();
            monthToRemove = moment(actualMonth).add(-APPOINTMENTS_MAX_MONTHS, 'M');
          } else {
            nextPresentMonths.pop();
            monthToRemove = moment(actualMonth).add(APPOINTMENTS_MAX_MONTHS, 'M');
          }

          _.remove(nextAppointments, a => moment(a.booking.date).isSame(monthToRemove, 'M'));
        }

        if (actualIsNewest) {
          nextPresentMonths.push(actualMonth);
          nextAppointments.push(...nextList);
        } else {
          nextPresentMonths.unshift(actualMonth);
          nextAppointments.unshift(...nextList);
        }

        yield put(setAppointments(nextAppointments));
        yield put(setPresentMonths(nextPresentMonths));
      }
    } catch (err) {
      if (err.view) {
        yield put(setError(err.code));
      }
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getAppointments)]);
}
