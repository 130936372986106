import {
  ProjectOutlined,
  BarsOutlined,
  ContactsOutlined,
  PartitionOutlined,
  CalendarOutlined,
  IdcardOutlined,
  EuroOutlined,
  FolderOpenOutlined,
  BarChartOutlined,
  AppstoreAddOutlined,
  TeamOutlined,
} from '@ant-design/icons';

const projectsNavTree = [
  {
    key: 'projects',
    path: '/app/projects',
    title: 'nav.projects',
    icon: ProjectOutlined,
    submenu: [
      {
        key: 'in-progress',
        path: '/app/projects/in-progress',
        title: 'nav.projects.in_progress',
      },
      {
        key: 'closed',
        path: '/app/projects/closed',
        title: 'nav.projects.closed',
      },
    ],
  },
];

const appointmentsNavTree = [
  {
    key: 'appointments',
    path: '/app/appointments',
    title: 'nav.appointments',
    icon: CalendarOutlined,
    hasBadge: true,
  },
];

const assignmentsNavTree = [
  {
    key: 'assignments',
    path: '/app/assignments',
    title: 'nav.assignments',
    icon: IdcardOutlined,
    hasBadge: true,
    submenu: [
      {
        key: 'requests',
        path: '/app/assignments/requests',
        title: 'nav.assignments.requests',
      },
      {
        key: 'historical',
        path: '/app/assignments/historical',
        title: 'nav.assignments.historical',
      },
    ],
  },
];

const documentationNavTree = [
  {
    key: 'documentation',
    path: '/app/documentation',
    title: 'nav.documentation',
    icon: FolderOpenOutlined,
  },
];

const paymentsNavTree = [
  {
    key: 'payments',
    path: '/app/payments',
    title: 'nav.payments',
    icon: EuroOutlined,
  },
];

const condominiNavTree = [
  {
    key: 'condomini',
    path: '/app/condomini',
    title: 'nav.condomini',
    icon: TeamOutlined,
  },
];

const technicianProfileNavTree = [
  {
    key: 'profile',
    path: '/app/profile',
    title: 'nav.profile',
    submenu: [
      {
        key: 'data',
        path: '/app/profile/data',
        title: 'nav.profile.data',
      },
      {
        key: 'evaluations',
        path: '/app/profile/evaluations',
        title: 'nav.profile.evaluations',
      },
      {
        key: 'documents',
        path: '/app/profile/documents',
        title: 'nav.profile.documents',
      },
    ],
  },
];

const companyProfileNavTree = [
  {
    key: 'profile',
    path: '/app/profile',
    title: 'nav.profile',
    submenu: [
      {
        key: 'data',
        path: '/app/profile/data',
        title: 'nav.profile.data',
      },
      {
        key: 'balance',
        path: '/app/profile/balance',
        title: 'nav.profile.balance',
      },
      {
        key: 'activities',
        path: '/app/profile/activities',
        title: 'nav.profile.activities',
      },
      {
        key: 'documents',
        path: '/app/profile/documents',
        title: 'nav.profile.documents',
      },
    ],
  },
];

const customerProfileNavTree = [
  {
    key: 'profile',
    path: '/app/profile',
    title: 'nav.profile',
    submenu: [
      {
        key: 'data',
        path: '/app/profile/data',
        title: 'nav.profile.data',
      },
    ],
  },
];

const condoProfileNavTree = [
  {
    key: 'profile',
    path: '/app/profile',
    title: 'nav.profile',
    submenu: [
      {
        key: 'data',
        path: '/app/profile/data',
        title: 'nav.profile.data',
      },
    ],
  },
];

const accountNavTree = [
  {
    key: 'account',
    path: '/app/account',
    title: 'nav.account',
  },
];

const adminNavTree = [
  {
    key: 'admin-dashboard',
    path: '/app/dashboard',
    title: 'nav.admin.dashboard',
    icon: BarChartOutlined,
  },
  {
    key: 'admin-projects',
    path: '/app/projects',
    title: 'nav.admin.projects',
    icon: AppstoreAddOutlined,
  },
  {
    key: 'admin-appointments',
    path: '/app/appointments',
    title: 'nav.appointments',
    icon: CalendarOutlined,
  },
  {
    key: 'admin-users',
    path: '/app/users',
    title: 'nav.admin.users',
    icon: ContactsOutlined,
    submenu: [
      {
        key: 'admin-users-all',
        path: '/app/users/all',
        title: 'nav.admin.users_all',
      },
      {
        key: 'admin-users-customers',
        path: '/app/users/customers',
        title: 'nav.admin.users_customers',
      },
      {
        key: 'admin-users-technicians',
        path: '/app/users/technicians',
        title: 'nav.admin.users_technicians',
      },
      {
        key: 'admin-users-companies',
        path: '/app/users/companies',
        title: 'nav.admin.users_companies',
      },
      {
        key: 'admin-users-condo',
        path: '/app/users/condominiums',
        title: 'nav.admin.users_condo',
      },
    ],
  },
  {
    key: 'admin-tasklist',
    path: '/app/tasklist',
    title: 'nav.admin.tasklist',
    icon: BarsOutlined,
    hasBadge: true,
    submenu: [
      {
        key: 'admin-tasklist-projects',
        path: '/app/tasklist/projects',
        title: 'nav.admin.tasklist_projects',
      },
      {
        key: 'admin-tasklist-registration',
        path: '/app/tasklist/registration',
        title: 'nav.admin.tasklist_registration',
      },
    ],
  },
  {
    key: 'admin-diagram',
    path: '/app/diagram',
    title: 'nav.admin.diagram',
    icon: PartitionOutlined,
  },
];

const navigationConfig = {
  administrator: [...adminNavTree],
  company: [...projectsNavTree, ...assignmentsNavTree, ...documentationNavTree, ...paymentsNavTree],
  technician: [...projectsNavTree, ...appointmentsNavTree, ...documentationNavTree, ...assignmentsNavTree],
  customer: [...projectsNavTree, ...appointmentsNavTree, ...documentationNavTree, ...paymentsNavTree],
  condo: [...projectsNavTree, ...appointmentsNavTree, ...condominiNavTree, ...documentationNavTree, ...paymentsNavTree],
  companyProfile: [...companyProfileNavTree],
  technicianProfile: [...technicianProfileNavTree],
  customerProfile: [...customerProfileNavTree],
  condoProfile: [...condoProfileNavTree],
  companyAccount: [...accountNavTree],
  technicianAccount: [...accountNavTree],
  customerAccount: [...accountNavTree],
  condoAccount: [...accountNavTree],
  administratorAccount: [...accountNavTree],
};

export default navigationConfig;
