import React from 'react';
import { useIntl } from 'react-intl';
import { Drawer, Grid } from 'antd';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { onMobileNavToggle } from 'redux/actions/Theme';
import Flex from 'components/shared-components/Flex';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Utils from 'utils';
import Logo from './Logo';
import MenuContent from './MenuContent';

const { useBreakpoint } = Grid;

export const MobileNav = props => {
  const { mobileNav, onMobileNavToggle } = props;
  const isMobile = !Utils.getBreakPoint(useBreakpoint()).includes('lg');
  const intl = useIntl();

  const onClose = () => {
    onMobileNavToggle(false);
  };

  return (
    <Drawer
      placement="left"
      closable={false}
      onClose={onClose}
      visible={mobileNav}
      bodyStyle={{
        padding: 0,
        backgroundColor: '#232192',
      }}
    >
      <Flex flexDirection="column" className="h-100">
        <Flex justifyContent="between" alignItems="center">
          <Logo mobileLogo />
          <div className="nav-close" onClick={() => onClose()}>
            <ArrowLeftOutlined style={{ color: '#F8F8F8' }} />
          </div>
        </Flex>
        <div className="mobile-nav-menu">
          <Scrollbars autoHide>
            <MenuContent {...props} />
          </Scrollbars>
        </div>
        {isMobile ? (
          <h2 className="rights-reserved-text mobile-rights-text-position">
            {intl.formatMessage(
              {
                id: 'nav.rights_reserved',
              },
              {
                b: str => <b>{str}</b>,
              },
            )}
          </h2>
        ) : null}
      </Flex>
    </Drawer>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, mobileNav } = theme;
  return { navCollapsed, mobileNav };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MobileNav);
