import {
  HomeOutlined,
  FileDoneOutlined,
  FileProtectOutlined,
  SearchOutlined,
  RadiusUprightOutlined,
  FolderOpenOutlined,
} from '@ant-design/icons';
import { ATTESA_VERIFICA_DOCUMENTI, CARICAMENTO_DOCUMENTI, INVITI_CONDOMINI } from 'constants/CamundaConstant';
import {
  MACRO_CONTRATTO,
  MACRO_DOCUMENTAZIONE,
  MACRO_LAVORI,
  MACRO_PRECONTRATTO,
  MACRO_PROGETTO,
  MACRO_RILIEVO,
  MACRO_SOPRALLUOGO,
  STATUS_TODO,
  STATUS_WAITING,
} from 'constants/ProjectsConstant';
import { GENERIC_TASKS, USER_TASKS } from './CommonTasks';

export const CONDO_STEPS = [
  {
    code: MACRO_DOCUMENTAZIONE,
    icon: FolderOpenOutlined,
    order: 1,
  },
  {
    code: MACRO_PRECONTRATTO,
    icon: FileDoneOutlined,
    order: 2,
  },
  {
    code: MACRO_SOPRALLUOGO,
    icon: SearchOutlined,
    order: 3,
  },
  {
    code: MACRO_RILIEVO,
    icon: RadiusUprightOutlined,
    order: 4,
  },
  {
    code: MACRO_PROGETTO,
    icon: HomeOutlined,
    order: 5,
  },
  {
    code: MACRO_CONTRATTO,
    icon: FileProtectOutlined,
    order: 6,
  },
  {
    code: MACRO_LAVORI,
    icon: HomeOutlined,
    order: 7,
  },
];

export const CONDO_TASKS = [
  {
    code: INVITI_CONDOMINI,
    status: STATUS_TODO,
    component: INVITI_CONDOMINI,
    message: INVITI_CONDOMINI,
  },
  {
    code: CARICAMENTO_DOCUMENTI,
    status: STATUS_TODO,
    component: CARICAMENTO_DOCUMENTI,
    message: CARICAMENTO_DOCUMENTI,
  },
  {
    code: ATTESA_VERIFICA_DOCUMENTI,
    status: STATUS_WAITING,
    component: ATTESA_VERIFICA_DOCUMENTI,
    message: ATTESA_VERIFICA_DOCUMENTI,
  },
  ...USER_TASKS,
  ...GENERIC_TASKS,
];
