import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Menu, Layout } from 'antd';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  DownCircleFilled,
} from '@ant-design/icons';
import { toggleCollapsedNav, onMobileNavToggle } from 'redux/actions/Theme';
import { setMenuKey } from 'redux/actions/Status';
import {
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from 'constants/ThemeConstant';
import navigationConfig from 'configs/NavigationConfig';
import IntlMessage from 'components/util-components/IntlMessage';
import { Link } from 'react-router-dom';
import NavProfile from './NavProfile';
import NavNotification from './NavNotification';
import Logo from './Logo';

const { Header } = Layout;

export const HeaderNav = props => {
  const {
    navCollapsed,
    mobileNav,
    toggleCollapsedNav,
    onMobileNavToggle,
    isMobile,
    location,
    role,
    setMenuKey,
  } = props;
  const [actualMenu, setActualMenu] = useState({});

  useEffect(() => {
    if (location) {
      const { pathname } = location;
      const isProfile = _.startsWith(pathname, '/app/profile');
      const isAccount = _.startsWith(pathname, '/app/account');
      const navMenu = `${role}${
        isProfile ? 'Profile' : isAccount ? 'Account' : ''
      }`;

      setActualMenu(
        navigationConfig[navMenu].find(menu =>
          _.startsWith(pathname, menu.path),
        ),
      );
    }
  }, [location]);

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };

  const getNavWidth = () => {
    if (isMobile) {
      return '0px';
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    }
    return `${SIDE_NAV_WIDTH}px`;
  };

  return (
    <Header className="app-header">
      <div className="app-header-wrapper pl-3">
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <div className="mx-sm-3" style={{ paddingTop: '2px' }}>
              {navCollapsed || isMobile ? (
                <MenuUnfoldOutlined onClick={onToggle} className="nav-icon" />
              ) : (
                <MenuFoldOutlined onClick={onToggle} className="nav-icon" />
              )}
            </div>
            {isMobile && <Logo {...props} />}
            {!isMobile && actualMenu?.path && (
              <Link to={actualMenu?.path}>
                <span
                  className="mx-4 font-weight-bold"
                  style={{ fontSize: '1.2rem', color: 'black' }}
                >
                  {actualMenu?.title && <IntlMessage id={actualMenu.title} />}
                </span>
              </Link>
            )}
            {!isMobile && actualMenu?.submenu && (
              <Menu
                expandIcon={<DownCircleFilled />}
                onSelect={() => setMenuKey(actualMenu.key)}
                mode="horizontal"
                className="ml-2"
              >
                {actualMenu.submenu.map((menu, i) => (
                  <Menu.Item className="mx-0 px-0" key={menu.key}>
                    <div
                      className={
                        (actualMenu.path === location.pathname && i === 0) ||
                        menu.path === location.pathname
                          ? 'active-ant-menu-item-border'
                          : 'not-active-ant-menu-item-border'
                      }
                    >
                      <span>
                        <IntlMessage id={menu.title} />
                      </span>
                    </div>
                    {menu.path ? <Link to={menu.path} /> : null}
                  </Menu.Item>
                ))}
              </Menu>
            )}
          </div>
          <div className="nav-right align-items-center">
            <NavNotification {...props} />
            <NavProfile {...props} />
          </div>
        </div>
      </div>
    </Header>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { navCollapsed, mobileNav } = theme;
  const { role } = auth;
  return { navCollapsed, mobileNav, role };
};

const mapDispatchToProps = {
  toggleCollapsedNav,
  onMobileNavToggle,
  setMenuKey,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNav);
