import { ATTESA_PAGAMENTO, VERIFICA_DOCUMENTI } from 'constants/CamundaConstant';
import { STATUS_TODO, STATUS_WAITING, TEC_VERIFICA_DOCUMENTI } from 'constants/ProjectsConstant';
import { GENERIC_TASKS, TECHNICIAN_TASKS } from './CommonTasks';

export const TEC_VERIFICA_DOCUMENTI_STEPS = [
  {
    code: TEC_VERIFICA_DOCUMENTI,
    icon: null,
    order: 1,
  },
];

export const TEC_VERIFICA_DOCUMENTI_TASKS = [
  ...TECHNICIAN_TASKS,
  ...GENERIC_TASKS,
  {
    code: VERIFICA_DOCUMENTI,
    timer: 30,
    status: STATUS_TODO,
    component: VERIFICA_DOCUMENTI,
    message: VERIFICA_DOCUMENTI,
  },
  {
    code: ATTESA_PAGAMENTO,
    status: STATUS_WAITING,
    component: ATTESA_PAGAMENTO,
    message: ATTESA_PAGAMENTO,
  },
];
