import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import _ from 'lodash';
import { setMenuKey } from 'redux/actions/Status';
import navigationConfig from 'configs/NavigationConfig';
import IntlMessage from 'components/util-components/IntlMessage';
import { Menu } from 'antd';

const ContentNav = props => {
  const { location, role } = props;
  const [actualMenu, setActualMenu] = useState({});

  useEffect(() => {
    if (location) {
      const { pathname } = location;
      const isProfile = _.startsWith(pathname, '/app/profile');
      const isAccount = _.startsWith(pathname, '/app/account');
      const navMenu = `${role}${isProfile ? 'Profile' : isAccount ? 'Account' : ''}`;

      setActualMenu(navigationConfig[navMenu].find(menu => _.startsWith(pathname, menu.path)));
    }
  }, [location, role]);

  return (
    <div className="m-2 m-sm-3">
      {actualMenu?.path && (
        <div className={actualMenu.path === '/app/account' ? 'pb-4' : ''}>
          <Link to={actualMenu?.path}>
            <span
              style={{
                fontFamily: 'Sora',
                fontWeight: '800',
                fontSize: '26px',
                color: 'black',
              }}
            >
              {actualMenu?.title && <IntlMessage id={actualMenu.title} />}
            </span>
          </Link>
        </div>
      )}
      {actualMenu?.submenu && (
        <Menu
          onSelect={() => setMenuKey(actualMenu.key)}
          mode="horizontal"
          className="pb-4 mb-2 mt-2 mt-sm-3 d-flex justify-content-between border-bottom-0 bg-transparent"
        >
          {actualMenu.submenu.map((menu, i) => (
            <Menu.Item style={{ width: `${100 / actualMenu.submenu.length - 7}%` }} className="mx-0" key={menu.key}>
              <div
                className={
                  (actualMenu.path === location.pathname && i === 0) || menu.path === location.pathname
                    ? 'active-ant-menu-item-border-wrapper'
                    : 'not-active-ant-menu-item-border-wrapper'
                }
              >
                <span>
                  <IntlMessage id={menu.title} />
                </span>
              </div>
              {menu.path ? <Link to={menu.path} /> : null}
            </Menu.Item>
          ))}
        </Menu>
      )}
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  const { role } = auth;
  return { role };
};
const mapDispatchToProps = {
  setMenuKey,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContentNav));
