import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import Company from './Company';
import Status from './Status';
import Technician from './Technician';
import Admin from './Admin';
import Customer from './Customer';
import Projects from './Projects';
import Appointments from './Appointments';
import Condominium from './Condominium';

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  company: Company,
  technician: Technician,
  customer: Customer,
  appStatus: Status,
  admin: Admin,
  projects: Projects,
  appointments: Appointments,
  condominium: Condominium,
});

export default reducers;
