import fetch from './interceptor';

const CondominiumService = {};

CondominiumService.startProject = data =>
  fetch({
    url: '/api/condominium/insert/project-condo',
    method: 'post',
    data,
  });

CondominiumService.getInvitations = () =>
  fetch({
    url: '/api/condominium/get/invitation-list',
    method: 'get',
  });

CondominiumService.getInvitationsById = params =>
  fetch({
    url: '/api/condominium/get/invitation-list-by-param',
    method: 'get',
    params,
  });

CondominiumService.acceptInvitation = data =>
  fetch({
    url: '/api/condominium/update/project-invitation-accept',
    method: 'put',
    data,
  });

CondominiumService.updateInvitation = data =>
  fetch({
    url: '/api/condominium/update/invitation',
    method: 'put',
    data,
  });

CondominiumService.deleteInvitation = params =>
  fetch({
    url: '/api/condominium/delete/invitation',
    method: 'delete',
    params,
  });

CondominiumService.resendInvitation = params =>
  fetch({
    url: '/api/condominium/update/send-project-invitation',
    method: 'put',
    params,
  });

export default CondominiumService;
