import fetch from './interceptor';

const CompanyService = {};

CompanyService.submitDurc = () =>
  fetch({
    url: '/api/company/insert/durc',
    method: 'post',
  });

CompanyService.getDurc = () =>
  fetch({
    url: '/api/company/get/durc',
    method: 'get',
  });

CompanyService.updateDurc = file => {
  const formData = new FormData();
  formData.append('file', file);

  return fetch({
    url: '/api/company/update/durc',
    method: 'put',
    data: formData,
  });
};

CompanyService.submitRegistry = data => {
  return fetch({
    url: '/api/company/insert/registry',
    method: 'post',
    data,
  });
};

CompanyService.getRegistry = params =>
  fetch({
    url: '/api/company/get/registry',
    method: 'get',
    params,
  });

CompanyService.updateRegistry = values => {
  const formData = new FormData();
  formData.append('company_details', JSON.stringify(values));

  return fetch({
    url: '/api/company/update/registry',
    method: 'put',
    data: formData,
  });
};

CompanyService.updateSocial = data =>
  fetch({
    url: '/api/company/update/social-links',
    method: 'put',
    data,
  });

CompanyService.getMacroCategories = () =>
  fetch({
    url: '/api/company/get/macrocategory',
    method: 'get',
  });

CompanyService.getCategories = () =>
  fetch({
    url: '/api/company/get/categories',
    method: 'get',
  });

CompanyService.submitCategories = data =>
  fetch({
    url: '/api/company/insert/category',
    method: 'post',
    data,
  });

CompanyService.getActualCategories = params =>
  fetch({
    url: '/api/company/get/category',
    method: 'get',
    params,
  });

CompanyService.updateCategories = data =>
  fetch({
    url: '/api/company/update/category',
    method: 'put',
    data,
  });

CompanyService.submitBalance = values => {
  const formData = new FormData();
  formData.append('balance_fields', JSON.stringify(values));

  return fetch({
    url: '/api/company/insert/balance-doc',
    method: 'post',
    data: formData,
  });
};

CompanyService.submitBalanceDetail = data =>
  fetch({
    url: '/api/company/insert/balance',
    method: 'post',
    data,
  });

CompanyService.getBalanceFormFields = () =>
  fetch({
    url: '/api/company/get/finance',
    method: 'get',
  });

CompanyService.getBalance = params =>
  fetch({
    url: '/api/company/get/company-finances',
    method: 'get',
    params,
  });

CompanyService.updateBalance = ({ data, params }) =>
  fetch({
    url: '/api/company/update/balance-doc',
    method: 'put',
    data,
    params,
  });

CompanyService.getAssignments = () =>
  fetch({
    url: '/api/project/get/company-assignments',
    method: 'get',
  });

CompanyService.getClosedAssignments = () =>
  fetch({
    url: '/api/project/get/company-assignments-archive',
    method: 'get',
  });

export default CompanyService;
