import { COMPANY, CONDO, CONDO_CHILD, CUSTOMER } from 'constants/AppConstant';
import {
  GENERAL_CONTRACTOR,
  TEC_ACUSTICO,
  TEC_APE,
  TEC_ASSEVERATORE,
  TEC_CALCOLATORE_CA,
  TEC_CERTIFICATORE_ENERGETICO,
  TEC_COLLAUDATORE_CA,
  TEC_CONDUZIONE_CANTIERE,
  TEC_DIREZIONE_LAVORI,
  TEC_GEOLOGO,
  TEC_PROGETTISTA,
  TEC_RILIEVO,
  TEC_SICUREZZA,
  TEC_SICUREZZA_ESECUZIONE,
  TEC_SOPRALLUOGO,
  TEC_TERMOTECNICO,
  TEC_TERMOTECNICO_ESECUZIONE,
  TEC_VERIFICA_DOCUMENTI,
  TEC_VVF,
} from 'constants/ProjectsConstant';
import { COMPANY_STEPS, COMPANY_TASKS } from './Company';
import { CONDO_STEPS, CONDO_TASKS } from './Condo';
import { CONDO_CHILD_STEPS, CONDO_CHILD_TASKS } from './CondoChild';
import { CUSTOMER_STEPS, CUSTOMER_TASKS } from './Customer';
import { GENERAL_CONTRACTOR_STEPS, GENERAL_CONTRACTOR_TASKS } from './GeneralContractor';
import { TEC_ACUSTICO_STEPS, TEC_ACUSTICO_TASKS } from './TechAcustico';
import { TEC_APE_STEPS, TEC_APE_TASKS } from './TechApe';
import { TEC_ASSEVERATORE_STEPS, TEC_ASSEVERATORE_TASKS } from './TechAsseveratore';
import { TEC_CALCOLATORE_CA_STEPS, TEC_CALCOLATORE_CA_TASKS } from './TechCalcolatoreCa';
import { TEC_CERTIFICATORE_ENERGETICO_STEPS, TEC_CERTIFICATORE_ENERGETICO_TASKS } from './TechCertificatoreEnergetico';
import { TEC_COLLAUDATORE_CA_STEPS, TEC_COLLAUDATORE_CA_TASKS } from './TechCollaudatoreCa';
import { TEC_CONDUZIONE_CANTIERE_STEPS, TEC_CONDUZIONE_CANTIERE_TASKS } from './TechConduzioneCantiere';
import { TEC_DIREZIONE_LAVORI_STEPS, TEC_DIREZIONE_LAVORI_TASKS } from './TechDirezioneLavori';
import { TEC_GEOLOGO_STEPS, TEC_GEOLOGO_TASKS } from './TechGeologo';
import { TEC_PROGETTISTA_STEPS, TEC_PROGETTISTA_TASKS } from './TechProgettista';
import { TEC_RILIEVO_STEPS, TEC_RILIEVO_TASKS } from './TechRilievo';
import { TEC_SICUREZZA_STEPS, TEC_SICUREZZA_TASKS } from './TechSicurezza';
import { TEC_SICUREZZA_ESECUZIONE_STEPS, TEC_SICUREZZA_ESECUZIONE_TASKS } from './TechSicurezzaEsecuzione';
import { TEC_SOPRALLUOGO_STEPS, TEC_SOPRALLUOGO_TASKS } from './TechSopralluogo';
import { TEC_TERMOTECNICO_STEPS, TEC_TERMOTECNICO_TASKS } from './TechTermotecnico';
import { TEC_TERMOTECNICO_ESECUZIONE_STEPS, TEC_TERMOTECNICO_ESECUZIONE_TASKS } from './TechTermotecnicoEsecuzione';
import { TEC_VERIFICA_DOCUMENTI_STEPS, TEC_VERIFICA_DOCUMENTI_TASKS } from './TechVerificaDocumenti';
import { TEC_VVF_STEPS, TEC_VVF_TASKS } from './TechVvf';

export const PROJECT_TASKLIST = {
  [CUSTOMER]: CUSTOMER_TASKS,
  [CONDO]: CONDO_TASKS,
  [CONDO_CHILD]: CONDO_CHILD_TASKS,
  [TEC_VERIFICA_DOCUMENTI]: TEC_VERIFICA_DOCUMENTI_TASKS,
  [TEC_SOPRALLUOGO]: TEC_SOPRALLUOGO_TASKS,
  [TEC_RILIEVO]: TEC_RILIEVO_TASKS,
  [TEC_APE]: TEC_APE_TASKS,
  [TEC_PROGETTISTA]: TEC_PROGETTISTA_TASKS,
  [TEC_TERMOTECNICO]: TEC_TERMOTECNICO_TASKS,
  [TEC_ASSEVERATORE]: TEC_ASSEVERATORE_TASKS,
  [TEC_DIREZIONE_LAVORI]: TEC_DIREZIONE_LAVORI_TASKS,
  [TEC_CONDUZIONE_CANTIERE]: TEC_CONDUZIONE_CANTIERE_TASKS,
  [TEC_SICUREZZA]: TEC_SICUREZZA_TASKS,
  [TEC_SICUREZZA_ESECUZIONE]: TEC_SICUREZZA_ESECUZIONE_TASKS,
  [TEC_CALCOLATORE_CA]: TEC_CALCOLATORE_CA_TASKS,
  [TEC_COLLAUDATORE_CA]: TEC_COLLAUDATORE_CA_TASKS,
  [TEC_TERMOTECNICO_ESECUZIONE]: TEC_TERMOTECNICO_ESECUZIONE_TASKS,
  [TEC_CERTIFICATORE_ENERGETICO]: TEC_CERTIFICATORE_ENERGETICO_TASKS,
  [TEC_ACUSTICO]: TEC_ACUSTICO_TASKS,
  [TEC_VVF]: TEC_VVF_TASKS,
  [TEC_GEOLOGO]: TEC_GEOLOGO_TASKS,
  [GENERAL_CONTRACTOR]: GENERAL_CONTRACTOR_TASKS,
  [COMPANY]: COMPANY_TASKS,
};

export const PROJECT_STEPS = {
  [CUSTOMER]: CUSTOMER_STEPS,
  [CONDO]: CONDO_STEPS,
  [CONDO_CHILD]: CONDO_CHILD_STEPS,
  [TEC_VERIFICA_DOCUMENTI]: TEC_VERIFICA_DOCUMENTI_STEPS,
  [TEC_SOPRALLUOGO]: TEC_SOPRALLUOGO_STEPS,
  [TEC_RILIEVO]: TEC_RILIEVO_STEPS,
  [TEC_APE]: TEC_APE_STEPS,
  [TEC_PROGETTISTA]: TEC_PROGETTISTA_STEPS,
  [TEC_TERMOTECNICO]: TEC_TERMOTECNICO_STEPS,
  [TEC_ASSEVERATORE]: TEC_ASSEVERATORE_STEPS,
  [TEC_DIREZIONE_LAVORI]: TEC_DIREZIONE_LAVORI_STEPS,
  [TEC_CONDUZIONE_CANTIERE]: TEC_CONDUZIONE_CANTIERE_STEPS,
  [TEC_SICUREZZA]: TEC_SICUREZZA_STEPS,
  [TEC_SICUREZZA_ESECUZIONE]: TEC_SICUREZZA_ESECUZIONE_STEPS,
  [TEC_CALCOLATORE_CA]: TEC_CALCOLATORE_CA_STEPS,
  [TEC_COLLAUDATORE_CA]: TEC_COLLAUDATORE_CA_STEPS,
  [TEC_TERMOTECNICO_ESECUZIONE]: TEC_TERMOTECNICO_ESECUZIONE_STEPS,
  [TEC_CERTIFICATORE_ENERGETICO]: TEC_CERTIFICATORE_ENERGETICO_STEPS,
  [TEC_ACUSTICO]: TEC_ACUSTICO_STEPS,
  [TEC_VVF]: TEC_VVF_STEPS,
  [TEC_GEOLOGO]: TEC_GEOLOGO_STEPS,
  [GENERAL_CONTRACTOR]: GENERAL_CONTRACTOR_STEPS,
  [COMPANY]: COMPANY_STEPS,
};
