import { SET_REGISTRY, SET_MACRO, SET_CATEGORIES, SET_BALANCE_FIELDS } from '../constants/Company';
import { SIGNOUT_SUCCESS } from '../constants/Auth';

const initState = {
  registry: {},
  macro: [],
  categories: [],
  balanceFields: [],
};

const company = (state = initState, action) => {
  switch (action.type) {
    case SET_REGISTRY:
      return {
        ...state,
        registry: action.payload,
      };
    case SET_MACRO:
      return {
        ...state,
        macro: action.payload,
      };
    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case SET_BALANCE_FIELDS:
      return {
        ...state,
        balanceFields: action.payload,
      };
    case SIGNOUT_SUCCESS:
      return {
        ...initState,
      };
    default:
      return state;
  }
};

export default company;
