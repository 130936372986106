import {
  SET_PERSONAL_REGISTRY,
  SET_PROFESSIONAL_REGISTRY,
  SET_COMPETENCES,
  SET_FIGURES,
  SET_REGISTERS,
  SET_COURSES,
  SET_MACRO,
  SET_INSURANCE_POLICIES,
  SET_WORKS_AMOUNT,
  CLEAR_INSURANCE_POLICIES,
  CLEAR_WORKS_AMOUNT,
} from '../constants/Technician';
import { SIGNOUT_SUCCESS } from '../constants/Auth';

const initState = {
  personalRegistry: {},
  professionalRegistry: {},
  insurancePolicies: [],
  worksAmount: [],
  registers: [],
  courses: [],
  competences: [],
  macro: [],
  figures: [],
};

const technician = (state = initState, action) => {
  switch (action.type) {
    case SET_PERSONAL_REGISTRY:
      return {
        ...state,
        personalRegistry: action.payload,
      };
    case SET_PROFESSIONAL_REGISTRY:
      return {
        ...state,
        professionalRegistry: action.payload,
      };
    case SET_INSURANCE_POLICIES:
      return {
        ...state,
        insurancePolicies: action.payload,
      };
    case CLEAR_INSURANCE_POLICIES:
      return {
        ...state,
        insurancePolicies: [],
      };
    case SET_WORKS_AMOUNT:
      return {
        ...state,
        worksAmount: action.payload,
      };
    case CLEAR_WORKS_AMOUNT:
      return {
        ...state,
        worksAmount: [],
      };
    case SET_REGISTERS:
      return {
        ...state,
        registers: action.payload,
      };
    case SET_COURSES:
      return {
        ...state,
        courses: action.payload,
      };
    case SET_COMPETENCES:
      return {
        ...state,
        competences: action.payload,
      };
    case SET_FIGURES:
      return {
        ...state,
        figures: action.payload,
      };
    case SET_MACRO:
      return {
        ...state,
        macro: action.payload,
      };
    case SIGNOUT_SUCCESS:
      return {
        ...initState,
      };
    default:
      return state;
  }
};

export default technician;
