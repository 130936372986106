import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import _ from 'lodash';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AppLayout from 'layouts/app-layout';
import RegistrationLayout from 'layouts/registration-layout';
import AuthViews from 'views/auth-views';
import ErrorViews from 'views/error-views';
import { useIntl } from 'react-intl';
import { getStatus } from 'redux/actions/Status';
import { BASE_URLS, PUBLIC_URLS } from 'constants/AppConstant';
import { setIntl } from 'redux/actions';

const gaEnabled = process.env.REACT_APP_GA_ENABLED === 'true';
if (gaEnabled) ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

const facebookEnabled = process.env.REACT_APP_FACEBOOK_ENABLED === 'true';
if (facebookEnabled) ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID);

let firstRender = true;

export const Views = props => {
  const { token, role, location, error, getStatus, status, setIntl } = props;
  const intl = useIntl();

  const authenticated = token && role;
  const registrationTerminated = status === 'registration_terminated';
  const registration = !['registration_terminated', 'checking_status'].includes(status);

  const { pathname, key } = location;
  const validUrl = key && pathname;

  useEffect(() => {
    const isPublic = PUBLIC_URLS.some(url => _.startsWith(pathname, url));
    const firstPrivate = firstRender && !isPublic;

    if (validUrl && !BASE_URLS.includes(pathname) && !firstPrivate) {
      if (gaEnabled) ReactGA.pageview(pathname);
      if (facebookEnabled) ReactPixel.pageView();
    }

    firstRender = false;
  }, [location]);

  useEffect(() => {
    if (token && !error) {
      getStatus();
    }

    setIntl(intl);
  }, []);

  return (
    <Switch>
      {!authenticated && !error && <Route path="/app-auth" component={AuthViews} />}
      {authenticated && !error && registrationTerminated && (
        <Route path="/app">
          <AppLayout role={role} location={location} />
        </Route>
      )}
      {authenticated && !error && registration && (
        <Route path="/app-registration">
          <RegistrationLayout role={role} location={location} />
        </Route>
      )}
      {error && <Route path="/app-error" component={ErrorViews} />}
      {error && <Redirect from="/" to="/app-error" />}
      {authenticated && registrationTerminated && <Redirect from="/" to="/app" />}
      {authenticated && registration && <Redirect from="/" to="/app-registration" />}
      <Redirect from="/" to="/app-auth" />
    </Switch>
  );
};

const mapStateToProps = ({ auth, appStatus }) => {
  const { token, role } = auth;
  const { error, status } = appStatus;
  return { token, role, error, status };
};

export default withRouter(connect(mapStateToProps, { getStatus, setIntl })(Views));
