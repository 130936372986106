import {
  SET_STATUS,
  SET_ERROR,
  CLEAR_ERROR,
  SET_MENU_KEY,
  SET_SELECTED_USER,
  SET_SELECTED_PROJECT,
  GO_BACK,
  SET_LAST_SELECTED_PROJECT,
  SET_LAST_SELECTED_USER,
  SET_LAST_MENU_KEY,
  CLEAR_BACK_ACTIVE,
  SET_SELECTED_TASK,
  SET_LAST_SELECTED_TASK,
} from '../constants/Status';
import { SIGNOUT_SUCCESS } from '../constants/Auth';

const initState = {
  status: 'checking_status',
  error: null,
  menuKey: null,
  selectedUser: null,
  selectedProject: null,
  selectedTask: null,
  lastMenuKey: null,
  lastSelectedUser: null,
  lastSelectedProject: null,
  lastSelectedTask: null,
  backActive: false,
};

const appStatus = (state = initState, action) => {
  switch (action.type) {
    case SET_STATUS:
      return {
        ...state,
        status: action.payload,
        error: null,
      };
    case SIGNOUT_SUCCESS:
      return {
        ...initState,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        menuKey: null,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case SET_MENU_KEY:
      return {
        ...state,
        backActive: false,
        menuKey: action.payload,
      };
    case SET_SELECTED_USER:
      return {
        ...state,
        selectedUser: action.payload,
      };
    case SET_SELECTED_PROJECT:
      return {
        ...state,
        selectedProject: action.payload,
      };
    case SET_SELECTED_TASK:
      return {
        ...state,
        selectedTask: action.payload,
      };
    case SET_LAST_SELECTED_PROJECT:
      return {
        ...state,
        lastSelectedProject: action.payload,
      };
    case SET_LAST_SELECTED_USER:
      return {
        ...state,
        lastSelectedUser: action.payload,
      };
    case SET_LAST_SELECTED_TASK:
      return {
        ...state,
        lastSelectedTask: action.payload,
      };
    case SET_LAST_MENU_KEY:
      return {
        ...state,
        backActive: true,
        lastMenuKey: action.payload,
      };
    case GO_BACK:
      return {
        ...state,
        menuKey: state.lastMenuKey,
        selectedProject: state.lastSelectedProject,
        selectedUser: state.lastSelectedUser,
        selectedTask: state.lastSelectedTask,
        lastMenuKey: null,
        lastSelectedProject: null,
        lastSelectedUser: null,
        lastSelectedTask: null,
        backActive: false,
      };
    case CLEAR_BACK_ACTIVE:
      return {
        ...state,
        backActive: false,
        selectedProject: null,
        selectedUser: null,
        selectedTask: null,
      };
    default:
      return state;
  }
};

export default appStatus;
