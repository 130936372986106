import React from 'react';
import { Layout, Typography } from 'antd';
import IntlMessage from 'components/util-components/IntlMessage';
import { Link } from 'react-router-dom';
import NavProfile from './NavProfile';
import Logo from './Logo';

const { Header } = Layout;
const { Text } = Typography;

export const HeaderRegistration = props => {
  const { isMobile } = props;

  return (
    <Header className="app-header">
      <div className="app-header-wrapper">
        <div className="ml-sm-4">
          <Logo registration {...props} />
        </div>
        <div className="nav w-100">
          <div className="nav-left">
            {!isMobile && (
              <Link to="/app-registration">
                <Text
                  className={`${isMobile ? 'ml-3' : 'ml-5'}`}
                  style={{ color: 'black', fontSize: '1.2rem' }}
                  strong
                >
                  <IntlMessage id="nav.registration" />
                </Text>
              </Link>
            )}
          </div>
          <div className="nav-right align-items-center">
            <NavProfile registration {...props} />
          </div>
        </div>
      </div>
    </Header>
  );
};

export default HeaderRegistration;
