import axios from 'axios';
import _ from 'lodash';
import { API_BASE_URL } from 'configs/AppConfig';
import { AUTH_TOKEN } from 'redux/constants/Auth';
import { signOut } from 'redux/actions/Auth';
import store from 'redux/store';

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 180000,
});

// API Request interceptor
service.interceptors.request.use(
  config => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN);

    if (jwtToken) {
      config.headers.authorization = `Bearer ${jwtToken}`;
    }

    return config;
  },
  error => {
    Promise.reject(error);
  },
);

// API respone interceptor
service.interceptors.response.use(
  response => {
    if (response.data.result === 'error') {
      const { message } = response.data;

      if (message === 'status_registration_failed') {
        return Promise.reject({
          code: 'error_500',
          message,
          type: 'success',
          view: true,
          status: 200,
        });
      }

      if (message === 'supplier_not_exist') {
        return Promise.reject({
          code: 'error_500',
          message,
          type: 'success',
          view: true,
          status: 200,
        });
      }

      if (message === 'application_error') {
        return Promise.reject({
          code: 'error_500',
          message,
          type: 'success',
          view: true,
          status: 200,
        });
      }

      if (
        ['bad_twilio_::_stage_1st', 'fail_twilio_::_stage_1st', 'bad_twilio_::_stage_2nd', 'fail_twilio_::_stage_2nd'].includes(
          message,
        )
      ) {
        return response.data;
      }

      return Promise.reject({
        code: message,
        message,
        type: 'success',
        view: false,
        status: 200,
      });
    }

    return response.data;
  },
  error => {
    if (!error.response) {
      return Promise.reject({
        code: 'error_500',
        message: 'request_failed',
        type: 'error',
        view: true,
        status: null,
      });
    }

    if (!error.response.data && error.response.status === 401) {
      localStorage.removeItem(AUTH_TOKEN);
      store.dispatch(signOut());

      return Promise.reject({
        code: 'token',
        message: 'invalid_token',
        type: 'error',
        view: false,
        status: 401,
      });
    }

    const { status, message, path } = error.response.data;

    if (status === 401) {
      localStorage.removeItem(AUTH_TOKEN);
      store.dispatch(signOut());

      return Promise.reject({
        code: 'token',
        message,
        type: 'error',
        view: false,
        status: 401,
      });
    }

    if (status === 403) {
      localStorage.removeItem(AUTH_TOKEN);
      store.dispatch(signOut());

      if (path === '/auth/login') {
        return Promise.reject({
          code: _.snakeCase(message),
          message,
          type: 'error',
          view: false,
          status: 403,
        });
      }

      return Promise.reject({
        code: 'error_500',
        message,
        type: 'error',
        view: true,
        status: 403,
      });
    }

    if (status === 404) {
      return Promise.reject({
        code: 'error_404',
        message,
        type: 'error',
        view: true,
        status: 404,
      });
    }

    return Promise.reject({
      code: 'error_500',
      message: message || 'generic_error',
      type: 'error',
      view: true,
      status: status || 500,
    });
  },
);

export default service;
