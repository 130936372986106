import {
  GET_STATUS,
  SET_STATUS,
  SET_ERROR,
  CLEAR_ERROR,
  SET_MENU_KEY,
  SET_SELECTED_USER,
  SET_SELECTED_PROJECT,
  GO_BACK,
  SET_LAST_SELECTED_PROJECT,
  SET_LAST_SELECTED_USER,
  SET_LAST_MENU_KEY,
  CLEAR_BACK_ACTIVE,
  SET_SELECTED_TASK,
  SET_LAST_SELECTED_TASK,
} from '../constants/Status';

export const getStatus = () => ({
  type: GET_STATUS,
});

export const setStatus = status => ({
  type: SET_STATUS,
  payload: status,
});

export const setError = errorCode => ({
  type: SET_ERROR,
  payload: errorCode,
});

export const clearError = () => ({
  type: CLEAR_ERROR,
});

export const setMenuKey = menuKey => ({
  type: SET_MENU_KEY,
  payload: menuKey,
});

export const setSelectedUser = user => ({
  type: SET_SELECTED_USER,
  payload: user,
});

export const setSelectedProject = project => ({
  type: SET_SELECTED_PROJECT,
  payload: project,
});

export const goBack = () => ({
  type: GO_BACK,
});

export const setLastSelectedProject = project => ({
  type: SET_LAST_SELECTED_PROJECT,
  payload: project,
});

export const setLastSelectedUser = user => ({
  type: SET_LAST_SELECTED_USER,
  payload: user,
});

export const setLastMenuKey = menuKey => ({
  type: SET_LAST_MENU_KEY,
  payload: menuKey,
});

export const clearBackActive = () => ({
  type: CLEAR_BACK_ACTIVE,
});

export const setSelectedTask = task => ({
  type: SET_SELECTED_TASK,
  payload: task,
});

export const setLastSelectedTask = task => ({
  type: SET_LAST_SELECTED_TASK,
  payload: task,
});
