import { CONDO, CONDO_CHILD, CUSTOMER } from './AppConstant';

export const DELEGA_SCHEDE_URL = 'https://wwwt.agenziaentrate.gov.it/mt/Generica_nuova/12%20T%2011022014.pdf';

// DOCUMENTS UPLOAD TYPES
export const DOCUMENTS_UPLOAD_CILA = 'CILA';
export const DOCUMENTS_UPLOAD_DOCUMENTO_TERMOTECNICO = 'DOCUMENTO_TERMOTECNICO';
export const DOCUMENTS_UPLOAD_FATTIBILITA = 'FATTIBILITA';
export const DOCUMENTS_UPLOAD_RICARICAMENTO_FATTIBILITA = 'RICARICAMENTO_FATTIBILITA';
export const DOCUMENTS_UPLOAD_PREVENTIVO_SANATORIA = 'PREVENTIVO_SANATORIA';
export const DOCUMENTS_UPLOAD_PROGETTO_ESECUTIVO = 'PROGETTO_ESECUTIVO';
export const DOCUMENTS_UPLOAD_PROGETTO_PRELIMINARE = 'PROGETTO_PRELIMINARE';
export const DOCUMENTS_UPLOAD_CONCLUSIONE_SANATORIA = 'CONCLUSIONE_SANATORIA';
export const DOCUMENTS_UPLOAD_DEPOSITO_PROGETTO_ESECUTIVO = 'DEPOSITO_PROGETTO_ESECUTIVO';
export const DOCUMENTS_UPLOAD_DEPOSITO_SANATORIA = 'DEPOSITO_SANATORIA';
export const DOCUMENTS_UPLOAD_DICHIARAZIONE_SOSTITUTIVA = 'DICHIARAZIONE_SOSTITUTIVA';
export const DOCUMENTS_UPLOAD_PROCURA_SANATORIA = 'PROCURA_SANATORIA';
export const DOCUMENTS_UPLOAD_DOCUMENTI_INIZIALI = 'DOCUMENTI_INIZIALI';
export const DOCUMENTS_UPLOAD_RICARICAMENTO_DOCUMENTI = 'RICARICAMENTO_DOCUMENTI';
export const DOCUMENTS_UPLOAD_ACCESSO_ATTI = 'ACCESSO_ATTI';

// DOCUMENTS VERIFICATION TYPES
export const DOCUMENTS_VERIFICATION_DOCUMENTI_INIZIALI = 'DOCUMENTI_INIZIALI';

// DOCUMENTS ACCEPTANCE TYPES
export const DOCUMENTS_ACCEPTANCE_PREVENTIVO_MASSIMA = 'PREVENTIVO_MASSIMA';
export const DOCUMENTS_ACCEPTANCE_RICARICAMENTO_PREVENTIVO_MASSIMA = 'RICARICAMENTO_PREVENTIVO_MASSIMA';
export const DOCUMENTS_ACCEPTANCE_PREVENTIVO_SANATORIA = 'PREVENTIVO_SANATORIA';
export const DOCUMENTS_ACCEPTANCE_PROGETTO_ESECUTIVO = 'PROGETTO_ESECUTIVO';
export const DOCUMENTS_ACCEPTANCE_PROGETTO_PRELIMINARE = 'PROGETTO_PRELIMINARE';

// ASSIGNMENTS
export const ASSIGNMENT_ACCEPTED = 'ACCEPTED';
export const ASSIGNMENT_EXPIRED = 'EXPIRED';
export const ASSIGNMENT_REFUSED = 'REFUSED';
export const ASSIGNMENT_CLOSED = 'CLOSED';
export const ASSIGNMENT_TO_ASSIGN = 'TO_ASSIGN';
export const ASSIGNMENT_TO_SIGN = 'TO_SIGN';
export const ASSIGNMENT_IN_PENDING = 'IN_PENDING';

// PROJECT ASSIGNMENTS
export const TEC_VERIFICA_DOCUMENTI = 'verifica_documenti';
export const TEC_SANATORIA = 'sanatoria';
export const TEC_SOPRALLUOGO = 'sopralluogo';
export const TEC_APE = 'ape';
export const TEC_RILIEVO = 'rilievo';
export const TEC_TERMOTECNICO = 'termotecnico';
export const TEC_PROGETTISTA = 'progettista';

export const PROJECT_ASSIGNMENTS = [
  TEC_VERIFICA_DOCUMENTI,
  TEC_SANATORIA,
  TEC_SOPRALLUOGO,
  TEC_APE,
  TEC_RILIEVO,
  TEC_TERMOTECNICO,
  TEC_PROGETTISTA,
];

// WORK ASSIGNMENTS
export const TEC_ASSEVERATORE = 'asseveratore';
export const TEC_SICUREZZA = 'sicurezza';
export const TEC_SICUREZZA_ESECUZIONE = 'sicurezza_esecuzione';
export const TEC_CALCOLATORE_CA = 'calcolatore_ca';
export const TEC_TERMOTECNICO_ESECUZIONE = 'termotecnico_esecuzione';
export const TEC_COLLAUDATORE_CA = 'collaudatore_ca';
export const TEC_CERTIFICATORE_ENERGETICO = 'certificatore_energetico';
export const TEC_ACUSTICO = 'acustico';
export const TEC_VVF = 'vvf';
export const TEC_GEOLOGO = 'geologo';

export const FIGURE_SELECTION_ASSIGNMENTS = [
  TEC_ASSEVERATORE,
  TEC_SICUREZZA,
  TEC_SICUREZZA_ESECUZIONE,
  TEC_CALCOLATORE_CA,
  TEC_TERMOTECNICO_ESECUZIONE,
  TEC_COLLAUDATORE_CA,
  TEC_CERTIFICATORE_ENERGETICO,
  TEC_ACUSTICO,
  TEC_VVF,
  TEC_GEOLOGO,
];

export const TEC_DIREZIONE_LAVORI = 'direzione_lavori';
export const TEC_CONDUZIONE_CANTIERE = 'conduzione_cantiere';

export const MULTIPLE_ASSIGNMENTS = [TEC_DIREZIONE_LAVORI, TEC_CONDUZIONE_CANTIERE];

export const WORK_ASSIGNMENTS = [...FIGURE_SELECTION_ASSIGNMENTS, ...MULTIPLE_ASSIGNMENTS];
export const ALL_TECHNICIAN_ASSIGNMENTS = [...PROJECT_ASSIGNMENTS, ...WORK_ASSIGNMENTS];

export const OBLIGATORY_ASSIGNMENTS = [
  TEC_ASSEVERATORE,
  TEC_DIREZIONE_LAVORI,
  TEC_SICUREZZA,
  TEC_SICUREZZA_ESECUZIONE,
  TEC_CALCOLATORE_CA,
  TEC_CONDUZIONE_CANTIERE,
];

export const GENERAL_CONTRACTOR = 'general_contractor';

export const ASSIGNMENT_TIMER = 5;

export const STATUS_TODO = 'TODO';
export const STATUS_WAITING = 'WAITING';
export const STATUS_ERROR = 'ERROR';
export const STATUS_SUCCESS = 'SUCCESS';

export const STATUS_PROCESSING = 'processing';

export const SIGNATURE_STATUS_DONE = 'DONE';
export const SIGNATURE_STATUS_REFUSED = 'REFUSED';
export const SIGNATURE_STATUS_EXPIRED = 'EXPIRED';
export const SIGNATURE_STATUS_PROCEDURE_CLOSED = 'PROCEDURE_CLOSED';

export const MACRO_DOCUMENTAZIONE = 'DOCUMENTI';
export const MACRO_PRECONTRATTO = 'PRECONTRATTO';
export const MACRO_SOPRALLUOGO = 'SOPRALLUOGO';
export const MACRO_RILIEVO = 'RILIEVO';
export const MACRO_PROGETTO = 'PROGETTO';
export const MACRO_CONTRATTO = 'CONTRATTO';
export const MACRO_LAVORI = 'LAVORI';

export const PROJECT_STATUS_FAILED = 'FAILED';
export const PROJECT_STATUS_FINISHED = 'FINISHED';
export const PROJECT_STATUS_ARCHIVED = 'ARCHIVED';

export const PROJECT_PENDING = [
  MACRO_DOCUMENTAZIONE,
  MACRO_PRECONTRATTO,
  MACRO_SOPRALLUOGO,
  MACRO_RILIEVO,
  MACRO_PROGETTO,
  MACRO_CONTRATTO,
  MACRO_LAVORI,
];

export const PROJECT_CLOSED = [PROJECT_STATUS_FAILED, PROJECT_STATUS_FINISHED, PROJECT_STATUS_ARCHIVED];
export const PROJECT_STATUS_LIST = [...PROJECT_PENDING, ...PROJECT_CLOSED];

export const ALL_PROJECT_TYPES = [CUSTOMER, CONDO_CHILD, CONDO];

// PAYMENTS
export const PAYMENT_TYPE_CAPARRA = 'CAPARRA';
export const PAYMENT_TYPE_SANATORIA = 'SANATORIA';
export const PAYMENT_TYPE_PROGETTAZIONE = 'PROGETTAZIONE';
export const PAYMENT_TYPE_RIMBORSO = 'RIMBORSO';
export const PAYMENT_TYPE_ACCESSO_ATTI = 'ACCESSO_ATTI';

export const PAYMENT_STATUS_VERIFICATO = 'VERIFICATO';
export const PAYMENT_STATUS_ANNULLATO = 'ANNULLATO';
export const PAYMENT_STATUS_PENDENTE = 'PENDENTE';
export const PAYMENT_STATUS_PAGATO = 'PAGATO';

// REPORT COMPILATION TYPES
export const REPORT_COMPILATION_APE = 'APE';
export const REPORT_COMPILATION_RILIEVO = 'RILIEVO';
export const REPORT_COMPILATION_SOPRALLUOGO = 'SOPRALLUOGO';

export const PROGETTO_PRELIMINARE_STATUS_ACCETTATO = 'ACCETTATO';
export const PROGETTO_PRELIMINARE_STATUS_RIFIUTATO = 'RIFIUTATO';
export const PROGETTO_PRELIMINARE_STATUS_DA_MODIFICARE = 'DA_MODIFICARE';

export const NOTE_TYPE_MODIFICA_PROGETTO_PRELIMINARE = 'MODIFICA_PROGETTO_PRELIMINARE';
export const NOTE_TYPE_CARATTERISTICA_EDIFICIO = 'CARATTERISTICA_EDIFICIO';
export const NOTE_TYPE_AMMALORAMENTI_STRUTTURA = 'AMMALORAMENTI_STRUTTURA';
export const NOTE_TYPE_SOPRALLUOGO_RICHIESTE = 'SOPRALLUOGO_RICHIESTE';
export const NOTE_TYPE_MURATURA_ALTRO = 'MURATURA_ALTRO';
export const NOTE_TYPE_COMPILAZIONE_RILIEVO = 'COMPILAZIONE_RILIEVO';
export const NOTE_TYPE_APE_GENERICO = 'APE_GENERICO';
export const NOTE_TYPE_GENERATORE_CALORE = 'GENERATORE_CALORE';
export const NOTE_TYPE_TIPOLOGIA_IMPIANTO = 'TIPOLOGIA_IMPIANTO';

export const CONDO_ADMIN = 'ADMIN';
export const DELEGATED_CONDO = 'DELEGATED_CONDO';
export const CONDO_ROLE_TYPES = [CONDO_ADMIN, DELEGATED_CONDO];

export const PROPRIETARIO = 'PROPRIETARIO';
export const DETENTORE = 'DETENTORE';
export const POSSESSION_TYPES = [PROPRIETARIO, DETENTORE];

export const NEW_PROJECT_STATUS = [
  'SOPRALLUOGO',
  'RILIEVO_APE',
  'PREVENTIVO_MASSIMA',
  'PROGETTO_PRELIMINARE',
  'PROGETTO_ESECUTIVO',
];

export const ALLOW_CREATE_PROJECT = process.env.REACT_APP_ALLOW_CREATE_PROJECT === 'true';

export const MURATURA_TYPES = ['LATERIZIO', 'PIETRA', 'CEMENTO', 'CEMENTO_ARMATO', 'PREFABBRICATI', 'MISTO', 'ALTRO'];

export const PROJECT_CREATION_ADDRESS = 'address';
export const PROJECT_CREATION_STATUS = 'status';
export const PROJECT_CREATION_CONDO_DETAILS = 'condo_details';
export const PROJECT_CREATION_CONDO_CHILDREN = 'condo_children';

export const PROJECT_CREATION_DOCUMENTATION = 'documentation';
export const PROJECT_CREATION_NOTES = 'notes';
export const PROJECT_CREATION_PAYMENTS = 'payments';
export const PROJECT_CREATION_SOPRALLUOGO = 'sopralluogo';
export const PROJECT_CREATION_RILIEVO = 'rilievo';
export const PROJECT_CREATION_APE = 'ape';
export const PROJECT_CREATION_PREVENTIVO_MASSIMA = 'preventivo_massima';

export const SOPRALLUOGO_CONDOMINIUMS = 'condominiums';
export const SOPRALLUOGO_BUILDING = 'building';
export const SOPRALLUOGO_BUILDING_DETAIL = 'building_detail';
export const SOPRALLUOGO_BUILDING_PORTIONS = 'building_portions';
export const SOPRALLUOGO_WORKING_OPERATIONS = 'working_operations';
export const SOPRALLUOGO_CONDOMINIUM_ROOM = 'condominium_room';

// CLOSE PROJECT CAUSES
export const CAUSE_VERIFICA_DOCUMENTI_FALLITA = 'VERIFICA_DOCUMENTI_FALLITA';
export const CAUSE_PAGAMENTO_DELLA_CAPARRA_NON_EFFETTUATO = 'PAGAMENTO_DELLA_CAPARRA_NON_EFFETTUATO';
export const CAUSE_PRECONTRATTO_RIFIUTATO = 'PRECONTRATTO_RIFIUTATO';
export const CAUSE_VERIFICA_SOPRALLUOGO_FALLITA = 'VERIFICA_SOPRALLUOGO_FALLITA';
export const CAUSE_VERIFICA_RILIEVO_FALLITA = 'VERIFICA_RILIEVO_FALLITA';
export const CAUSE_PREVENTIVO_SANATORIA_NON_ACCETTATO = 'PREVENTIVO_SANATORIA_NON_ACCETTATO';
export const CAUSE_PREVENTIVO_DI_MASSIMA_NON_ACCETTATO = 'PREVENTIVO_DI_MASSIMA_NON_ACCETTATO';
export const CAUSE_PROGETTO_PRELIMINARE_RIFIUTATO = 'PROGETTO_PRELIMINARE_RIFIUTATO';
export const CAUSE_PROGETTO_ESECUTIVO_RIFIUTATO = 'PROGETTO_ESECUTIVO_RIFIUTATO';

// GENERIC SERVICES
export const SERVICE_CLOSE_PROJECT = '/project/close';
export const SERVICE_CLOSE_BOOKING = '/project/close/booking';

// PROJECT FLOW
export const PROJECT_ACCETTAZIONE_CILA = '/project/close/accept-cila';
export const PROJECT_ACCETTAZIONE_PREVENTIVO_MASSIMA = '/project/close/accept-rough-quotation';
export const PROJECT_ACCETTAZIONE_PREVENTIVO_SANATORIA = '/project/close/accept-sanatory-quotation';
export const PROJECT_ACCETTAZIONE_PROGETTO_ESECUTIVO = '/project/close/accept-executive-project';
export const PROJECT_ACCETTAZIONE_PROGETTO_PRELIMINARE = '/project/close/accept-preliminary-project';
export const PROJECT_CARICAMENTO_DICHIARAZIONE_SOSTITUTIVA = '/project/close/upload-substitutive-declaration';
export const PROJECT_CARICAMENTO_DOCUMENTI = '/project/close/documents-upload';
export const PROJECT_CARICAMENTO_PROCURA_SANATORIA = '/project/close/upload-sanatory-document';
export const PROJECT_INVITI_CONDOMINI = '/project/close/condo-invitations';
export const PROJECT_PAGAMENTO = '/project/close/payment';
export const PROJECT_RICARICAMENTO_DOCUMENTI = '/project/close/documents-reupload';
export const PROJECT_VALUTAZIONE_TECNICO = '/project/close/technician-evaluate';
export const PROJECT_VERIFICA_PROPOSTA_PRENOTAZIONE = '/project/close/booking-management';

// COMPANY FLOW
export const COMPANY_ACCETTAZIONE_ASSEGNAZIONE = '/company/close/accept-company-assignment';
export const COMPANY_ACCETTAZIONE_ASSEGNAZIONE_APPALTATRICE = '/company/close/accept-general-contractor';
export const COMPANY_VERIFICA_COMPUTO_METRICO = '/company/close/verify-metric-calculation';

// TECHNICIAN FLOW
export const TECHNICIAN_ACCETTAZIONE_ASSEGNAZIONE = '/technician/close/accept-assignment';
export const TECHNICIAN_AGGIUNTA_CATEGORIE = '/technician/close/company-categories-addition';
export const TECHNICIAN_CARICAMENTO_ACCESSO_ATTI = '/technician/close/access-upload';
export const TECHNICIAN_CARICAMENTO_CILA = '/technician/close/cila-upload';
export const TECHNICIAN_CARICAMENTO_DICHIARAZIONE_SOSTITUTIVA = '/technician/close/upload-substitutive-declaration';
export const TECHNICIAN_CARICAMENTO_DOCUMENTO_TERMOTECNICO = '/technician/close/thermotechnician-document-upload';
export const TECHNICIAN_CARICAMENTO_FATTIBILITA = '/technician/close/rough-quotation-upload';
export const TECHNICIAN_CARICAMENTO_PREVENTIVO_SANATORIA = '/technician/close/sanatory-quotation-upload';
export const TECHNICIAN_CARICAMENTO_PROGETTO_ESECUTIVO = '/technician/close/executive-project-upload';
export const TECHNICIAN_CARICAMENTO_PROGETTO_PRELIMINARE = '/technician/close/preliminary-project-upload';
export const TECHNICIAN_COMPILAZIONE_APE = '/technician/close/ape-completed';
export const TECHNICIAN_COMPILAZIONE_RILIEVO = '/technician/close/detection-completed';
export const TECHNICIAN_COMPILAZIONE_SOPRALLUOGO = '/technician/close/inspection-completed';
export const TECHNICIAN_CONCLUSIONE_SANATORIA = '/technician/close/sanatory-completed';
export const TECHNICIAN_DEPOSITO_PROGETTO_ESECUTIVO = '/technician/close/executive-project-deposit';
export const TECHNICIAN_DEPOSITO_SANATORIA = '/technician/close/sanatory-deposit';
export const TECHNICIAN_GESTIONE_RILIEVO = '/technician/close/detection-management';
export const TECHNICIAN_INSERIMENTO_IMPORTI = '/technician/close/suppliers-amount';
export const TECHNICIAN_PROPOSTA_PRENOTAZIONE = '/technician/close/booking-proposal';
export const TECHNICIAN_SELEZIONE_CATEGORIE = '/technician/close/company-categories-selection';
export const TECHNICIAN_SELEZIONE_FIGURE = '/technician/close/technician-figures-selection';
export const TECHNICIAN_VERIFICA_DOCUMENTI = '/technician/close/documents-verification';

// ADMIN FLOW
export const ADMIN_ASSEGNAZIONE_AZIENDA = '/admin/close/company-assignment';
export const ADMIN_ASSEGNAZIONE_AZIENDA_APPALTATRICE = '/admin/close/general-contractor-assignment';
export const ADMIN_ASSEGNAZIONE_TECNICO = '/admin/close/technician-assignment';
export const ADMIN_CALCOLO_PAGAMENTO_PROGETTAZIONE = '/admin/close/design-payment-calculation';
export const ADMIN_CREAZIONE_PROGETTO = '/admin/close/create-project';
export const ADMIN_GESTIONE_PROCEDURA_FIRMA = '/admin/close/signature-procedure-management';
export const ADMIN_PAGAMENTO = '/admin/close/deposit-return';
export const ADMIN_VALIDAZIONE_DOCUMENTO = '/admin/close/document-validation';
export const ADMIN_VERIFICA_RICEZIONE_PAGAMENTO = '/admin/close/verify-payment';
