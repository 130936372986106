import { TEC_CERTIFICATORE_ENERGETICO } from 'constants/ProjectsConstant';
import { GENERIC_TASKS, TECHNICIAN_TASKS } from './CommonTasks';

export const TEC_CERTIFICATORE_ENERGETICO_STEPS = [
  {
    code: TEC_CERTIFICATORE_ENERGETICO,
    icon: null,
    order: 1,
  },
];

export const TEC_CERTIFICATORE_ENERGETICO_TASKS = [...TECHNICIAN_TASKS, ...GENERIC_TASKS];
