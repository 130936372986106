export const INSERT_PROJECT = 'INSERT_PROJECT';

export const GET_INVITATIONS_BY_ID = 'GET_INVITATIONS_BY_ID';
export const GET_INVITATIONS = 'GET_INVITATIONS';
export const SET_INVITATIONS = 'SET_INVITATIONS';

export const ACCEPT_INVITATION = 'ACCEPT_INVITATION';
export const UPDATE_INVITATION = 'UPDATE_INVITATION';
export const DELETE_INVITATION = 'DELETE_INVITATION';
export const RESEND_INVITATION = 'RESEND_INVITATION';
