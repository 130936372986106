import { TEC_ACUSTICO } from 'constants/ProjectsConstant';
import { GENERIC_TASKS, TECHNICIAN_TASKS } from './CommonTasks';

export const TEC_ACUSTICO_STEPS = [
  {
    code: TEC_ACUSTICO,
    icon: null,
    order: 1,
  },
];

export const TEC_ACUSTICO_TASKS = [...TECHNICIAN_TASKS, ...GENERIC_TASKS];
