import { TEC_SICUREZZA_ESECUZIONE } from 'constants/ProjectsConstant';
import { GENERIC_TASKS, TECHNICIAN_TASKS } from './CommonTasks';

export const TEC_SICUREZZA_ESECUZIONE_STEPS = [
  {
    code: TEC_SICUREZZA_ESECUZIONE,
    icon: null,
    order: 1,
  },
];

export const TEC_SICUREZZA_ESECUZIONE_TASKS = [...TECHNICIAN_TASKS, ...GENERIC_TASKS];
