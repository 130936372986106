import React from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { SIDE_NAV_WIDTH } from 'constants/ThemeConstant';
import { Scrollbars } from 'react-custom-scrollbars';
import MenuContent from './MenuContent';
import Logo from './Logo';

const { Sider } = Layout;

export const SideNav = props => {
  const { navCollapsed } = props;

  return (
    <Sider className="side-nav" width={SIDE_NAV_WIDTH} collapsed={navCollapsed}>
      <Logo {...props} />
      <Scrollbars autoHide>
        <MenuContent {...props} />
      </Scrollbars>
    </Sider>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed } = theme;
  return { navCollapsed };
};

export default connect(mapStateToProps)(SideNav);
