import { SET_REGISTRY } from '../constants/Customer';
import { SIGNOUT_SUCCESS } from '../constants/Auth';

const initState = {
  registry: {},
};

const customer = (state = initState, action) => {
  switch (action.type) {
    case SET_REGISTRY:
      return {
        ...state,
        registry: action.payload,
      };
    case SIGNOUT_SUCCESS:
      return {
        ...initState,
      };
    default:
      return state;
  }
};

export default customer;
