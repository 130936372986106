import {
  AUTH_ROLE,
  AUTH_TOKEN,
  AUTH_USERID,
  AUTHENTICATED,
  SIGNOUT_SUCCESS,
  SHOW_LOADING,
  SET_HIDE_LOADING,
  AUTH_MAIL,
} from '../constants/Auth';

const initState = {
  loading: false,
  token: localStorage.getItem(AUTH_TOKEN),
  role: localStorage.getItem(AUTH_ROLE),
  userId: localStorage.getItem(AUTH_USERID),
  mail: localStorage.getItem(AUTH_MAIL),
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case AUTHENTICATED:
      return {
        ...state,
        loading: false,
        token: action.payload.token,
        role: action.payload.role,
        userId: action.payload.userId,
        mail: action.payload.mail,
      };
    case SIGNOUT_SUCCESS: {
      return {
        ...state,
        token: null,
        loading: false,
        role: null,
        mail: null,
      };
    }
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_HIDE_LOADING: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default auth;
