import {
  SET_SELECTED_DATE,
  SET_APPOINTMENTS,
  SET_WEEKLY_APPOINTMENTS,
  SET_SELECTED_APPOINTMENT,
  SET_PRESENT_MONTHS,
  SET_FIRST_MONTH,
  CLEANUP_APPOINTMENTS,
  GET_APPOINTMENTS,
  SET_SEEN_REQUESTS,
} from 'redux/constants/Appointments';

export const setSelectedDate = date => ({
  type: SET_SELECTED_DATE,
  payload: date,
});

export const setSelectedAppointment = appointment => ({
  type: SET_SELECTED_APPOINTMENT,
  payload: appointment,
});

export const setAppointments = appointments => ({
  type: SET_APPOINTMENTS,
  payload: appointments,
});

export const setWeeklyAppointments = appointments => ({
  type: SET_WEEKLY_APPOINTMENTS,
  payload: appointments,
});

export const setPresentMonths = months => ({
  type: SET_PRESENT_MONTHS,
  payload: months,
});

export const setFirstMonth = month => ({
  type: SET_FIRST_MONTH,
  payload: month,
});

export const cleanupAppointments = () => ({
  type: CLEANUP_APPOINTMENTS,
});

export const getAppointments = date => ({
  type: GET_APPOINTMENTS,
  payload: { date },
});

export const setSeenRequests = seenReq => ({
  type: SET_SEEN_REQUESTS,
  payload: seenReq,
});
