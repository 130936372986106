import fetch from './interceptor';

const AdminService = {};

AdminService.getAdminDetails = () =>
  fetch({
    url: '/api/admin/detail/get/admin-detail',
    method: 'get',
  });

AdminService.updateAdminDetails = data =>
  fetch({
    url: '/api/admin/detail/update/admin-detail',
    method: 'put',
    data,
  });

AdminService.getUsers = () =>
  fetch({
    url: '/api/admin/users/get',
    method: 'get',
  });

AdminService.getTasklistRegistration = () =>
  fetch({
    url: '/api/admin/list-tasks',
    method: 'get',
  });

AdminService.getTasklistProjects = () =>
  fetch({
    url: '/api/admin/task-list-project',
    method: 'get',
  });

AdminService.completeTask = (userId, taskId, variables) => {
  const formData = new FormData();
  formData.append('userId', userId);
  formData.append('taskId', taskId);
  formData.append('variables', JSON.stringify(variables));

  return fetch({
    url: '/api/admin/close/task',
    method: 'post',
    data: formData,
  });
};

AdminService.getProcessDiagrams = () =>
  fetch({
    url: '/api/admin/xml-tasks',
    method: 'get',
  });

AdminService.getAssignableTechnicians = params =>
  fetch({
    url: '/api/admin/get/assignable-technicians',
    method: 'get',
    params,
  });

AdminService.getBuilding = params =>
  fetch({
    url: '/api/technician/get/building',
    method: 'get',
    params,
  });

AdminService.getCondominiums = params =>
  fetch({
    url: '/api/technician/get/condominiums',
    method: 'get',
    params,
  });

AdminService.getCondominiumRooms = params =>
  fetch({
    url: '/api/technician/get/condominium-room-list',
    method: 'get',
    params,
  });

AdminService.getWorkingOperations = params =>
  fetch({
    url: '/api/technician/get/project-working-operations-list',
    method: 'get',
    params,
  });

AdminService.getInspection = params =>
  fetch({
    url: '/api/technician/get/inspection',
    method: 'get',
    params,
  });

AdminService.getProjectCompanyCategory = params =>
  fetch({
    url: '/api/admin/get/project-company-category',
    method: 'get',
    params,
  });

AdminService.getAppointments = params =>
  fetch({
    url: '/api/admin/get/booking-list',
    method: 'get',
    params,
  });

AdminService.updateCompanyDetails = data =>
  fetch({
    url: '/api/admin/update/company-registry',
    method: 'put',
    data,
  });

AdminService.payTechnician = params =>
  fetch({
    url: '/api/admin/update/pay-technician',
    method: 'put',
    params,
  });

AdminService.payCompany = params =>
  fetch({
    url: '/api/admin/update/pay-company',
    method: 'put',
    params,
  });

AdminService.getTechnicianAssignments = params =>
  fetch({
    url: '/api/admin/get/technician-assignments',
    method: 'get',
    params,
  });

AdminService.getProcedureStatus = params =>
  fetch({
    url: '/api/admin/get/signature-procedure',
    method: 'get',
    params,
  });

AdminService.getGeneralContractorList = () =>
  fetch({
    url: '/api/admin/get/general-contractor-list',
    method: 'get',
  });

AdminService.getTrustedSuppliers = params =>
  fetch({
    url: '/api/admin/get/trusted-suppliers',
    method: 'get',
    params,
  });

AdminService.reassignSupplier = params =>
  fetch({
    url: '/api/admin/update/supplier-reassign',
    method: 'put',
    params,
  });

AdminService.getUsersNewProject = () =>
  fetch({
    url: '/api/admin/get/new-project-customers',
    method: 'get',
  });

AdminService.updateProjectSuppliers = ({ projectId, insertList, deleteList }) => {
  const data = new FormData();
  data.append('projectId', projectId);
  data.append('insertList', JSON.stringify(insertList));
  data.append('deleteList', JSON.stringify(deleteList));

  return fetch({
    url: '/api/admin/update/works-project-suppliers',
    method: 'put',
    data,
  });
};

AdminService.updateProjectCompanyCategories = ({ projectId, insertList, deleteList }) => {
  const data = new FormData();
  data.append('projectId', projectId);
  data.append('insertList', JSON.stringify(insertList));
  data.append('deleteList', JSON.stringify(deleteList));

  return fetch({
    url: '/api/admin/update/project-company-categories',
    method: 'put',
    data,
  });
};

AdminService.deleteProject = params =>
  fetch({
    url: '/api/admin/delete-project',
    method: 'delete',
    params,
  });

AdminService.requestDocument = params =>
  fetch({
    url: '/api/admin/request-document',
    method: 'post',
    params,
  });

AdminService.getProjectUsersList = params =>
  fetch({
    url: '/api/admin/get/request-document-users',
    method: 'get',
    params,
  });

AdminService.getUnreadComments = params =>
  fetch({
    url: '/api/admin/get/unread-comments',
    method: 'get',
    params,
  });

AdminService.convertDocument = params =>
  fetch({
    url: '/api/admin/docxtopdf-conversion-start',
    method: 'post',
    params,
  });

export default AdminService;
