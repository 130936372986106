/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Badge, Menu, Grid } from 'antd';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import navigationConfig from 'configs/NavigationConfig';
import { connect } from 'react-redux';
import Utils from 'utils';
import { onMobileNavToggle } from 'redux/actions/Theme';
import { setMenuKey } from 'redux/actions/Status';
import { ADMIN, TECHNICIAN, CUSTOMER, CONDO } from 'constants/AppConstant';

import Icon from '../util-components/Icon';
import IntlMessage from '../util-components/IntlMessage';

const { useBreakpoint } = Grid;

const MenuContent = props => {
  const {
    role,
    onMobileNavToggle,
    navCollapsed,
    menuKey,
    setMenuKey,
    assignments,
    list,
    seenAssignments,
    seenTasklist,
    tasklistProjects,
    tasklistRegistration,
    seenRequests,
  } = props;
  const intl = useIntl();
  const isMobile = !Utils.getBreakPoint(useBreakpoint()).includes('lg');
  const [tasklistBadge, setTasklistBadge] = useState(null);
  const [assignmentsBadge, setAssignmentsBadge] = useState(null);
  const [requestsBadge, setRequestsBadge] = useState(null);
  const isAdmin = role === ADMIN;

  const tasks = {
    [TECHNICIAN]: [
      'proposta_prenotazione_sopralluogo',
      'proposta_prenotazione_rilievo',
      'proposta_prenotazione_ape',
      'proposta_prenotazione_progetto_preliminare',
      'proposta_prenotazione_termotecnico',
    ],
    [CUSTOMER]: [
      'verifica_proposta_prenotazione',
      'verifica_proposta_prenotazione_rilievo',
      'verifica_proposta_prenotazione_progettista',
    ],
    [CONDO]: [
      'verifica_proposta_prenotazione',
      'verifica_proposta_prenotazione_rilievo',
      'verifica_proposta_prenotazione_progettista',
    ],
  };

  useEffect(() => {
    const total = tasklistProjects.length + tasklistRegistration.length;
    setTasklistBadge(total);
  }, [tasklistProjects, tasklistRegistration]);

  useEffect(() => {
    setAssignmentsBadge(assignments.length);
  }, [assignments]);

  useEffect(() => {
    setRequestsBadge(
      list.filter(proj => {
        return proj.projectTasks.some(task =>
          tasks[role]?.includes(task.taskDefinitionKey),
        );
      }).length,
    );
  }, [list]);

  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };

  const firstKey = navigationConfig[role][0].key;

  const getBadge = menuKey => {
    let badgeCount = 0;

    if (isAdmin && !seenTasklist) {
      badgeCount = tasklistBadge;
    } else if (menuKey === 'appointments' && !seenRequests) {
      badgeCount = requestsBadge;
    } else if (menuKey === 'assignments' && !seenAssignments) {
      badgeCount = assignmentsBadge;
    }
    return (
      badgeCount > 0 && (
        <Badge
          className="custom-badge"
          offset={[20, -4]}
          count={badgeCount}
          style={{ borderColor: '#d93131' }}
        />
      )
    );
  };

  const renderedMenuItem = menu => (
    <Menu.Item className="ant-menu-item-custom-superpratico" key={menu.key}>
      {menu.icon && navCollapsed ? <Icon type={menu?.icon} /> : null}
      <span>
        <IntlMessage id={menu.title} />
      </span>
      {menu.hasBadge && !navCollapsed && getBadge(menu.key)}
      {menu.path ? (
        <Link onClick={() => closeMobileNav()} to={menu.path} />
      ) : null}
    </Menu.Item>
  );

  return (
    <Menu
      theme="dark"
      mode="inline"
      className="px-3 pt-4"
      style={{ height: '100%', borderRight: 0 }}
      selectedKeys={[menuKey || firstKey]}
      onSelect={({ key }) => setMenuKey(key)}
    >
      {navigationConfig[role].map(menu => renderedMenuItem(menu))}
      {!isMobile ? (
        !navCollapsed ? (
          <h2 className="rights-reserved-text desktop-rights-text-position">
            {intl.formatMessage(
              {
                id: 'nav.rights_reserved',
              },
              {
                b: str => <b>{str}</b>,
              },
            )}
          </h2>
        ) : null
      ) : null}
    </Menu>
  );
};

const mapStateToProps = ({
  auth,
  theme,
  appStatus,
  projects,
  admin,
  appointments,
}) => {
  const { role } = auth;
  const { navCollapsed } = theme;
  const { menuKey } = appStatus;
  const { assignments, seenAssignments, list } = projects;
  const { tasklistRegistration, tasklistProjects, seenTasklist } = admin;
  const { seenRequests } = appointments;
  return {
    role,
    navCollapsed,
    menuKey,
    assignments,
    seenAssignments,
    tasklistRegistration,
    tasklistProjects,
    seenTasklist,
    list,
    seenRequests,
  };
};

export default connect(mapStateToProps, {
  onMobileNavToggle,
  setMenuKey,
})(MenuContent);
