import fetch from './interceptor';

const AuthService = {};

AuthService.login = data =>
  fetch({
    url: '/auth/login',
    method: 'post',
    data,
  });

AuthService.signUp = data =>
  fetch({
    url: '/registration',
    method: 'post',
    data,
  });

AuthService.forgotPassword = data =>
  fetch({
    url: '/registration/reset-password',
    method: 'post',
    params: data,
  });

AuthService.resetPassword = data =>
  fetch({
    url: '/registration/save-password',
    method: 'post',
    data,
  });

AuthService.changePassword = data =>
  fetch({
    url: '/registration/change-password',
    method: 'post',
    data,
  });

export default AuthService;
