const dev = {
  API_ENDPOINT_URL: 'http://127.0.0.1:8080',
};

const prod = {
  API_ENDPOINT_URL: 'https://superpratico.org',
};

const staging = {
  API_ENDPOINT_URL: 'https://superbonus-test.ennova-research.com',
};

const test = {
  API_ENDPOINT_URL: 'http://127.0.0.1:8080',
};

const getEnv = () => {
  switch (process.env.REACT_APP_CUSTOM_NODE_ENV) {
    case 'development':
      return dev;
    case 'production':
      return prod;
    case 'test':
      return test;
    case 'staging':
      return staging;
    default:
      break;
  }
};

export const env = getEnv();
