import app from './app.json';
import auth from './auth.json';
import registration from './registration.json';
import company from './company.json';
import technician from './technician.json';
import customer from './customer.json';
import admin from './admin.json';
import projects from './projects.json';
import appointments from './appointments.json';

const itMsg = {
  ...app,
  ...auth,
  ...registration,
  ...company,
  ...technician,
  ...customer,
  ...admin,
  ...projects,
  ...appointments,
};

export default itMsg;
