import React from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

const GlobalDndContext = props => {
  const { children } = props;

  return (
    <DndProvider backend={HTML5Backend} key={Math.random()}>
      {children}
    </DndProvider>
  );
};

export default GlobalDndContext;
