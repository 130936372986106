import { ADMIN, COMPANY, CONDO, CONDO_CHILD, CUSTOMER, DEFAULT_ACCEPTED_FORMATS, MAX_SIZE_FILE } from 'constants/AppConstant';
import {
  ALL_PROJECT_TYPES,
  ALL_TECHNICIAN_ASSIGNMENTS,
  DELEGA_SCHEDE_URL,
  GENERAL_CONTRACTOR,
  PROJECT_ASSIGNMENTS,
  TEC_ACUSTICO,
  TEC_APE,
  TEC_ASSEVERATORE,
  TEC_CALCOLATORE_CA,
  TEC_CERTIFICATORE_ENERGETICO,
  TEC_COLLAUDATORE_CA,
  TEC_CONDUZIONE_CANTIERE,
  TEC_DIREZIONE_LAVORI,
  TEC_GEOLOGO,
  TEC_PROGETTISTA,
  TEC_RILIEVO,
  TEC_SICUREZZA,
  TEC_SICUREZZA_ESECUZIONE,
  TEC_SOPRALLUOGO,
  TEC_TERMOTECNICO,
  TEC_TERMOTECNICO_ESECUZIONE,
  TEC_VERIFICA_DOCUMENTI,
  TEC_VVF,
  WORK_ASSIGNMENTS,
} from 'constants/ProjectsConstant';

/**
 * ** TEMPLATES
 */

export const TEMPLATE_GUIDA_RILIEVO = 'GUIDA_RILIEVO.pdf';
export const TEMPLATE_GUIDA_SANATORIA = 'GUIDA_SANATORIA.pdf';
export const TEMPLATE_RELAZIONE_FATTIBILITA = 'RELAZIONE_FATTIBILITA.docx';
export const TEMPLATE_QUADRO_ECONOMICO = 'QUADRO_ECONOMICO.xls';
export const TEMPLATE_VERBALE_DELIBERA = 'VERBALE_DELIBERA.docx';
export const TEMPLATE_DELEGA_CONDOMINO_DELEGATO = 'DELEGA_CONDOMINO_DELEGATO.docx';
export const TEMPLATE_DICHIARAZIONE_SOSTITUTIVA = 'DICHIARAZIONE_SOSTITUTIVA.docx';
export const TEMPLATE_PROCURA_SANATORIA = 'PROCURA_SANATORIA.docx';
export const TEMPLATE_DELEGA_ACCESSO_ATTI = 'DELEGA_ACCESSO_ATTI.docx';

/**
 * ** OTHER VARIABLES
 */

export const DOCUMENTS_CATEGORIES = {
  IMAGES: 'IMAGES',
  VERIFY: 'VERIFY',
  TECHNICAL: 'TECHNICAL',
  CONTRACTS: 'CONTRACTS',
};

export const ALL_ASSIGNMENTS = [ADMIN, ...ALL_PROJECT_TYPES, COMPANY, GENERAL_CONTRACTOR, ...ALL_TECHNICIAN_ASSIGNMENTS];

const DEFAULT_VALUES = {
  projectType: ALL_PROJECT_TYPES,
  allowlistView: ALL_ASSIGNMENTS,
  allowlistUpload: ALL_ASSIGNMENTS,
  blocklistView: [],
  blocklistUpload: [],
  formatList: DEFAULT_ACCEPTED_FORMATS,
  template: null,
  required: false,
  multiple: true,
  updateUpload: true,
  drag: 'file',
  tooltip: null,
  link: null,
  maxSize: MAX_SIZE_FILE,
};

/**
 * *** PROJECT DOCUMENTS DOCUMENTATION
 *  REQUIRED
 *  @type string (nel BE è un enum ProjectDocumentType)
 *  @category string (DOCUMENTS_CATEGORIES definito in alto)
 *
 *  OPTIONAL
 *  @projectType []string (DEFAULT: ALL_PROJECT_TYPES [CUSTOMER, CONDO_CHILD, CONDO])
 *  @allowlistView []string<assignments> (DEFAULT: ALL_ASSIGNMENTS)
 *  @allowlistUpload []string<assignments> (DEFAULT: ALL_ASSIGNMENTS)
 *  @blocklistView []string<assignments> (DEFAULT: [])
 *  @blocklistUpload []string<assignments> (DEFAULT: [])
 *  @formatList []string (es: ['.pdf', '.jpg'] || DEFAULT: ['.pdf', '.png', '.jpeg', '.heic', '.heif', '.jpg'])
 *  @template (string) (dalla lista dei templates definita in alto)
 *  @required boolean (DEFAULT: false)
 *  @multiple boolean (DEFAULT: true)
 *  @drag string (è il code per usare nella label `project.document.${code}.drag` || DEFAULT: 'file')
 *  @updateUpload boolean (DEFAULT: false)
 *  @tooltip string (fa vedere un tooltip di info. viene usato nella label `project.document.${code}.tooltip`)
 *  @link string (URL da aggiungere al tooltip)
 *  @maxSize number (in bytes es: 20000000 == 20MB || DEFAULT: 10000000 === 10MB)
 */

/**
 * * IMAGES DOCUMENTS
 */

export const PROJECT_DOCUMENT_IMMAGINI_IMMOBILE = {
  ...DEFAULT_VALUES,
  type: 'IMMAGINI_IMMOBILE',
  category: DOCUMENTS_CATEGORIES.IMAGES,
  required: true,
  drag: 'immagini_immobile',
  projectType: [CUSTOMER, CONDO_CHILD],
  allowlistUpload: [ADMIN, CUSTOMER, CONDO_CHILD],
};

export const PROJECT_DOCUMENT_IMMAGINI_CONDO = {
  ...DEFAULT_VALUES,
  type: 'IMMAGINI_CONDO',
  category: DOCUMENTS_CATEGORIES.IMAGES,
  required: true,
  drag: 'immagini_condo',
  projectType: [CONDO],
  allowlistUpload: [ADMIN, CONDO],
};

export const PROJECT_DOCUMENT_IMMAGINI_SOPRALLUOGO = {
  ...DEFAULT_VALUES,
  type: 'IMMAGINI_SOPRALLUOGO',
  category: DOCUMENTS_CATEGORIES.IMAGES,
  required: true,
  projectType: ALL_PROJECT_TYPES,
  allowlistUpload: [ADMIN, ...ALL_PROJECT_TYPES, TEC_SOPRALLUOGO],
};

export const PROJECT_DOCUMENT_PRESENZA_INTERFERENZE = {
  ...DEFAULT_VALUES,
  type: 'PRESENZA_INTERFERENZE',
  category: DOCUMENTS_CATEGORIES.IMAGES,
  required: true,
  projectType: ALL_PROJECT_TYPES,
  allowlistUpload: [ADMIN, ...ALL_PROJECT_TYPES, TEC_SOPRALLUOGO],
};

export const PROJECT_DOCUMENT_AMMALORAMENTI = {
  ...DEFAULT_VALUES,
  type: 'AMMALORAMENTI',
  category: DOCUMENTS_CATEGORIES.IMAGES,
  projectType: ALL_PROJECT_TYPES,
  allowlistUpload: [ADMIN, ...ALL_PROJECT_TYPES, TEC_SOPRALLUOGO],
};

export const PROJECT_DOCUMENT_FACCIATE = {
  ...DEFAULT_VALUES,
  type: 'FACCIATE',
  category: DOCUMENTS_CATEGORIES.IMAGES,
  projectType: ALL_PROJECT_TYPES,
  allowlistUpload: [ADMIN, ...ALL_PROJECT_TYPES, TEC_SOPRALLUOGO],
};

export const PROJECT_DOCUMENT_SERRAMENTI = {
  ...DEFAULT_VALUES,
  type: 'SERRAMENTI',
  category: DOCUMENTS_CATEGORIES.IMAGES,
  projectType: ALL_PROJECT_TYPES,
  allowlistUpload: [ADMIN, ...ALL_PROJECT_TYPES, TEC_SOPRALLUOGO],
};

export const PROJECT_DOCUMENT_SOGLIE = {
  ...DEFAULT_VALUES,
  type: 'SOGLIE',
  category: DOCUMENTS_CATEGORIES.IMAGES,
  projectType: ALL_PROJECT_TYPES,
  allowlistUpload: [ADMIN, ...ALL_PROJECT_TYPES, TEC_SOPRALLUOGO],
};

export const PROJECT_DOCUMENT_DAVANZALI = {
  ...DEFAULT_VALUES,
  type: 'DAVANZALI',
  category: DOCUMENTS_CATEGORIES.IMAGES,
  projectType: ALL_PROJECT_TYPES,
  allowlistUpload: [ADMIN, ...ALL_PROJECT_TYPES, TEC_SOPRALLUOGO],
};

export const PROJECT_DOCUMENT_LATTONERIE = {
  ...DEFAULT_VALUES,
  type: 'LATTONERIE',
  category: DOCUMENTS_CATEGORIES.IMAGES,
  projectType: ALL_PROJECT_TYPES,
  allowlistUpload: [ADMIN, ...ALL_PROJECT_TYPES, TEC_SOPRALLUOGO],
};

export const PROJECT_DOCUMENT_IMPIANTI_RISCALDAMENTO = {
  ...DEFAULT_VALUES,
  type: 'IMPIANTI_RISCALDAMENTO',
  category: DOCUMENTS_CATEGORIES.IMAGES,
  projectType: ALL_PROJECT_TYPES,
  allowlistUpload: [ADMIN, ...ALL_PROJECT_TYPES, TEC_SOPRALLUOGO],
};

export const PROJECT_DOCUMENT_TERMINALI = {
  ...DEFAULT_VALUES,
  type: 'TERMINALI',
  category: DOCUMENTS_CATEGORIES.IMAGES,
  projectType: ALL_PROJECT_TYPES,
  allowlistUpload: [ADMIN, ...ALL_PROJECT_TYPES, TEC_SOPRALLUOGO],
};

export const PROJECT_DOCUMENT_GENERATORE_CALORE = {
  ...DEFAULT_VALUES,
  type: 'GENERATORE_CALORE',
  category: DOCUMENTS_CATEGORIES.IMAGES,
  required: true,
  projectType: ALL_PROJECT_TYPES,
  allowlistUpload: [ADMIN, ...ALL_PROJECT_TYPES, TEC_APE],
};

export const PROJECT_DOCUMENT_LIBRETTO = {
  ...DEFAULT_VALUES,
  type: 'LIBRETTO',
  category: DOCUMENTS_CATEGORIES.IMAGES,
  required: true,
  projectType: ALL_PROJECT_TYPES,
  allowlistUpload: [ADMIN, ...ALL_PROJECT_TYPES, TEC_APE],
};

export const PROJECT_DOCUMENT_TIPOLOGIA_IMPIANTO = {
  ...DEFAULT_VALUES,
  type: 'TIPOLOGIA_IMPIANTO',
  category: DOCUMENTS_CATEGORIES.IMAGES,
  required: true,
  projectType: ALL_PROJECT_TYPES,
  allowlistUpload: [ADMIN, ...ALL_PROJECT_TYPES, TEC_APE],
};

export const IMAGES_DOCUMENTS_CONFIG = [
  PROJECT_DOCUMENT_IMMAGINI_IMMOBILE,
  PROJECT_DOCUMENT_IMMAGINI_CONDO,
  PROJECT_DOCUMENT_IMMAGINI_SOPRALLUOGO,
  PROJECT_DOCUMENT_PRESENZA_INTERFERENZE,
  PROJECT_DOCUMENT_AMMALORAMENTI,
  PROJECT_DOCUMENT_FACCIATE,
  PROJECT_DOCUMENT_SERRAMENTI,
  PROJECT_DOCUMENT_SOGLIE,
  PROJECT_DOCUMENT_DAVANZALI,
  PROJECT_DOCUMENT_LATTONERIE,
  PROJECT_DOCUMENT_IMPIANTI_RISCALDAMENTO,
  PROJECT_DOCUMENT_TERMINALI,
  PROJECT_DOCUMENT_GENERATORE_CALORE,
  PROJECT_DOCUMENT_LIBRETTO,
  PROJECT_DOCUMENT_TIPOLOGIA_IMPIANTO,
];

/**
 * * VERIFY DOCUMENTS
 */

export const PROJECT_DOCUMENT_ATTO_PROVENIENZA = {
  ...DEFAULT_VALUES,
  type: 'ATTO_PROVENIENZA',
  category: DOCUMENTS_CATEGORIES.VERIFY,
  required: true,
  drag: 'atto_provenienza',
  projectType: [CUSTOMER, CONDO_CHILD],
  allowlistUpload: [ADMIN, CUSTOMER, CONDO_CHILD],
};

export const PROJECT_DOCUMENT_ATTO_PROVENIENZA_CONDO = {
  ...DEFAULT_VALUES,
  type: 'ATTO_PROVENIENZA_CONDO',
  category: DOCUMENTS_CATEGORIES.VERIFY,
  required: true,
  drag: 'atto_provenienza_condo',
  projectType: [CONDO],
  allowlistUpload: [ADMIN, CONDO],
};

export const PROJECT_DOCUMENT_TITOLI_EDILIZI = {
  ...DEFAULT_VALUES,
  type: 'TITOLI_EDILIZI',
  category: DOCUMENTS_CATEGORIES.VERIFY,
  required: true,
  drag: 'titoli_edilizi',
  projectType: ALL_PROJECT_TYPES,
  allowlistUpload: [ADMIN, ...ALL_PROJECT_TYPES, TEC_VERIFICA_DOCUMENTI],
};

export const PROJECT_DOCUMENT_DELEGA_ATTI = {
  ...DEFAULT_VALUES,
  type: 'DELEGA_ATTI',
  category: DOCUMENTS_CATEGORIES.VERIFY,
  template: TEMPLATE_DELEGA_ACCESSO_ATTI,
  required: false,
  multiple: false,
  tooltip: 'delega_atti',
  drag: 'delega_atti',
  projectType: ALL_PROJECT_TYPES,
  allowlistView: [ADMIN, ...ALL_PROJECT_TYPES, TEC_VERIFICA_DOCUMENTI],
  allowlistUpload: [ADMIN, ...ALL_PROJECT_TYPES],
};

export const PROJECT_DOCUMENT_DOCUMENTO_IDENTITA = {
  ...DEFAULT_VALUES,
  type: 'DOCUMENTO_IDENTITA',
  category: DOCUMENTS_CATEGORIES.VERIFY,
  required: true,
  drag: 'documento_identita',
  projectType: ALL_PROJECT_TYPES,
  allowlistView: [ADMIN, ...ALL_PROJECT_TYPES],
  allowlistUpload: [ADMIN, ...ALL_PROJECT_TYPES],
};

export const PROJECT_DOCUMENT_SCHEDE_CATASTALI = {
  ...DEFAULT_VALUES,
  type: 'SCHEDE_CATASTALI',
  category: DOCUMENTS_CATEGORIES.VERIFY,
  required: true,
  drag: 'schede_catastali',
  projectType: ALL_PROJECT_TYPES,
  allowlistUpload: [ADMIN, ...ALL_PROJECT_TYPES, TEC_VERIFICA_DOCUMENTI],
};

export const PROJECT_DOCUMENT_DELEGA_SCHEDE = {
  ...DEFAULT_VALUES,
  type: 'DELEGA_SCHEDE',
  category: DOCUMENTS_CATEGORIES.VERIFY,
  required: true,
  drag: 'delega_schede',
  tooltip: 'delega_schede',
  link: DELEGA_SCHEDE_URL,
  projectType: ALL_PROJECT_TYPES,
  allowlistView: [ADMIN, ...ALL_PROJECT_TYPES, TEC_VERIFICA_DOCUMENTI],
  allowlistUpload: [ADMIN, ...ALL_PROJECT_TYPES],
};

export const PROJECT_DOCUMENT_PRESTAZIONE_ENERGETICA = {
  ...DEFAULT_VALUES,
  type: 'PRESTAZIONE_ENERGETICA',
  category: DOCUMENTS_CATEGORIES.VERIFY,
  drag: 'prestazione_energetica',
  projectType: ALL_PROJECT_TYPES,
  allowlistUpload: [ADMIN, ...ALL_PROJECT_TYPES, TEC_APE],
};

export const PROJECT_DOCUMENT_RILIEVO = {
  ...DEFAULT_VALUES,
  type: 'RILIEVO',
  formatList: [...DEFAULT_ACCEPTED_FORMATS, '.dwg'],
  category: DOCUMENTS_CATEGORIES.VERIFY,
  template: TEMPLATE_GUIDA_RILIEVO,
  drag: 'rilievo',
  maxSize: 20000000,
  projectType: ALL_PROJECT_TYPES,
  allowlistUpload: [ADMIN, ...ALL_PROJECT_TYPES, TEC_RILIEVO],
};

export const PROJECT_DOCUMENT_BOLLETTA_GAS = {
  ...DEFAULT_VALUES,
  type: 'BOLLETTA_GAS',
  category: DOCUMENTS_CATEGORIES.VERIFY,
  required: true,
  drag: 'bolletta_gas',
  projectType: ALL_PROJECT_TYPES,
  allowlistUpload: [ADMIN, ...ALL_PROJECT_TYPES],
};

export const PROJECT_DOCUMENT_BOLLETTA_ELETTRICA = {
  ...DEFAULT_VALUES,
  type: 'BOLLETTA_ELETTRICA',
  category: DOCUMENTS_CATEGORIES.VERIFY,
  required: true,
  drag: 'bolletta_elettrica',
  projectType: ALL_PROJECT_TYPES,
  allowlistUpload: [ADMIN, ...ALL_PROJECT_TYPES],
};

export const PROJECT_DOCUMENT_DELEGA_PROPRIETARIO = {
  ...DEFAULT_VALUES,
  type: 'DELEGA_PROPRIETARIO',
  category: DOCUMENTS_CATEGORIES.VERIFY,
  drag: 'delega_proprietario',
  projectType: [CONDO_CHILD],
  allowlistView: [ADMIN, CONDO_CHILD],
  allowlistUpload: [ADMIN, CONDO_CHILD],
};

export const VERIFY_DOCUMENTS_CONFIG = [
  PROJECT_DOCUMENT_ATTO_PROVENIENZA,
  PROJECT_DOCUMENT_ATTO_PROVENIENZA_CONDO,
  PROJECT_DOCUMENT_TITOLI_EDILIZI,
  PROJECT_DOCUMENT_DELEGA_ATTI,
  PROJECT_DOCUMENT_DOCUMENTO_IDENTITA,
  PROJECT_DOCUMENT_SCHEDE_CATASTALI,
  PROJECT_DOCUMENT_DELEGA_SCHEDE,
  PROJECT_DOCUMENT_PRESTAZIONE_ENERGETICA,
  PROJECT_DOCUMENT_RILIEVO,
  PROJECT_DOCUMENT_BOLLETTA_GAS,
  PROJECT_DOCUMENT_BOLLETTA_ELETTRICA,
  PROJECT_DOCUMENT_DELEGA_PROPRIETARIO,
];

/**
 * * PROJECT DOCUMENTS
 */

export const PROJECT_DOCUMENT_FATTIBILITA = {
  ...DEFAULT_VALUES,
  type: 'FATTIBILITA',
  category: DOCUMENTS_CATEGORIES.TECHNICAL,
  template: TEMPLATE_RELAZIONE_FATTIBILITA,
  required: true,
  projectType: ALL_PROJECT_TYPES,
  allowlistUpload: [ADMIN, TEC_APE, TEC_PROGETTISTA, GENERAL_CONTRACTOR],
  blocklistView: [TEC_VERIFICA_DOCUMENTI, TEC_SOPRALLUOGO, TEC_RILIEVO],
};

export const PROJECT_DOCUMENT_PROGETTO_PRELIMINARE = {
  ...DEFAULT_VALUES,
  type: 'PROGETTO_PRELIMINARE',
  category: DOCUMENTS_CATEGORIES.TECHNICAL,
  required: true,
  projectType: ALL_PROJECT_TYPES,
  allowlistUpload: [ADMIN, TEC_PROGETTISTA, GENERAL_CONTRACTOR],
  blocklistView: [TEC_VERIFICA_DOCUMENTI, TEC_SOPRALLUOGO, TEC_RILIEVO, TEC_APE],
};

export const PROJECT_DOCUMENT_PROGETTO_ESECUTIVO = {
  ...DEFAULT_VALUES,
  type: 'PROGETTO_ESECUTIVO',
  category: DOCUMENTS_CATEGORIES.TECHNICAL,
  required: true,
  projectType: ALL_PROJECT_TYPES,
  allowlistUpload: [ADMIN, TEC_PROGETTISTA, GENERAL_CONTRACTOR],
  blocklistView: [TEC_VERIFICA_DOCUMENTI, TEC_SOPRALLUOGO, TEC_RILIEVO, TEC_APE, TEC_TERMOTECNICO],
};

export const PROJECT_DOCUMENT_DEPOSITO_PROGETTO_ESECUTIVO = {
  ...DEFAULT_VALUES,
  type: 'DEPOSITO_PROGETTO_ESECUTIVO',
  category: DOCUMENTS_CATEGORIES.TECHNICAL,
  required: true,
  projectType: ALL_PROJECT_TYPES,
  allowlistUpload: [ADMIN, TEC_PROGETTISTA, GENERAL_CONTRACTOR],
  blocklistView: [TEC_VERIFICA_DOCUMENTI, TEC_SOPRALLUOGO, TEC_RILIEVO, TEC_APE, TEC_TERMOTECNICO],
};

export const PROJECT_DOCUMENT_DOCUMENTO_TERMOTECNICO = {
  ...DEFAULT_VALUES,
  type: 'DOCUMENTO_TERMOTECNICO',
  category: DOCUMENTS_CATEGORIES.TECHNICAL,
  required: true,
  projectType: ALL_PROJECT_TYPES,
  allowlistUpload: [ADMIN, TEC_TERMOTECNICO, GENERAL_CONTRACTOR],
  blocklistView: [TEC_VERIFICA_DOCUMENTI, TEC_SOPRALLUOGO, TEC_RILIEVO, TEC_APE],
};

export const PROJECT_DOCUMENT_SANATORIA = {
  ...DEFAULT_VALUES,
  type: 'SANATORIA',
  category: DOCUMENTS_CATEGORIES.TECHNICAL,
  template: TEMPLATE_GUIDA_SANATORIA,
  projectType: ALL_PROJECT_TYPES,
  allowlistUpload: [ADMIN, TEC_RILIEVO],
  allowlistView: [ADMIN, ...ALL_PROJECT_TYPES, TEC_RILIEVO],
};

export const PROJECT_DOCUMENT_PREVENTIVO_SANATORIA = {
  ...DEFAULT_VALUES,
  type: 'PREVENTIVO_SANATORIA',
  category: DOCUMENTS_CATEGORIES.TECHNICAL,
  required: true,
  projectType: ALL_PROJECT_TYPES,
  allowlistUpload: [ADMIN, TEC_RILIEVO],
  allowlistView: [ADMIN, ...ALL_PROJECT_TYPES, TEC_RILIEVO],
};

export const PROJECT_DOCUMENT_PROCURA_SANATORIA = {
  ...DEFAULT_VALUES,
  type: 'PROCURA_SANATORIA',
  category: DOCUMENTS_CATEGORIES.TECHNICAL,
  template: TEMPLATE_PROCURA_SANATORIA,
  required: true,
  projectType: ALL_PROJECT_TYPES,
  allowlistUpload: [ADMIN, ...ALL_PROJECT_TYPES],
  allowlistView: [ADMIN, ...ALL_PROJECT_TYPES, TEC_RILIEVO],
};

export const PROJECT_DOCUMENT_COMPUTO_METRICO = {
  ...DEFAULT_VALUES,
  type: 'COMPUTO_METRICO',
  formatList: ['.pdf'],
  category: DOCUMENTS_CATEGORIES.TECHNICAL,
  required: true,
  projectType: ALL_PROJECT_TYPES,
  allowlistUpload: [ADMIN, TEC_APE, TEC_PROGETTISTA, GENERAL_CONTRACTOR],
  blocklistView: [TEC_VERIFICA_DOCUMENTI, TEC_SOPRALLUOGO, TEC_RILIEVO],
};

export const PROJECT_DOCUMENT_VERBALE_ASSEMBLEA = {
  ...DEFAULT_VALUES,
  type: 'VERBALE_ASSEMBLEA',
  formatList: ['.pdf'],
  category: DOCUMENTS_CATEGORIES.TECHNICAL,
  required: true,
  projectType: [CONDO],
  allowlistUpload: [ADMIN, CONDO],
  allowlistView: [ADMIN, CONDO],
};

export const PROJECT_DOCUMENT_QUADRO_ECONOMICO = {
  ...DEFAULT_VALUES,
  type: 'QUADRO_ECONOMICO',
  formatList: ['.pdf'],
  category: DOCUMENTS_CATEGORIES.TECHNICAL,
  template: TEMPLATE_QUADRO_ECONOMICO,
  required: true,
  projectType: ALL_PROJECT_TYPES,
  allowlistUpload: [ADMIN, TEC_APE, TEC_PROGETTISTA, GENERAL_CONTRACTOR],
  blocklistView: [TEC_VERIFICA_DOCUMENTI, TEC_SOPRALLUOGO, TEC_RILIEVO],
};

export const PROJECT_DOCUMENT_IMPORTI_ESECUTORI = {
  ...DEFAULT_VALUES,
  type: 'IMPORTI_ESECUTORI',
  formatList: ['.pdf'],
  category: DOCUMENTS_CATEGORIES.TECHNICAL,
  required: true,
  projectType: ALL_PROJECT_TYPES,
  allowlistUpload: [ADMIN, TEC_APE, TEC_PROGETTISTA, GENERAL_CONTRACTOR],
  blocklistView: [TEC_VERIFICA_DOCUMENTI, TEC_SOPRALLUOGO, TEC_RILIEVO],
};

export const PROJECT_DOCUMENT_CANTIERE = {
  ...DEFAULT_VALUES,
  type: 'CANTIERE',
  category: DOCUMENTS_CATEGORIES.TECHNICAL,
  required: true,
  projectType: ALL_PROJECT_TYPES,
  blocklistView: PROJECT_ASSIGNMENTS.filter(assignment => assignment !== TEC_PROGETTISTA),
};

export const PROJECT_DOCUMENT_ALTRO = {
  ...DEFAULT_VALUES,
  type: 'ALTRO',
  category: DOCUMENTS_CATEGORIES.TECHNICAL,
  required: true,
  projectType: ALL_PROJECT_TYPES,
};

export const PROJECT_DOCUMENT_CILA = {
  ...DEFAULT_VALUES,
  type: 'CILA',
  category: DOCUMENTS_CATEGORIES.TECHNICAL,
  projectType: ALL_PROJECT_TYPES,
  allowlistView: [ADMIN, ...ALL_PROJECT_TYPES, TEC_APE],
  allowlistUpload: [ADMIN, TEC_APE],
};

export const PROJECT_DOCUMENT_VERBALE_DELIBERA = {
  ...DEFAULT_VALUES,
  type: 'VERBALE_DELIBERA',
  category: DOCUMENTS_CATEGORIES.TECHNICAL,
  template: TEMPLATE_VERBALE_DELIBERA,
  required: true,
  projectType: [CONDO],
  allowlistUpload: [ADMIN, CONDO],
  allowlistView: [ADMIN, CONDO],
};

export const PROJECT_DOCUMENT_DELEGA_CONDOMINO_DELEGATO = {
  ...DEFAULT_VALUES,
  type: 'DELEGA_CONDOMINO_DELEGATO',
  category: DOCUMENTS_CATEGORIES.TECHNICAL,
  template: TEMPLATE_DELEGA_CONDOMINO_DELEGATO,
  projectType: [CONDO],
  allowlistUpload: [ADMIN, CONDO],
  allowlistView: [ADMIN, CONDO],
};

export const PROJECT_DOCUMENT_DICHIARAZIONE_SOSTITUTIVA = {
  ...DEFAULT_VALUES,
  type: 'DICHIARAZIONE_SOSTITUTIVA',
  category: DOCUMENTS_CATEGORIES.TECHNICAL,
  template: TEMPLATE_DICHIARAZIONE_SOSTITUTIVA,
  required: true,
  projectType: [CONDO, CUSTOMER],
  allowlistUpload: [ADMIN, CONDO, CUSTOMER],
};

export const PROJECT_DOCUMENT_DICHIARAZIONE_SOSTITUTIVA_ASSEVERATORE = {
  ...DEFAULT_VALUES,
  type: 'DICHIARAZIONE_SOSTITUTIVA_ASSEVERATORE',
  category: DOCUMENTS_CATEGORIES.TECHNICAL,
  template: TEMPLATE_DICHIARAZIONE_SOSTITUTIVA,
  required: true,
  projectType: [CONDO, CUSTOMER],
  allowlistView: [ADMIN, TEC_ASSEVERATORE],
  allowlistUpload: [ADMIN, TEC_ASSEVERATORE],
};

export const PROJECT_DOCUMENT_DICHIARAZIONI_REDDITO = {
  ...DEFAULT_VALUES,
  type: 'DICHIARAZIONI_REDDITO',
  category: DOCUMENTS_CATEGORIES.TECHNICAL,
  required: true,
  drag: 'dichiarazioni_reddito',
  projectType: ALL_PROJECT_TYPES,
  allowlistUpload: [ADMIN, ...ALL_PROJECT_TYPES],
  allowlistView: [ADMIN, ...ALL_PROJECT_TYPES],
};

export const PROJECT_DOCUMENTS_CONFIG = [
  PROJECT_DOCUMENT_FATTIBILITA,
  PROJECT_DOCUMENT_PROGETTO_PRELIMINARE,
  PROJECT_DOCUMENT_PROGETTO_ESECUTIVO,
  PROJECT_DOCUMENT_DEPOSITO_PROGETTO_ESECUTIVO,
  PROJECT_DOCUMENT_DOCUMENTO_TERMOTECNICO,
  PROJECT_DOCUMENT_SANATORIA,
  PROJECT_DOCUMENT_PREVENTIVO_SANATORIA,
  PROJECT_DOCUMENT_PROCURA_SANATORIA,
  PROJECT_DOCUMENT_COMPUTO_METRICO,
  PROJECT_DOCUMENT_VERBALE_ASSEMBLEA,
  PROJECT_DOCUMENT_QUADRO_ECONOMICO,
  PROJECT_DOCUMENT_IMPORTI_ESECUTORI,
  PROJECT_DOCUMENT_CANTIERE,
  PROJECT_DOCUMENT_ALTRO,
  PROJECT_DOCUMENT_CILA,
  PROJECT_DOCUMENT_VERBALE_DELIBERA,
  PROJECT_DOCUMENT_DELEGA_CONDOMINO_DELEGATO,
  PROJECT_DOCUMENT_DICHIARAZIONE_SOSTITUTIVA,
  PROJECT_DOCUMENT_DICHIARAZIONE_SOSTITUTIVA_ASSEVERATORE,
  PROJECT_DOCUMENT_DICHIARAZIONI_REDDITO,
];

/**
 * * CONTRACT DOCUMENTS
 */

export const PROJECT_DOCUMENT_CONTRATTO_VERIFICA_DOCUMENTI = {
  ...DEFAULT_VALUES,
  type: 'CONTRATTO_VERIFICA_DOCUMENTI',
  category: DOCUMENTS_CATEGORIES.CONTRACTS,
  required: true,
  multiple: false,
  updateUpload: false,
  projectType: [CUSTOMER, CONDO],
  allowlistView: [ADMIN, TEC_VERIFICA_DOCUMENTI],
  allowlistUpload: [ADMIN],
};

export const PROJECT_DOCUMENT_PRECONTRATTO = {
  ...DEFAULT_VALUES,
  type: 'PRECONTRATTO',
  category: DOCUMENTS_CATEGORIES.CONTRACTS,
  multiple: false,
  updateUpload: false,
  projectType: [CUSTOMER],
  allowlistView: [ADMIN, CUSTOMER],
  allowlistUpload: [ADMIN],
};

export const PROJECT_DOCUMENT_PRECONTRATTO_CONDO = {
  ...DEFAULT_VALUES,
  type: 'PRECONTRATTO_CONDO',
  category: DOCUMENTS_CATEGORIES.CONTRACTS,
  multiple: false,
  updateUpload: false,
  projectType: [CONDO],
  allowlistView: [ADMIN, CONDO],
  allowlistUpload: [ADMIN],
};

export const PROJECT_DOCUMENT_CONTRATTO_SOPRALLUOGO = {
  ...DEFAULT_VALUES,
  type: 'CONTRATTO_SOPRALLUOGO',
  category: DOCUMENTS_CATEGORIES.CONTRACTS,
  required: true,
  multiple: false,
  updateUpload: false,
  projectType: [CUSTOMER, CONDO],
  allowlistView: [ADMIN, TEC_SOPRALLUOGO],
  allowlistUpload: [ADMIN],
};

export const PROJECT_DOCUMENT_CONTRATTO_RILIEVO = {
  ...DEFAULT_VALUES,
  type: 'CONTRATTO_RILIEVO',
  category: DOCUMENTS_CATEGORIES.CONTRACTS,
  required: true,
  multiple: false,
  updateUpload: false,
  projectType: [CUSTOMER, CONDO],
  allowlistView: [ADMIN, TEC_RILIEVO],
  allowlistUpload: [ADMIN],
};

export const PROJECT_DOCUMENT_CONTRATTO_APE = {
  ...DEFAULT_VALUES,
  type: 'CONTRATTO_APE',
  category: DOCUMENTS_CATEGORIES.CONTRACTS,
  required: true,
  multiple: false,
  updateUpload: false,
  projectType: [CUSTOMER, CONDO],
  allowlistView: [ADMIN, TEC_APE],
  allowlistUpload: [ADMIN],
};

export const PROJECT_DOCUMENT_CONTRATTO_PROGETTISTA = {
  ...DEFAULT_VALUES,
  type: 'CONTRATTO_PROGETTISTA',
  category: DOCUMENTS_CATEGORIES.CONTRACTS,
  required: true,
  multiple: false,
  updateUpload: false,
  projectType: [CUSTOMER, CONDO],
  allowlistView: [ADMIN, TEC_PROGETTISTA],
  allowlistUpload: [ADMIN],
};

export const PROJECT_DOCUMENT_CONTRATTO_TERMOTECNICO = {
  ...DEFAULT_VALUES,
  type: 'CONTRATTO_TERMOTECNICO',
  category: DOCUMENTS_CATEGORIES.CONTRACTS,
  required: true,
  multiple: false,
  updateUpload: false,
  projectType: [CUSTOMER, CONDO],
  allowlistView: [ADMIN, TEC_TERMOTECNICO],
  allowlistUpload: [ADMIN],
};

export const PROJECT_DOCUMENT_CONTRATTO_DIREZIONE_LAVORI = {
  ...DEFAULT_VALUES,
  type: 'CONTRATTO_DIREZIONE_LAVORI',
  category: DOCUMENTS_CATEGORIES.CONTRACTS,
  required: true,
  multiple: false,
  updateUpload: false,
  projectType: [CUSTOMER, CONDO],
  allowlistView: [ADMIN, TEC_DIREZIONE_LAVORI],
  allowlistUpload: [ADMIN],
};

export const PROJECT_DOCUMENT_CONTRATTO_ASSEVERATORE = {
  ...DEFAULT_VALUES,
  type: 'CONTRATTO_ASSEVERATORE',
  category: DOCUMENTS_CATEGORIES.CONTRACTS,
  required: true,
  multiple: false,
  updateUpload: false,
  projectType: [CUSTOMER, CONDO],
  allowlistView: [ADMIN, TEC_ASSEVERATORE],
  allowlistUpload: [ADMIN],
};

export const PROJECT_DOCUMENT_CONTRATTO_SICUREZZA = {
  ...DEFAULT_VALUES,
  type: 'CONTRATTO_SICUREZZA',
  category: DOCUMENTS_CATEGORIES.CONTRACTS,
  required: true,
  multiple: false,
  updateUpload: false,
  projectType: [CUSTOMER, CONDO],
  allowlistView: [ADMIN, TEC_SICUREZZA],
  allowlistUpload: [ADMIN],
};

export const PROJECT_DOCUMENT_CONTRATTO_SICUREZZA_ESECUZIONE = {
  ...DEFAULT_VALUES,
  type: 'CONTRATTO_SICUREZZA_ESECUZIONE',
  category: DOCUMENTS_CATEGORIES.CONTRACTS,
  required: true,
  multiple: false,
  updateUpload: false,
  projectType: [CUSTOMER, CONDO],
  allowlistView: [ADMIN, TEC_SICUREZZA_ESECUZIONE],
  allowlistUpload: [ADMIN],
};

export const PROJECT_DOCUMENT_CONTRATTO_CA = {
  ...DEFAULT_VALUES,
  type: 'CONTRATTO_CA',
  category: DOCUMENTS_CATEGORIES.CONTRACTS,
  required: true,
  multiple: false,
  updateUpload: false,
  projectType: [CUSTOMER, CONDO],
  allowlistView: [ADMIN, TEC_CALCOLATORE_CA],
  allowlistUpload: [ADMIN],
};

export const PROJECT_DOCUMENT_CONTRATTO_TERMOTECNICO_ESECUZIONE = {
  ...DEFAULT_VALUES,
  type: 'CONTRATTO_TERMOTECNICO_ESECUZIONE',
  category: DOCUMENTS_CATEGORIES.CONTRACTS,
  required: true,
  multiple: false,
  updateUpload: false,
  projectType: [CUSTOMER, CONDO],
  allowlistView: [ADMIN, TEC_TERMOTECNICO_ESECUZIONE],
  allowlistUpload: [ADMIN],
};

export const PROJECT_DOCUMENT_CONTRATTO_COLLAUDATORE_CA = {
  ...DEFAULT_VALUES,
  type: 'CONTRATTO_COLLAUDATORE_CA',
  category: DOCUMENTS_CATEGORIES.CONTRACTS,
  required: true,
  multiple: false,
  updateUpload: false,
  projectType: [CUSTOMER, CONDO],
  allowlistView: [ADMIN, TEC_COLLAUDATORE_CA],
  allowlistUpload: [ADMIN],
};

export const PROJECT_DOCUMENT_CONTRATTO_CERTIFICATORE_ENERGETICO = {
  ...DEFAULT_VALUES,
  type: 'CONTRATTO_CERTIFICATORE_ENERGETICO',
  category: DOCUMENTS_CATEGORIES.CONTRACTS,
  required: true,
  multiple: false,
  updateUpload: false,
  projectType: [CUSTOMER, CONDO],
  allowlistView: [ADMIN, TEC_CERTIFICATORE_ENERGETICO],
  allowlistUpload: [ADMIN],
};

export const PROJECT_DOCUMENT_CONTRATTO_ACUSTICO = {
  ...DEFAULT_VALUES,
  type: 'CONTRATTO_ACUSTICO',
  category: DOCUMENTS_CATEGORIES.CONTRACTS,
  required: true,
  multiple: false,
  updateUpload: false,
  projectType: [CUSTOMER, CONDO],
  allowlistView: [ADMIN, TEC_ACUSTICO],
  allowlistUpload: [ADMIN],
};

export const PROJECT_DOCUMENT_CONTRATTO_VVF = {
  ...DEFAULT_VALUES,
  type: 'CONTRATTO_VVF',
  category: DOCUMENTS_CATEGORIES.CONTRACTS,
  required: true,
  multiple: false,
  updateUpload: false,
  projectType: [CUSTOMER, CONDO],
  allowlistView: [ADMIN, TEC_VVF],
  allowlistUpload: [ADMIN],
};

export const PROJECT_DOCUMENT_CONTRATTO_CONDUZIONE_CANTIERE = {
  ...DEFAULT_VALUES,
  type: 'CONTRATTO_CONDUZIONE_CANTIERE',
  category: DOCUMENTS_CATEGORIES.CONTRACTS,
  required: true,
  multiple: false,
  updateUpload: false,
  projectType: [CUSTOMER, CONDO],
  allowlistView: [ADMIN, TEC_CONDUZIONE_CANTIERE],
  allowlistUpload: [ADMIN],
};

export const PROJECT_DOCUMENT_CONTRATTO_GEOLOGO = {
  ...DEFAULT_VALUES,
  type: 'CONTRATTO_GEOLOGO',
  category: DOCUMENTS_CATEGORIES.CONTRACTS,
  required: true,
  multiple: false,
  updateUpload: false,
  projectType: [CUSTOMER, CONDO],
  allowlistView: [ADMIN, TEC_GEOLOGO],
  allowlistUpload: [ADMIN],
};

export const PROJECT_DOCUMENT_CONTRATTO_APPALTO = {
  ...DEFAULT_VALUES,
  type: 'CONTRATTO_APPALTO',
  category: DOCUMENTS_CATEGORIES.CONTRACTS,
  required: true,
  multiple: false,
  updateUpload: false,
  projectType: [CUSTOMER],
  allowlistView: [ADMIN, CUSTOMER, ...WORK_ASSIGNMENTS, TEC_PROGETTISTA, COMPANY, GENERAL_CONTRACTOR],
  allowlistUpload: [ADMIN],
};

export const PROJECT_DOCUMENT_CONTRATTO_CILA = {
  ...DEFAULT_VALUES,
  type: 'CONTRATTO_CILA',
  category: DOCUMENTS_CATEGORIES.CONTRACTS,
  required: true,
  multiple: false,
  updateUpload: false,
  projectType: ALL_PROJECT_TYPES,
  allowlistView: [ADMIN, ...ALL_PROJECT_TYPES],
  allowlistUpload: [ADMIN],
};

export const PROJECT_DOCUMENT_CONTRATTO_APPALTO_CONDO = {
  ...DEFAULT_VALUES,
  type: 'CONTRATTO_APPALTO_CONDO',
  category: DOCUMENTS_CATEGORIES.CONTRACTS,
  required: true,
  multiple: false,
  updateUpload: false,
  projectType: [CONDO, CONDO_CHILD],
  allowlistUpload: [ADMIN],
  allowlistView: [ADMIN, CONDO, CONDO_CHILD, ...WORK_ASSIGNMENTS, TEC_PROGETTISTA, COMPANY, GENERAL_CONTRACTOR],
};

export const PROJECT_DOCUMENT_CONTRATTO_AZIENDA_APPALTATRICE = {
  ...DEFAULT_VALUES,
  type: 'CONTRATTO_AZIENDA_APPALTATRICE',
  category: DOCUMENTS_CATEGORIES.CONTRACTS,
  required: true,
  multiple: false,
  updateUpload: false,
  projectType: ALL_PROJECT_TYPES,
  allowlistView: [ADMIN, GENERAL_CONTRACTOR],
  allowlistUpload: [ADMIN],
};

export const CONTRACT_DOCUMENTS_CONFIG = [
  PROJECT_DOCUMENT_CONTRATTO_VERIFICA_DOCUMENTI,
  PROJECT_DOCUMENT_PRECONTRATTO,
  PROJECT_DOCUMENT_PRECONTRATTO_CONDO,
  PROJECT_DOCUMENT_CONTRATTO_SOPRALLUOGO,
  PROJECT_DOCUMENT_CONTRATTO_RILIEVO,
  PROJECT_DOCUMENT_CONTRATTO_APE,
  PROJECT_DOCUMENT_CONTRATTO_PROGETTISTA,
  PROJECT_DOCUMENT_CONTRATTO_TERMOTECNICO,
  PROJECT_DOCUMENT_CONTRATTO_DIREZIONE_LAVORI,
  PROJECT_DOCUMENT_CONTRATTO_ASSEVERATORE,
  PROJECT_DOCUMENT_CONTRATTO_SICUREZZA,
  PROJECT_DOCUMENT_CONTRATTO_SICUREZZA_ESECUZIONE,
  PROJECT_DOCUMENT_CONTRATTO_CA,
  PROJECT_DOCUMENT_CONTRATTO_TERMOTECNICO_ESECUZIONE,
  PROJECT_DOCUMENT_CONTRATTO_COLLAUDATORE_CA,
  PROJECT_DOCUMENT_CONTRATTO_CERTIFICATORE_ENERGETICO,
  PROJECT_DOCUMENT_CONTRATTO_ACUSTICO,
  PROJECT_DOCUMENT_CONTRATTO_VVF,
  PROJECT_DOCUMENT_CONTRATTO_CONDUZIONE_CANTIERE,
  PROJECT_DOCUMENT_CONTRATTO_GEOLOGO,
  PROJECT_DOCUMENT_CONTRATTO_APPALTO,
  PROJECT_DOCUMENT_CONTRATTO_CILA,
  PROJECT_DOCUMENT_CONTRATTO_APPALTO_CONDO,
  PROJECT_DOCUMENT_CONTRATTO_AZIENDA_APPALTATRICE,
];

export const ALL_PROJECT_DOCUMENTS_CONFIG = [
  ...IMAGES_DOCUMENTS_CONFIG,
  ...VERIFY_DOCUMENTS_CONFIG,
  ...PROJECT_DOCUMENTS_CONFIG,
  ...CONTRACT_DOCUMENTS_CONFIG,
];

export const EXCLUDED_PROJECT_DOCUMENT_CONFIG = {
  customer: [PROJECT_DOCUMENT_COMPUTO_METRICO.type, PROJECT_DOCUMENT_IMPORTI_ESECUTORI.type],
  condo: [PROJECT_DOCUMENT_COMPUTO_METRICO.type, PROJECT_DOCUMENT_IMPORTI_ESECUTORI.type],
};

export const EXCLUDED_TEMPLATES_DOWNLOAD_CONFIG = {
  customer: [
    PROJECT_DOCUMENT_RILIEVO.type,
    PROJECT_DOCUMENT_FATTIBILITA.type,
    PROJECT_DOCUMENT_SANATORIA.type,
    PROJECT_DOCUMENT_QUADRO_ECONOMICO.type,
  ],
  condo: [
    PROJECT_DOCUMENT_RILIEVO.type,
    PROJECT_DOCUMENT_FATTIBILITA.type,
    PROJECT_DOCUMENT_SANATORIA.type,
    PROJECT_DOCUMENT_QUADRO_ECONOMICO.type,
  ],
};
