import React from 'react';
import AvatarStatus from 'components/shared-components/AvatarStatus';

const Avatar = props => {
  const { avatar, name, mail } = props;

  return (
    <div className="d-flex h-100">
      <AvatarStatus src={avatar} name={name} subTitle={mail} />
    </div>
  );
};

export default Avatar;
