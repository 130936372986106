import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment';
import { message } from 'antd';
import TechnicianService from 'services/TechnicianService';
import { setAssignments } from 'redux/actions/Projects';
import {
  setPersonalRegistry,
  getPersonalRegistry,
  setProfessionalRegistry,
  getProfessionalRegistry,
  getCompetences,
  setCompetences,
  setFigures,
  getFigures,
  getRegisters,
  setRegisters,
  getCourses,
  setCourses,
  setMacro,
  setInsurancePolicies,
  setWorksAmount,
  getInsurancePolicies,
} from 'redux/actions/Technician';
import {
  GET_PERSONAL_REGISTRY,
  UPDATE_PERSONAL_REGISTRY,
  SUBMIT_PERSONAL_REGISTRY,
  GET_PROFESSIONAL_REGISTRY,
  UPDATE_PROFESSIONAL_REGISTRY,
  SUBMIT_PROFESSIONAL_REGISTRY,
  GET_COMPETENCES,
  SUBMIT_COMPETENCES,
  UPDATE_COMPETENCES,
  GET_FIGURES,
  SUBMIT_FIGURES,
  UPDATE_FIGURES,
  GET_REGISTERS,
  SUBMIT_REGISTERS,
  UPDATE_REGISTERS,
  GET_COURSES,
  SUBMIT_COURSES,
  UPDATE_COURSES,
  UPDATE_SOCIAL,
  GET_TECHNICIAN_ASSIGNMENTS,
  GET_INSURANCE_POLICIES,
  GET_WORKS_AMOUNT,
  SUBMIT_INSURANCE_POLICY,
  UPDATE_INSURANCE_POLICY,
  DELETE_INSURANCE_POLICY,
} from '../constants/Technician';
import { showLoading, hideLoading } from '../actions/Auth';
import { setError, getStatus } from '../actions/Status';

require('moment/locale/it');

export const getTheme = state => state.theme;

export function* getPersonalRegistrySaga() {
  yield takeEvery(GET_PERSONAL_REGISTRY, function* (action) {
    const { params } = action.payload;

    try {
      const res = yield TechnicianService.getPersonalRegistry(params);
      const data = _.omit(res.object, ['id', 'userId']);

      const formattedData = {
        ...data,
        birthDate: moment(data.birthDate),
      };

      yield put(setPersonalRegistry(formattedData));
    } catch (err) {
      if (err.view) {
        yield put(setError(err.code));
      }
    }
  });
}

export function* submitPersonalRegistry() {
  yield takeEvery(SUBMIT_PERSONAL_REGISTRY, function* (action) {
    const { registry, intl } = action.payload;

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.sending' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      yield TechnicianService.submitPersonalRegistry(registry);

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(setPersonalRegistry(registry));
      yield put(getStatus());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* updatePersonalRegistry() {
  yield takeEvery(UPDATE_PERSONAL_REGISTRY, function* (action) {
    const { registry, intl } = action.payload;

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.updating' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      yield TechnicianService.updatePersonalRegistry(registry);

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(getPersonalRegistry());
      yield put(hideLoading());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* getProfessionalRegistrySaga() {
  yield takeEvery(GET_PROFESSIONAL_REGISTRY, function* (action) {
    const { params } = action.payload;

    try {
      const res = yield TechnicianService.getProfessionalRegistry(params);
      const data = _.omit(res.object, ['id', 'userId']);
      yield put(setProfessionalRegistry(data));
    } catch (err) {
      if (err.view) {
        yield put(setError(err.code));
      }
    }
  });
}

export function* submitProfessionalRegistry() {
  yield takeEvery(SUBMIT_PROFESSIONAL_REGISTRY, function* (action) {
    const { data, intl } = action.payload;

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.sending' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      yield TechnicianService.submitProfessionalRegistry(data);

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(setProfessionalRegistry(data));
      yield put(getStatus());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* updateProfessionalRegistry() {
  yield takeEvery(UPDATE_PROFESSIONAL_REGISTRY, function* (action) {
    const { data, intl } = action.payload;

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.updating' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      yield TechnicianService.updateProfessionalRegistry(data);

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(getProfessionalRegistry());
      yield put(hideLoading());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* getInsurancePoliciesSaga() {
  yield takeEvery(GET_INSURANCE_POLICIES, function* (action) {
    const { params } = action.payload;

    try {
      const { objectList } = yield TechnicianService.getInsurancePolicies(params);
      yield put(setInsurancePolicies(objectList));
    } catch (err) {
      if (err.view) {
        yield put(setError(err.code));
      }
    }
  });
}

export function* submitInsurancePolicy() {
  yield takeEvery(SUBMIT_INSURANCE_POLICY, function* (action) {
    const { policy } = action.payload;
    const { intl } = yield select(getTheme);

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.sending' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      yield TechnicianService.submitInsurancePolicy(policy);

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(getInsurancePolicies({ userId: policy.userId }));
      yield put(hideLoading());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* updateInsurancePolicy() {
  yield takeEvery(UPDATE_INSURANCE_POLICY, function* (action) {
    const { policy } = action.payload;
    const { intl } = yield select(getTheme);

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.updating' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      yield TechnicianService.updateInsurancePolicy(policy);

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(getInsurancePolicies({ userId: policy.userId }));
      yield put(hideLoading());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* deleteInsurancePolicy() {
  yield takeEvery(DELETE_INSURANCE_POLICY, function* (action) {
    const { policy } = action.payload;
    const { intl } = yield select(getTheme);

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.deleting' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      yield TechnicianService.deleteInsurancePolicy({ id: policy.id });

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(getInsurancePolicies({ userId: policy.userId }));
      yield put(hideLoading());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* getWorksAmountSaga() {
  yield takeEvery(GET_WORKS_AMOUNT, function* (action) {
    const { params } = action.payload;

    try {
      const { objectList } = yield TechnicianService.getWorksAmount(params);
      yield put(setWorksAmount(objectList));
    } catch (err) {
      if (err.view) {
        yield put(setError(err.code));
      }
    }
  });
}

export function* updateSocial() {
  yield takeEvery(UPDATE_SOCIAL, function* (action) {
    const { social, intl } = action.payload;

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.updating' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      yield TechnicianService.updateSocial(social);

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(getProfessionalRegistry());
      yield put(hideLoading());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* getRegistersSaga() {
  yield takeEvery(GET_REGISTERS, function* () {
    try {
      const { object } = yield TechnicianService.getRegisters();
      yield put(setRegisters(object?.registers));
    } catch (err) {
      if (err.view) {
        yield put(setError(err.code));
      }
    }
  });
}

export function* submitRegisters() {
  yield takeEvery(SUBMIT_REGISTERS, function* (action) {
    const { data, intl } = action.payload;

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.sending' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      yield TechnicianService.submitRegisters(data);

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(setRegisters(data));
      yield put(getStatus());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* updateRegisters() {
  yield takeEvery(UPDATE_REGISTERS, function* (action) {
    const { data, intl } = action.payload;

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.updating' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      yield TechnicianService.updateRegisters(data);

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(getRegisters());
      yield put(hideLoading());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* getCoursesSaga() {
  yield takeEvery(GET_COURSES, function* () {
    try {
      const res = yield TechnicianService.getCourses();
      const data = res.object;

      yield put(setCourses(data.courses));
    } catch (err) {
      if (err.view) {
        yield put(setError(err.code));
      }
    }
  });
}

export function* submitCourses() {
  yield takeEvery(SUBMIT_COURSES, function* (action) {
    const { data, intl } = action.payload;

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.sending' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      yield TechnicianService.submitCourses(data);

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(setCourses(data));
      yield put(getStatus());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* updateCourses() {
  yield takeEvery(UPDATE_COURSES, function* (action) {
    const { data, intl } = action.payload;

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.updating' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      yield TechnicianService.updateCourses(data);

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(getCourses());
      yield put(hideLoading());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* getCompetencesSaga() {
  yield takeEvery(GET_COMPETENCES, function* (action) {
    const { params } = action.payload;

    try {
      const resCompetences = yield TechnicianService.getCompetences();
      const resActual = yield TechnicianService.getActualCompetences(params);

      const actualCompetences = resActual.object.competences;
      const data = resCompetences.objectList.map(c => ({
        ...c,
        rating: actualCompetences[c.id],
        checked: !!actualCompetences[c.id],
      }));

      yield put(setCompetences(data));
    } catch (err) {
      if (err.view) {
        yield put(setError(err.code));
      }
    }
  });
}

export function* submitCompetences() {
  yield takeEvery(SUBMIT_COMPETENCES, function* (action) {
    const { competences, intl } = action.payload;

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.sending' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    const competencesObject = _.reduce(competences, (acc, { id, rating }) => ({ ...acc, [id]: rating }), {});

    try {
      yield TechnicianService.submitCompetences({
        competences: competencesObject,
      });

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(setCompetences(competences));
      yield put(getStatus());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* updateCompetences() {
  yield takeEvery(UPDATE_COMPETENCES, function* (action) {
    const { competences, intl } = action.payload;

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.sending' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    const actualCompetences = competences.filter(c => c.rating && c.checked);

    const competencesObject = _.reduce(actualCompetences, (acc, { id, rating }) => ({ ...acc, [id]: rating }), {});

    try {
      yield TechnicianService.updateCompetences({
        competences: competencesObject,
      });

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(getCompetences());
      yield put(hideLoading());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* getFiguresSaga() {
  yield takeEvery(GET_FIGURES, function* (action) {
    const { params } = action.payload;

    try {
      const resMacro = yield TechnicianService.getMacroFigures();
      const resFigures = yield TechnicianService.getFigures();
      const resActual = yield TechnicianService.getActualFigures(params);

      const actualFigures = resActual.object.figures;

      const macro = resMacro.object;

      const data = resFigures.objectList.map((figure, index) => ({
        id: figure.id,
        description: figure.description,
        macro: figure.macro_figure_id,
        rating: actualFigures[figure.id],
        checked: !!actualFigures[figure.id],
      }));

      yield put(setMacro(macro));
      yield put(setFigures(data));
    } catch (err) {
      if (err.view) {
        yield put(setError(err.code));
      }
    }
  });
}

export function* getAssignmentsSaga() {
  yield takeEvery(GET_TECHNICIAN_ASSIGNMENTS, function* () {
    try {
      const { objectList } = yield TechnicianService.getAssignments();

      yield put(setAssignments(objectList));
    } catch (err) {
      if (err.view) {
        yield put(setError(err.code));
      }
    }
  });
}

export function* submitFigures() {
  yield takeEvery(SUBMIT_FIGURES, function* (action) {
    const { figures, intl } = action.payload;

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.sending' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    const actualFigures = figures.filter(f => f.rating);

    const figuresObject = _.reduce(actualFigures, (acc, { id, rating }) => ({ ...acc, [id]: rating }), {});

    try {
      yield TechnicianService.submitFigures({
        figures: figuresObject,
      });

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(getFigures());
      yield put(getStatus());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* updateFigures() {
  yield takeEvery(UPDATE_FIGURES, function* (action) {
    const { figures, intl } = action.payload;

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.updating' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    const actualFigures = figures.filter(f => f.rating && f.checked);

    const figuresObject = _.reduce(actualFigures, (acc, { id, rating }) => ({ ...acc, [id]: rating }), {});

    try {
      yield TechnicianService.updateFigures({
        figures: figuresObject,
      });

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(getFigures());
      yield put(hideLoading());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getPersonalRegistrySaga),
    fork(submitPersonalRegistry),
    fork(updatePersonalRegistry),
    fork(getProfessionalRegistrySaga),
    fork(submitProfessionalRegistry),
    fork(updateProfessionalRegistry),
    fork(updateSocial),
    fork(getRegistersSaga),
    fork(submitRegisters),
    fork(updateRegisters),
    fork(getCoursesSaga),
    fork(submitCourses),
    fork(updateCourses),
    fork(getCompetencesSaga),
    fork(submitCompetences),
    fork(updateCompetences),
    fork(getFiguresSaga),
    fork(submitFigures),
    fork(updateFigures),
    fork(getAssignmentsSaga),
    fork(getInsurancePoliciesSaga),
    fork(submitInsurancePolicy),
    fork(updateInsurancePolicy),
    fork(getWorksAmountSaga),
    fork(deleteInsurancePolicy),
  ]);
}
