import { TEC_CALCOLATORE_CA } from 'constants/ProjectsConstant';
import { GENERIC_TASKS, TECHNICIAN_TASKS } from './CommonTasks';

export const TEC_CALCOLATORE_CA_STEPS = [
  {
    code: TEC_CALCOLATORE_CA,
    icon: null,
    order: 1,
  },
];

export const TEC_CALCOLATORE_CA_TASKS = [...TECHNICIAN_TASKS, ...GENERIC_TASKS];
