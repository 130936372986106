import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import { message } from 'antd';
import AppService from 'services/AppService';
import { setError, getStatus } from '../actions/Status';
import { showLoading, hideLoading } from '../actions/Auth';
import { CHANGE_PASSWORD, TERMINATE_REGISTRATION, UPLOAD_USER_FILE, SEND_DATA } from '../constants/App';

export const getTheme = state => state.theme;

export function* changePassword() {
  yield takeEvery(CHANGE_PASSWORD, function* (action) {
    const { values, form, intl } = action.payload;
    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.sending' })}`,
      key,
    });

    yield put(showLoading());

    try {
      yield AppService.changePassword(values);

      form.resetFields();

      message.success({
        content: `${intl.formatMessage({ id: 'messages.done' })}`,
        key,
      });

      yield put(hideLoading());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* terminateRegistration() {
  yield takeEvery(TERMINATE_REGISTRATION, function* () {
    try {
      yield AppService.terminateRegistration();
      yield put(getStatus());
    } catch (err) {
      if (err.view) {
        yield put(setError(err.code));
      }
    }
  });
}

export function* uploadUserFile() {
  yield takeEvery(UPLOAD_USER_FILE, function* (action) {
    const { file, fileDetails, requestedId, onSuccess } = action.payload;
    const { intl } = yield select(getTheme);

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: 'messages.uploading' })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());
    try {
      yield AppService.uploadUserFile(file, fileDetails, requestedId);

      message.success({
        content: `${file.name} ${intl.formatMessage({
          id: 'messages.uploaded_successfully',
        })}`,
        key,
      });

      if (onSuccess) onSuccess();
      yield put(hideLoading());
    } catch (err) {
      yield put(hideLoading());
      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage(
            {
              id: 'messages.uploaded_unsuccessfully',
            },
            {
              fileName: file.name,
            },
          )}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export function* sendData() {
  yield takeEvery(SEND_DATA, function* (action) {
    const { service, content, options } = action.payload;
    const { intl } = yield select(getTheme);

    const key = 'updatable';
    message.loading({
      content: `${intl.formatMessage({ id: `messages.${options?.loadingMessage || 'sending'}` })}`,
      key,
      duration: 0,
    });

    yield put(showLoading());

    try {
      yield service(content);

      message.success({
        content: `${intl.formatMessage({ id: `messages.${options?.successMessage || 'done'}` })}`,
        key,
      });

      if (options?.successAction) options.successAction();
      yield put(hideLoading());
    } catch (err) {
      yield put(hideLoading());

      if (err.view) {
        message.destroy(key);
        yield put(setError(err.code));
      } else {
        message.error({
          content: `${intl.formatMessage({ id: `error.${err.code}` })}`,
          key,
          duration: 5,
        });
      }
    }
  });
}

export default function* rootSaga() {
  yield all([fork(changePassword), fork(terminateRegistration), fork(uploadUserFile), fork(sendData)]);
}
