import { COMPANY } from 'constants/AppConstant';
import { ATTESA_CONTRATTO_APPALTO, ATTESA_GESTIONE_PROCEDURA, ESECUZIONE_LAVORI } from 'constants/CamundaConstant';
import { STATUS_WAITING } from 'constants/ProjectsConstant';
import { GENERIC_TASKS } from './CommonTasks';

export const COMPANY_STEPS = [
  {
    code: COMPANY,
    icon: null,
    order: 1,
  },
];

export const COMPANY_TASKS = [
  {
    code: ATTESA_GESTIONE_PROCEDURA,
    status: STATUS_WAITING,
    component: ATTESA_GESTIONE_PROCEDURA,
    message: ATTESA_GESTIONE_PROCEDURA,
  },
  {
    code: ATTESA_CONTRATTO_APPALTO,
    status: STATUS_WAITING,
    component: ATTESA_CONTRATTO_APPALTO,
    message: ATTESA_CONTRATTO_APPALTO,
  },
  {
    code: ESECUZIONE_LAVORI,
    status: STATUS_WAITING,
    component: ESECUZIONE_LAVORI,
    message: ESECUZIONE_LAVORI,
  },
  ...GENERIC_TASKS,
];
