import { GET_REGISTRY, SET_REGISTRY, UPDATE_REGISTRY, SUBMIT_REGISTRY } from '../constants/Customer';

export const getRegistry = params => ({
  type: GET_REGISTRY,
  payload: { params },
});

export const setRegistry = registry => ({
  type: SET_REGISTRY,
  payload: registry,
});

export const submitRegistry = (registry, intl) => ({
  type: SUBMIT_REGISTRY,
  payload: { registry, intl },
});

export const updateRegistry = (registry, intl) => ({
  type: UPDATE_REGISTRY,
  payload: { registry, intl },
});
