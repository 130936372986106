export const GET_USERS = 'GET_USERS';
export const SET_USERS = 'SET_USERS';
export const SET_COMPANIES = 'SET_COMPANIES';
export const SET_TECHNICIANS = 'SET_TECHNICIANS';
export const SET_CUSTOMERS = 'SET_CUSTOMERS';
export const SET_CONDOMINIUMS = 'SET_CONDOMINIUMS';

export const GET_TASKLIST_PROJECTS = 'GET_TASKLIST_PROJECTS';
export const SET_TASKLIST_PROJECTS = 'SET_TASKLIST_PROJECTS';
export const GET_TASKLIST_REGISTRATION = 'GET_TASKLIST_REGISTRATION';
export const SET_TASKLIST_REGISTRATION = 'SET_TASKLIST_REGISTRATION';

export const CLOSE_TASK = 'CLOSE_ADMIN_TASK';

export const UPDATE_COMPANY_DETAILS = 'UPDATE_COMPANY_DETAILS';

export const GET_ADMIN_DETAILS = 'GET_ADMIN_DETAILS';
export const SET_ADMIN_DETAILS = 'SET_ADMIN_DETAILS';
export const SUBMIT_ADMIN_DETAILS = 'SUBMIT_ADMIN_DETAILS';
export const UPDATE_ADMIN_DETAILS = 'UPDATE_ADMIN_DETAILS';

export const SET_APPOINTMENTS = 'SET_APPOINTMENTS';

export const UPDATE_IS_PAID = 'UPDATE_IS_PAID';

export const SET_SEEN_TASKLIST = 'SET_SEEN_TASKLIST';

export const GET_TRUSTED_SUPPLIERS = 'GET_TRUSTED_SUPPLIERS';
export const SET_TRUSTED_SUPPLIERS = 'SET_TRUSTED_SUPPLIERS';

export const CREATE_NEW_PROJECT = 'CREATE_NEW_PROJECT';
