export const GET_PERSONAL_REGISTRY = 'GET_TECHNICIAN_PERSONAL_REGISTRY';
export const SUBMIT_PERSONAL_REGISTRY = 'SUBMIT_TECHNICIAN_PERSONAL_REGISTRY';
export const SET_PERSONAL_REGISTRY = 'SET_TECHNICIAN_PERSONAL_REGISTRY';
export const UPDATE_PERSONAL_REGISTRY = 'UPDATE_TECHNICIAN_PERSONAL_REGISTRY';

export const GET_PROFESSIONAL_REGISTRY = 'GET_TECHNICIAN_PROFESSIONAL_REGISTRY';
export const SUBMIT_PROFESSIONAL_REGISTRY = 'SUBMIT_TECHNICIAN_PROFESSIONAL_REGISTRY';
export const SET_PROFESSIONAL_REGISTRY = 'SET_TECHNICIAN_PROFESSIONAL_REGISTRY';
export const UPDATE_PROFESSIONAL_REGISTRY = 'UPDATE_TECHNICIAN_PROFESSIONAL_REGISTRY';
export const UPDATE_SOCIAL = 'UPDATE_TECHNICIAN_SOCIAL';

export const GET_REGISTERS = 'GET_TECHNICIAN_REGISTERS';
export const SUBMIT_REGISTERS = 'SUBMIT_TECHNICIAN_REGISTERS';
export const SET_REGISTERS = 'SET_TECHNICIAN_REGISTERS';
export const UPDATE_REGISTERS = 'UPDATE_TECHNICIAN_REGISTERS';

export const GET_INSURANCE_POLICIES = 'GET_INSURANCE_POLICIES';
export const SET_INSURANCE_POLICIES = 'SET_INSURANCE_POLICIES';
export const CLEAR_INSURANCE_POLICIES = 'CLEAR_INSURANCE_POLICIES';
export const SUBMIT_INSURANCE_POLICY = 'SUBMIT_INSURANCE_POLICY';
export const UPDATE_INSURANCE_POLICY = 'UPDATE_INSURANCE_POLICY';
export const DELETE_INSURANCE_POLICY = 'DELETE_INSURANCE_POLICY';

export const GET_WORKS_AMOUNT = 'GET_WORKS_AMOUNT';
export const SET_WORKS_AMOUNT = 'SET_WORKS_AMOUNT';
export const CLEAR_WORKS_AMOUNT = 'CLEAR_WORKS_AMOUNT';

export const GET_COURSES = 'GET_TECHNICIAN_COURSES';
export const SUBMIT_COURSES = 'SUBMIT_TECHNICIAN_COURSES';
export const SET_COURSES = 'SET_TECHNICIAN_COURSES';
export const UPDATE_COURSES = 'UPDATE_TECHNICIAN_COURSES';

export const GET_TECHNICIAN_ASSIGNMENTS = 'GET_TECHNICIAN_ASSIGNMENTS';

export const GET_COMPETENCES = 'GET_TECHNICIAN_COMPETENCES';
export const SUBMIT_COMPETENCES = 'SUBMIT_TECHNICIAN_COMPETENCES';
export const UPDATE_COMPETENCES = 'UPDATE_TECHNICIAN_COMPETENCES';
export const SET_COMPETENCES = 'SET_TECHNICIAN_COMPETENCES';

export const GET_FIGURES = 'GET_TECHNICIAN_FIGURES';
export const SUBMIT_FIGURES = 'SUBMIT_TECHNICIAN_FIGURES';
export const SET_MACRO = 'SET_TECHNICIAN_MACRO';
export const SET_FIGURES = 'SET_TECHNICIAN_FIGURES';
export const UPDATE_FIGURES = 'UPDATE_TECHNICIAN_FIGURES';
