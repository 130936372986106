import { SET_INVITATIONS } from 'redux/constants/Condominium';
import { SIGNOUT_SUCCESS } from '../constants/Auth';

const initState = {
  invitationsList: [],
};

const condominium = (state = initState, action) => {
  switch (action.type) {
    case SET_INVITATIONS:
      return {
        ...state,
        invitationsList: action.payload,
      };
    case SIGNOUT_SUCCESS:
      return {
        ...initState,
      };
    default:
      return state;
  }
};

export default condominium;
