import fetch from './interceptor';

const CustomerService = {};

CustomerService.getRegistry = params =>
  fetch({
    url: '/api/customer/get/customer-detail',
    method: 'get',
    params,
  });

CustomerService.submitRegistry = data =>
  fetch({
    url: '/api/customer/insert/customer-detail',
    method: 'post',
    data,
  });

CustomerService.updateRegistry = data =>
  fetch({
    url: '/api/customer/update/customer-detail',
    method: 'put',
    data,
  });

CustomerService.getClosedProjects = () =>
  fetch({
    url: '/api/project/get/user-projects-finished',
    method: 'get',
  });

CustomerService.deleteAccount = () =>
  fetch({
    url: '/api/delete/customer',
    method: 'delete',
  });

CustomerService.getAppointments = params =>
  fetch({
    url: '/api/customer/get/booking-list',
    method: 'get',
    params,
  });

CustomerService.getProposedDates = params =>
  fetch({
    url: '/api/customer/get/booking-proposal',
    method: 'get',
    params,
  });

export default CustomerService;
