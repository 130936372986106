import React, { Children } from 'react';
import AppLocale from 'lang';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';

const IntlWrapper = props => {
  const { locale, children } = props;
  const currentAppLocale = AppLocale[locale];

  return (
    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
      {Children.only(children)}
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme }) => {
  const { locale } = theme;
  return { locale };
};

export default connect(mapStateToProps)(IntlWrapper);
