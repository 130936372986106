import {
  CLOSE_TASK,
  GET_PROJECTS,
  GET_WORKING_OPERATIONS,
  SET_ASSIGNMENTS,
  SET_SEEN_ASSIGNMENTS,
  SET_PROJECTS,
  SET_WORKING_OPERATIONS,
  START_PROJECT,
  GET_SEEN_ASSIGNMENTS,
  GET_PAYMENTS,
  SET_PAYMENTS,
  GET_BUILDING,
  SET_BUILDING,
  UPLOAD_PROJECT_FILE,
} from '../constants/Projects';

export const getProjects = () => ({
  type: GET_PROJECTS,
});

export const setProjects = projects => ({
  type: SET_PROJECTS,
  payload: projects,
});

export const startProject = (project, intl) => ({
  type: START_PROJECT,
  payload: { project, intl },
});

export const uploadFile = (file, fileDetails, onSuccess, requestedId) => ({
  type: UPLOAD_PROJECT_FILE,
  payload: { file, fileDetails, onSuccess, requestedId },
});

export const closeTask = (content, options) => ({
  type: CLOSE_TASK,
  payload: { content, options },
});

export const getSeenAssignments = () => ({
  type: GET_SEEN_ASSIGNMENTS,
});

export const setAssignments = assignments => ({
  type: SET_ASSIGNMENTS,
  payload: assignments,
});

export const setSeenAssignments = seen => ({
  type: SET_SEEN_ASSIGNMENTS,
  payload: seen,
});

export const getWorkingOperations = () => ({
  type: GET_WORKING_OPERATIONS,
});

export const setWorkingOperations = workingOperations => ({
  type: SET_WORKING_OPERATIONS,
  payload: workingOperations,
});

export const getPayments = params => ({
  type: GET_PAYMENTS,
  payload: params,
});

export const setPayments = payments => ({
  type: SET_PAYMENTS,
  payload: payments,
});

export const getBuilding = params => ({
  type: GET_BUILDING,
  payload: params,
});

export const setBuilding = building => ({
  type: SET_BUILDING,
  payload: building,
});
