import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import Loading from 'components/shared-components/Loading';

const actualComponent = {
  company: lazy(() => import('./company')),
  technician: lazy(() => import('./technician')),
  customer: lazy(() => import('./customer')),
  condo: lazy(() => import('./condo')),
};

export const RegistrationViews = props => {
  const { match, role } = props;

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${match.url}`} component={actualComponent[role]} />
      </Switch>
    </Suspense>
  );
};

const mapStateToProps = ({ auth }) => {
  const { role } = auth;
  return { role };
};

export default connect(mapStateToProps)(RegistrationViews);
