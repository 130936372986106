import {
  GET_PERSONAL_REGISTRY,
  SET_PERSONAL_REGISTRY,
  UPDATE_PERSONAL_REGISTRY,
  SUBMIT_PERSONAL_REGISTRY,
  GET_PROFESSIONAL_REGISTRY,
  SET_PROFESSIONAL_REGISTRY,
  UPDATE_PROFESSIONAL_REGISTRY,
  SUBMIT_PROFESSIONAL_REGISTRY,
  SUBMIT_COMPETENCES,
  GET_COMPETENCES,
  SET_COMPETENCES,
  UPDATE_COMPETENCES,
  GET_FIGURES,
  SET_FIGURES,
  SUBMIT_FIGURES,
  UPDATE_FIGURES,
  GET_REGISTERS,
  SET_REGISTERS,
  SUBMIT_REGISTERS,
  UPDATE_REGISTERS,
  GET_COURSES,
  SET_COURSES,
  SUBMIT_COURSES,
  UPDATE_COURSES,
  UPDATE_SOCIAL,
  SET_MACRO,
  GET_TECHNICIAN_ASSIGNMENTS,
  GET_INSURANCE_POLICIES,
  SET_INSURANCE_POLICIES,
  CLEAR_INSURANCE_POLICIES,
  GET_WORKS_AMOUNT,
  SET_WORKS_AMOUNT,
  SUBMIT_INSURANCE_POLICY,
  UPDATE_INSURANCE_POLICY,
  DELETE_INSURANCE_POLICY,
} from '../constants/Technician';

export const getPersonalRegistry = params => ({
  type: GET_PERSONAL_REGISTRY,
  payload: { params },
});

export const setPersonalRegistry = registry => ({
  type: SET_PERSONAL_REGISTRY,
  payload: registry,
});

export const submitPersonalRegistry = (registry, intl) => ({
  type: SUBMIT_PERSONAL_REGISTRY,
  payload: { registry, intl },
});

export const updatePersonalRegistry = (registry, intl) => ({
  type: UPDATE_PERSONAL_REGISTRY,
  payload: { registry, intl },
});

export const getProfessionalRegistry = params => ({
  type: GET_PROFESSIONAL_REGISTRY,
  payload: { params },
});

export const setProfessionalRegistry = registry => ({
  type: SET_PROFESSIONAL_REGISTRY,
  payload: registry,
});

export const submitProfessionalRegistry = (data, intl) => ({
  type: SUBMIT_PROFESSIONAL_REGISTRY,
  payload: { data, intl },
});

export const updateProfessionalRegistry = (data, intl) => ({
  type: UPDATE_PROFESSIONAL_REGISTRY,
  payload: { data, intl },
});

export const updateSocial = (social, intl) => ({
  type: UPDATE_SOCIAL,
  payload: { social, intl },
});

export const getRegisters = () => ({
  type: GET_REGISTERS,
});

export const setMacro = macro => ({
  type: SET_MACRO,
  payload: macro,
});

export const setRegisters = registers => ({
  type: SET_REGISTERS,
  payload: registers,
});

export const submitRegisters = (data, intl) => ({
  type: SUBMIT_REGISTERS,
  payload: { data, intl },
});

export const updateRegisters = (data, intl) => ({
  type: UPDATE_REGISTERS,
  payload: { data, intl },
});

export const getCourses = () => ({
  type: GET_COURSES,
});

export const getAssignments = () => ({
  type: GET_TECHNICIAN_ASSIGNMENTS,
});

export const setCourses = courses => ({
  type: SET_COURSES,
  payload: courses,
});

export const submitCourses = (data, intl) => ({
  type: SUBMIT_COURSES,
  payload: { data, intl },
});

export const updateCourses = (data, intl) => ({
  type: UPDATE_COURSES,
  payload: { data, intl },
});

export const getCompetences = params => ({
  type: GET_COMPETENCES,
  payload: { params },
});

export const setCompetences = competences => ({
  type: SET_COMPETENCES,
  payload: competences,
});

export const submitCompetences = (competences, intl) => ({
  type: SUBMIT_COMPETENCES,
  payload: { competences, intl },
});

export const updateCompetences = (competences, intl) => ({
  type: UPDATE_COMPETENCES,
  payload: { competences, intl },
});

export const getFigures = params => ({
  type: GET_FIGURES,
  payload: { params },
});

export const setFigures = figures => ({
  type: SET_FIGURES,
  payload: figures,
});

export const submitFigures = (figures, intl) => ({
  type: SUBMIT_FIGURES,
  payload: { figures, intl },
});

export const updateFigures = (figures, intl) => ({
  type: UPDATE_FIGURES,
  payload: { figures, intl },
});

export const getInsurancePolicies = params => ({
  type: GET_INSURANCE_POLICIES,
  payload: { params },
});

export const setInsurancePolicies = policies => ({
  type: SET_INSURANCE_POLICIES,
  payload: policies,
});

export const clearInsurancePolicies = () => ({
  type: CLEAR_INSURANCE_POLICIES,
});

export const submitInsurancePolicy = policy => ({
  type: SUBMIT_INSURANCE_POLICY,
  payload: { policy },
});

export const updateInsurancePolicy = policy => ({
  type: UPDATE_INSURANCE_POLICY,
  payload: { policy },
});

export const deleteInsurancePolicy = policy => ({
  type: DELETE_INSURANCE_POLICY,
  payload: { policy },
});

export const getWorksAmount = params => ({
  type: GET_WORKS_AMOUNT,
  payload: { params },
});

export const setWorksAmount = worksAmount => ({
  type: SET_WORKS_AMOUNT,
  payload: worksAmount,
});
